import "./App.css";
import Layout from "./components/layout/Layout";
import { ThemeProvider } from "./ThemeContext";
import { auth, passwordResetEmail } from "./firebase";
import ZoomableWrapper from "./components/common/ZoomWraper";

import { GoogleOAuthProvider } from "@react-oauth/google";
function App() {
  // if (window.location.protocol === 'http:') {
  //   window.location.replace('https://' + window.location.host + window.location.pathname);
  // }

  const clientId = process.env.REACT_APP_GOOGLE_O_AUTH_CLIENT_ID;

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ZoomableWrapper>
        <div className="App">
          <ThemeProvider>
            <Layout />
          </ThemeProvider>
        </div>
      </ZoomableWrapper>
    </GoogleOAuthProvider>
  );
}

export default App;
