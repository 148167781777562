"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
require("./Content.css");
var Content = function (_a) {
    var routes = _a.routes, isAuthenticated = _a.isAuthenticated, setIsAuthenticated = _a.setIsAuthenticated, ProtectedRoute = _a.protectedRouteComponent, Login = _a.loginComponent, Routes = _a.RoutesComponent, Route = _a.RouteComponent;
    (0, react_1.useEffect)(function () {
        var handleStorageChange = function () {
            setIsAuthenticated(localStorage.getItem("isAuthenticated") === "true");
        };
        window.addEventListener("storage", handleStorageChange);
        return function () {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [setIsAuthenticated]);
    return (react_1.default.createElement("div", { className: "content-root" },
        react_1.default.createElement(Routes, null,
            react_1.default.createElement(Route, { path: "/", element: react_1.default.createElement(Login, { setIsAuthenticated: setIsAuthenticated }) }),
            routes.map(function (_a, index) {
                var path = _a.path, Component = _a.component, isProtected = _a.isProtected;
                return (react_1.default.createElement(Route, { key: index, path: path, element: isProtected ? (react_1.default.createElement(ProtectedRoute, { element: Component, isAuthenticated: isAuthenticated, redirectTo: "/" })) : (react_1.default.createElement(Component, null)) }));
            }))));
};
exports.default = Content;
