import Popup from "react-animated-popup";
import { useTheme } from "../ThemeContext";
import { useLogout } from "./hooks";

const CustomPopup = ({ visible, content, setVisible, setIsAuthenticated }) => {
  const { currentTheme } = useTheme();
  const logout = useLogout();
  const handleLogout = () => {
    setVisible(false);
    alert(0);
    logout();
    setIsAuthenticated(false);
  };

  return (
    <Popup
      style={{
        display: "flex",
        // flexDirection: "column",
        justifyContent: "center",
        maxWidth: "70vw",
        width: "70vw", // Set the width here
        background: currentTheme.loginPage.forceLoginBgColor,
        color: currentTheme.homePage.textColor,
      }}
      visible={visible}
      onClose={() => handleLogout()}
    >
      {content}
    </Popup>
  );
};

export default CustomPopup;
