import { createContext, useContext, useState } from "react";
import {
  getCallHistory,
  getDistinctPatientsData,
  getPatientsCallData,
  getCallAUDIO,
  getPatientsCallDataOld,
} from "../../../utils/CustomerUiAPI";
import { useEffect } from "react";
import OriginalData from "./call_history_sample.json";
// import DistintData from "./distinct_patients_data_1000_rows.json";
import DistintData from "./distinct_patients_data_1500_rows.json";
export const CallHistoryContext = createContext(null);

const useCalllHistoryService = () => {
  const [callerList, setCallerList] = useState([]);
  const [callList, setCallList] = useState([]);
  const [selectedCall, setSelectedCall] = useState("");
  const [callContent, setCallContent] = useState({});
  const [selectedMember, setSelectedMember] = useState("");
  const [audioUrl, setAudioUrl] = useState(null);
  const [callsData, setCallsData] = useState([]);

  const [renderType, setRenderType] = useState("callList");

  const [totalRows, setTotalRows] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [prevPageState, setPrevPageState] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterBy, setFilterBy] = useState("date");
  const [filterType, setFilterType] = useState("DESC");

  const transformPatientData = (data) => {
    let patientMetadata = [];
    patientMetadata = data.map((patient) => ({
      call_end_time: patient.call_end_time,
      call_id: patient.call_id,
      call_start_time: patient.call_start_time,
      caller_handler_type: patient.caller_handler_type,
      member_id: patient.member_id,
      member_name: patient.member_name,
    }));

    return patientMetadata;
  };

  const transformCallData = (info) => {
    const callData = info.map((call) => ({
      call_id: call.call_id,
      batch_request_document_id: call.batch_request_document_id,
      call_transcription_fragment: call.call_transcription_fragment.map(
        (fragment) => ({
          telecom_persona_type: fragment.telecom_persona_type,
          time_start: fragment.time_start,
          time_end: fragment.time_end,
          text: fragment.text,
          utterance_id: fragment.utterance_id,
        })
      ),
      overall_call_metadata: {
        before_transfer: {
          id: call.overall_call_metadata.before_transfer.id,
          call_start_time:
            call.overall_call_metadata.before_transfer.call_start_time,
          call_end_time:
            call.overall_call_metadata.before_transfer.call_end_time,
          total_talk_time:
            call.overall_call_metadata.before_transfer.total_talk_time,
          caller_name: call.overall_call_metadata.before_transfer.caller_name,
        },
        after_transfer: {
          id: call.overall_call_metadata.after_transfer.id,
          call_start_time:
            call.overall_call_metadata.after_transfer.call_start_time,
          call_end_time:
            call.overall_call_metadata.after_transfer.call_end_time,
          total_talk_time:
            call.overall_call_metadata.after_transfer.total_talk_time,
          caller_name: call.overall_call_metadata.after_transfer.caller_name,
        },
      },
      call_audio_url: call.call_audio_url,
      white_sheet: call.white_sheet,
      pdf_path: call.pdf_path,
      transcription_verified: call.transcription_verified,
      copay_verified: call.copay_verified,
      total_count: call.total_count,
    }));
    return callData;
  };

  const transformData = (data) => {
    // const distinctData = OriginalData.map((member) => ({
    //   member_id: member.member_id,
    //   member_name: member.member_name,
    // }));
    // Extracting patient_metadata
    const patientMetadata = data.map((patient) => ({
      call_end_time: patient.call_end_time,
      call_id: patient.call_id,
      call_start_time: patient.call_start_time,
      caller_handler_type: patient.caller_handler_type,
      member_id: patient.member_id,
      member_name: patient.member_name,
    }));

    const info = data.length > 0 ? data : callsData;
    // Extracting call_data
    const callData = info.map((call) => ({
      call_id: call.call_id,
      batch_request_document_id: call.batch_request_document_id,
      call_transcription_fragment: call.call_transcription_fragment.map(
        (fragment) => ({
          time_start: fragment.time_start,
          time_end: fragment.time_end,
          text: fragment.text,
          telecom_persona_type: fragment.telecom_persona_type,
          utterance_id: fragment.utterance_id,
        })
      ),
      overall_call_metadata: {
        before_transfer: {
          id: call.overall_call_metadata.before_transfer.id,
          call_start_time:
            call.overall_call_metadata.before_transfer.call_start_time,
          call_end_time:
            call.overall_call_metadata.before_transfer.call_end_time,
          total_talk_time:
            call.overall_call_metadata.before_transfer.total_talk_time,
          caller_name: call.overall_call_metadata.before_transfer.caller_name,
        },
        after_transfer: {
          id: call.overall_call_metadata.after_transfer.id,
          call_start_time:
            call.overall_call_metadata.after_transfer.call_start_time,
          call_end_time:
            call.overall_call_metadata.after_transfer.call_end_time,
          total_talk_time:
            call.overall_call_metadata.after_transfer.total_talk_time,
          caller_name: call.overall_call_metadata.after_transfer.caller_name,
        },
      },
      call_audio_url: call.call_audio_url,
    }));

    // Creating the transformed data object
    const transformedData = {
      // distinct_data: distinctData,
      patient_metadata: patientMetadata,
      call_data: callData,
    };
    setCallList(patientMetadata);
    // setCallerList(DistintData);
    // setCallContent(callData);
    return transformedData;
  };

  const getUniquePatientData = (
    memberId,
    batchRequestDocumentId,
    emailId,
    currentSessionId
  ) => {
    getDistinctPatientsData(
      memberId ? "member_id" : "",
      memberId,
      batchRequestDocumentId,
      emailId,
      currentSessionId
    )
      .then((res) => {
        const data = res.response;
        console.log(data, "getUniquePatientData callerListDebugger");
        setCallerList(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const fetchPatientCallData = (
    batchId = "",
    setLoading,
    page_no,
    page_size,
    setTotalRows,
    member_id,
    action = "",
    sortBy = "call_start_time",
    sortType = "DESC"
  ) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      console.log(sortType, sortBy, "sortBy");
      getPatientsCallData(
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId"),
        batchId || "",
        page_no,
        page_size,
        member_id,
        sortBy,
        sortType
      )
        .then((res) => {
          setLoading(false);
          const patientData = transformPatientData(res.response);
          const callData = transformCallData(res.response);
          if (res.response.length === 0) {
            setTotalRows(0);
          }
          if (callData && callData.length > 0) {
            // Update total rows and current page
            if (page_no === "1") {
              console.log("page", callData[0].total_count);
              setTotalRows(callData[0]?.total_count);
            }
            // setCurrentPage(page_no);
          }

          if (action === "resetState") {
            setPrevPageState([0]);
            setCurrentPage(1);
          }

          const callDataState =
            action === "resetState" ? callData : [...callsData, ...callData];

          setCallsData(callDataState);
          setCallList((prev) =>
            action === "resetState" ? patientData : [...prev, ...patientData]
          );
          resolve({ patientData, callData });
        })
        .catch((e) => {
          setLoading(false);
          console.log("Error fetching calls", e);
          reject(e); // Reject the promise in case of an error
        });
    });
  };

  const fetchPatientCallDataOld = (memberId, batchId = "", setLoading) => {
    // setLoading(true);
    getPatientsCallDataOld(
      memberId,
      sessionStorage.getItem("mail"),
      sessionStorage.getItem("sessionId"),
      batchId || ""
    )
      .then((res) => {
        setLoading(false);
        // setCallList(res.response);
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error fetching calls", e);
      });
  };

  // const fetchCallDetails = (id) => {
  //   const data = transformData();
  //   const index = data.call_data.findIndex((info) => info.call_id === id);
  //   setCallContent(data.call_data[index]);
  //   // getCallHistory(
  //   //   id,
  //   //   sessionStorage.getItem("mail"),
  //   //   sessionStorage.getItem("sessionId")
  //   // )
  //   //   .then((res) => setCallContent(res.response))
  //   //   .catch((e) => console.log("Error fetching calls", e));
  // };

  const fetchCallDetails = (id) => {
    const data = callsData;
    const index = data.findIndex((info) => info.call_id === id);
    console.log("PPOO", data[index]);
    setCallContent(data[index]);
  };

  const handleCallSelect = (id) => {
    setSelectedCall("");
    setSelectedCall(id);
    fetchCallDetails(id);
  };

  const getActiveCallInfo = () => {
    return callList?.find((item) => item.id === selectedCall);
  };

  const getSelectedMemberInfo = () => {
    return selectedMember && callerList[selectedMember]
      ? { member_id: selectedMember, member_name: callerList[selectedMember] }
      : {};
  };

  return {
    getUniquePatientData,
    callerList,
    fetchPatientCallData,
    callList,
    selectedCall,
    setSelectedCall,
    // fetchCallDetails,
    handleCallSelect,
    callContent,
    getActiveCallInfo,
    selectedMember,
    getSelectedMemberInfo,
    setSelectedMember,
    audioUrl,
    fetchPatientCallDataOld,
    renderType,
    setRenderType,
    filterBy,
    setFilterBy,
    filterType,
    setFilterType,
    totalRows,
    setTotalRows,
    itemsPerPage,
    setItemsPerPage,
    prevPageState,
    setPrevPageState,
    currentPage,
    setCurrentPage,
  };
};

export const CallHistoryProvider = ({ children }) => {
  const value = useCalllHistoryService();

  return (
    <CallHistoryContext.Provider value={value}>
      {children}
    </CallHistoryContext.Provider>
  );
};

export const useCallHistoryProvider = () => useContext(CallHistoryContext);
