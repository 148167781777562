import axios from "axios";
import { decryptData, encryptData } from "./utility-functions";

export const API_BASE_URL =
  process.env.REACT_APP_BACKEND_URL ?? process.env.REACT_APP_BACKEND_URL_DEV;
// export const API_BASE_URL = "http://127.0.0.1:5000";

export const BASIC_AUTH_HEADER = process.env.REACT_APP_BASIC_AUTH_HEADER;
async function makeRequest(
  endpoint,
  queryParams = {},
  method = "get",
  data = null
) {
  if (sessionStorage.getItem("userStatus") === "warned") return;

  // Manually construct the query parameter string
  const queryString = Object.keys(queryParams)
    .map((key) => {
      if (Array.isArray(queryParams[key])) {
        // If the value is an array, join its elements using commas
        return `${key}=${queryParams[key].join(",")}`;
      } else {
        return `${key}=${encodeURIComponent(queryParams[key])}`;
      }
    })
    .join("&");

  const url = `${API_BASE_URL}/${endpoint}?${queryString}`;

  try {
    let encryptedData = null;

    // Encrypt data for POST/PUT requests
    console.log("encrypterDebugger", method, data);
    if (data && (method === "post" || method === "put")) {
      encryptedData = encryptData(data); // Encrypt the data
      console.log("encrypterDebugger", encryptedData);
    }

    const response = await axios.request({
      method,
      url,
      headers: {
        Authorization: BASIC_AUTH_HEADER,
        "Content-Type": "application/json",
      },
      data: encryptedData || data, // Use encrypted data if applicable
    });

    if (response.status === 200 || response.status === 201) {
      // Decrypt the response data
      const decryptedResponse = decryptData(response.data);
      console.log(decryptedResponse, "decryptedResponseDebugger", endpoint);
      return decryptedResponse;
    }

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      sessionStorage.setItem("Error_401", true);
      console.error(error);
      if (sessionStorage.getItem("userStatus") === "notWarned") {
        sessionStorage.setItem("userStatus", "warned");
      } else {
        setTimeout(() => window.location.reload(), 1000);
      }
    }
    throw error;
  }
}

async function makeRequest2(
  endpoint,
  queryParams = {},
  method = "get",
  data = null
) {
  // Manually construct the query parameter string
  const queryString = Object.keys(queryParams)
    .map((key) => {
      if (Array.isArray(queryParams[key])) {
        // If the value is an array, join its elements using commas
        return `${key}=${queryParams[key].join(",")}`;
      } else {
        return `${key}=${encodeURIComponent(queryParams[key])}`;
      }
    })
    .join("&");

  const url = `${API_BASE_URL}/${endpoint}?${queryString}`;

  try {
    const response = await axios.request({
      method,
      url,
      headers: {
        Authorization: BASIC_AUTH_HEADER,
        "Content-Type": "application/json",
      },
      data,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Set sessionStorage item if error is 401
      sessionStorage.setItem("Error_401", true);
    }
    throw error;
  }
}
// API functions with named exports
export const getDistinctPatientsData = async (
  search_key,
  search_value,
  batchRequestDocumentId,
  emailId,
  currentSessionId
) => {
  try {
    return await makeRequest("get_distinct_patients_data", {
      search_key,
      search_value,
      batchRequestDocumentId,
      emailId,
      currentSessionId,
    });
  } catch (error) {
    // Handle error as needed
    console.error("Error in getDistinctPatientsDataa:", error);
    throw error; // Re-throw the error to be handled at a higher level
  }
};

export const getPatientsCallData = async (
  emailId,
  currentSessionId,
  batchRequestDocumentId,
  page_no,
  page_size,
  memberId,
  sortBy,
  sortType
) => {
  try {
    // Manually construct the filter_columns parameter as a single string
    const filter_columns =
      "['call_id','call_start_time','call_end_time','caller_handler.caller_handler_type','member_id','payload']";

    // Create the request parameters object
    const requestParams = {
      // filter_columns,
      emailId,
      currentSessionId,
      page_no,
      page_size,
      sort_by: sortBy,
      order_type: sortType,
    };

    if (memberId) {
      requestParams.memberId = memberId;
    }

    // Conditionally add batchRequestDocumentId to the request parameters
    if (batchRequestDocumentId) {
      requestParams.batchRequestDocumentId = batchRequestDocumentId;
    }

    return await makeRequest("get_patients_metadata", requestParams);
  } catch (error) {
    console.error("Error in getPatientsCallData:", error);
    throw error;
  }
};

export const getPatientsCallDataPDF = async (
  emailId,
  currentSessionId,
  pdf_uri
) => {
  try {
    // Create the request parameters object
    const requestParams = {
      pdf_uri,
      emailId,
      currentSessionId,
    };

    return await makeRequest("get_patients_metadata", requestParams);
  } catch (error) {
    console.error("Error in getPatientsCallDataPDF:", error);
    throw error;
  }
};

export const getPatientsCallDataOld = async (
  memberId,
  emailId,
  currentSessionId,
  batchRequestDocumentId
) => {
  try {
    // Manually construct the filter_columns parameter as a single string
    const filter_columns =
      "['call_id','call_start_time','call_end_time','caller_handler.caller_handler_type','member_id','payload']";

    // Create the request parameters object
    const requestParams = {
      filter_columns,
      emailId,
      currentSessionId,
    };

    // Conditionally add batchRequestDocumentId to the request parameters
    if (memberId) {
      requestParams.memberId = memberId;
    }
    if (batchRequestDocumentId) {
      requestParams.batchRequestDocumentId = batchRequestDocumentId;
    }

    return await makeRequest("get_patients_metadata", requestParams);
  } catch (error) {
    console.error("Error in getPatientsCallData:", error);
    throw error;
  }
};

export const getCallHistory = (call_id, emailId, currentSessionId) => {
  try {
    const call_columns =
      "['call_duration', 'white_sheet','call_transcription_fragment','call_start_time','call_end_time','call_id','batch_request_document_id','call_audio_url', 'overall_call_metadata']";
    return makeRequest("get_call_history", {
      call_columns,
      call_id,
      emailId,
      currentSessionId,
    });
  } catch (error) {
    console.error("Error in getCallHistory:", error);
    throw error;
  }
};

export const getBatchStatus = (
  emailId,
  currentSessionId,
  batch_request_document_id
) => {
  try {
    return makeRequest("get_batch_status", {
      emailId,
      currentSessionId,
      batch_request_document_id,
    });
  } catch (error) {
    console.error("Error in getCallHistory:", error);
    throw error;
  }
};

export const getCallAndProcessData = async (
  emailId,
  currentSessionId,
  batch_request_id
) => {
  try {
    return await makeRequest("get_call_and_process_data", {
      emailId,
      currentSessionId,
      batchRequestDocumentId: batch_request_id,
    });
  } catch (error) {
    console.error("Error in getCallAndProcessData:", error);
    throw error;
  }
};

export const getErrorData = async (
  batch_request_id,
  emailId,
  currentSessionId
) => {
  try {
    return await makeRequest("get_error_data", {
      batch_request_id,
      emailId,
      currentSessionId,
    });
  } catch (error) {
    console.error("Error in getErrorData:", error);
    throw error;
  }
};

export const getProviderData = async (emailId, currentSessionId) => {
  try {
    return await makeRequest("get_provider_metadata", {
      emailId,
      currentSessionId,
    });
  } catch (error) {
    console.error("Error in getProviderData:", error);
    throw error;
  }
};

export const getBatchData = async (providerId, emailId, currentSessionId) => {
  try {
    return await makeRequest("get_batch_data", {
      providerId,
      emailId,
      currentSessionId,
    });
  } catch (error) {
    console.error("Error in getCustomerData:", error);
    throw error;
  }
};

export const getBatchDataCSV = async (csv_uri, emailId, currentSessionId) => {
  try {
    const response = await makeRequest("get_batch_data", {
      csv_uri,
      emailId,
      currentSessionId,
    });
    return response.csv_data;
  } catch (error) {
    console.error("Error in getCustomerData:", error);
    throw error;
  }
};

export const getHomePageDownloadData = async (
  providerId,
  emailId,
  currentSessionId,
  call_post_process_data,
  batch_request_id
) => {
  try {
    return await makeRequest("get_batch_data", {
      providerId,
      emailId,
      currentSessionId,
      call_post_process_data,
      batch_request_id,
    });
  } catch (error) {
    console.error("Error in getCustomerData:", error);
    throw error;
  }
};

export const insertUserActivity = async (
  emailId,
  activityType,
  forceFlag,
  sessionId
) => {
  try {
    return await makeRequest(
      "insert_user_activity",
      { emailId, activityType, forceFlag, sessionId },
      "post"
    );
  } catch (error) {
    console.error("Error in insertUserActivity:", error);
    throw error;
  }
};

export const pushProviderPayorData = async (
  requestData,
  emailId,
  currentSessionId
) => {
  try {
    return await makeRequest(
      "push_provider_payor_data",
      { emailId, currentSessionId },
      "post",
      JSON.stringify(requestData)
    );
  } catch (error) {
    console.error("Error in pushProviderPayorData:", error);
    throw error;
  }
};

export const pushProviderPatientData = async (
  requestData,
  emailId,
  currentSessionId,
  batch_request_id
) => {
  try {
    return await makeRequest(
      "push_provider_patient_data",
      { emailId, currentSessionId, batch_request_id },
      "post",
      JSON.stringify(requestData)
    );
  } catch (error) {
    console.error("Error in pushProviderPayorData:", error);
    throw error;
  }
};

export const pushDataToFileBucket = async (
  requestData,
  emailId,
  currentSessionId,
  batch_request_id
) => {
  try {
    // Construct the request parameters
    const requestParams = { emailId, currentSessionId, batch_request_id };

    // Use the makeRequest function for the POST request and encrypt data
    return await makeRequest(
      "push_file_to_bucket", // endpoint
      requestParams, // query params
      "post", // method
      requestData // data (body)
    );
  } catch (error) {
    console.error("Error in pushDataToFileBucket:", error);
    throw error;
  }
};

export const getCustomerUiRbca = async (role, emailId, currentSessionId) => {
  try {
    return await makeRequest("get_customer_ui_rbca", {
      role,
      emailId,
      currentSessionId,
    });
  } catch (error) {
    console.error("Error in getCustomerUiRbca:", error);
    throw error;
  }
};

export const getAllCustomerUiRbca = async (emailId, currentSessionId) => {
  try {
    return await makeRequest("get_customer_ui_rbca", {
      emailId,
      currentSessionId,
    });
  } catch (error) {
    console.error("Error in getCustomerUiRbca:", error);
    throw error;
  }
};

export const addUserRole = async (requestData, emailId, currentSessionId) => {
  try {
    // Construct the request parameters
    const requestParams = { emailId, currentSessionId };

    // Use the makeRequest function for the POST request and encrypt data
    return await makeRequest(
      "post_customer_ui_rbca", // endpoint
      requestParams, // query params
      "post", // method
      requestData // data (body)
    );
  } catch (error) {
    console.error("Error in addUserRole:", error);
    throw error;
  }
};

export const updateUserRole = async (
  requestData,
  emailId,
  currentSessionId
) => {
  try {
    // Construct the request parameters
    const requestParams = { emailId, currentSessionId };

    // Use the makeRequest function for the PUT request
    return await makeRequest(
      "update_customer_ui_rbca", // endpoint
      requestParams, // query params
      "put", // method
      requestData // data (body)
    );
  } catch (error) {
    console.error("Error in updateUserRole:", error);
    throw error;
  }
};

export const deleteUserRole = async (
  requestData,
  emailId,
  currentSessionId
) => {
  try {
    // Construct the request parameters
    const requestParams = { emailId, currentSessionId };

    // Use the makeRequest function for the DELETE request
    return await makeRequest(
      "delete_customer_ui_rbca", // endpoint
      requestParams, // query params
      "delete", // method
      requestData // data (body)
    );
  } catch (error) {
    console.error("Error in deleteUserRole:", error);
    throw error;
  }
};

export const getCallAUDIO = async (audio_url, emailId, currentSessionId) => {
  if (audio_url) {
    try {
      // Construct the request params
      const requestParams = {
        emailId,
        currentSessionId,
      };

      // Prepare the request body (audio_url)
      const requestBody = { audio_url };

      // Use the makeRequest function for the POST request
      return await makeRequest(
        "get_audio_response_post", // endpoint
        requestParams, // query params
        "post", // method
        requestBody // data (body)
      );
    } catch (error) {
      console.error("Error in getCallAUDIO:", error);
      throw error;
    }
  }
};

export const getCustomerUiUser = async (emailId, currentSessionId, email) => {
  const requestParams = {
    emailId,
    currentSessionId,
  };
  if (email) {
    requestParams.email = email;
  }
  try {
    return await makeRequest("get_customer_ui_user", requestParams);
  } catch (error) {
    console.error("Error in getCustomerUiRbca:", error);
    throw error;
  }
};

export const addCustomerUiUser = async (
  requestData,
  emailId,
  currentSessionId
) => {
  try {
    // Construct request params
    const requestParams = {
      emailId,
      currentSessionId,
    };

    // Use the makeRequest function for the POST request
    return await makeRequest(
      "post_customer_ui_user", // endpoint
      requestParams, // query params
      "post", // method
      requestData // data (body)
    );
  } catch (error) {
    console.error("Error in addCustomerUiUser:", error);
    throw error;
  }
};

export const updateCustomerUiUser = async (
  requestData,
  emailId,
  currentSessionId,
  clinicName
) => {
  try {
    // Construct request params
    const requestParams = {
      emailId,
      currentSessionId,
      clinicName,
    };

    // Use the makeRequest function for the PUT request
    return await makeRequest(
      "update_customer_ui_user", // endpoint
      requestParams, // query params
      "put", // method
      requestData // data (body)
    );
  } catch (error) {
    console.error("Error in updateCustomerUiUser:", error);
    throw error;
  }
};

export const deleteCustomerUiUser = async (
  email,
  emailId,
  currentSessionId
) => {
  try {
    // Construct request params
    const requestParams = {
      emailId,
      currentSessionId,
      email,
    };

    // Use the makeRequest function for the DELETE request
    return await makeRequest(
      "delete_customer_ui_user", // endpoint
      requestParams, // query params
      "delete" // method
    );
  } catch (error) {
    console.error("Error in deleteCustomerUiUser:", error);
    throw error;
  }
};

// export const addQuestionsFormNewTask = async (
//   requestData,
//   emailId,
//   currentSessionId
// ) => {
//   try {
//     const encryptedData = encryptData(requestData)
//     const response = await axios({
//       method: "POST",
//       url: `${API_BASE_URL}/questions_form_new_task`,
//       data: encryptedData,
//       params: { emailId, currentSessionId },
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: BASIC_AUTH_HEADER,
//       },
//     });
//     return response;
//   } catch (error) {
//     return error;
//   }
// };

export const addQuestionsFormNewTask = async (
  requestData,
  emailId,
  currentSessionId
) => {
  try {
    // Construct request params
    const requestParams = {
      emailId,
      currentSessionId,
    };

    // Make the request using the makeRequest function and encrypt the data
    return await makeRequest(
      "questions_form_new_task", // endpoint
      requestParams, // query params
      "post", // method
      requestData // data (this will be encrypted inside the makeRequest function)
    );
  } catch (error) {
    console.error("Error in addQuestionsFormNewTask:", error);
    throw error; // re-throw the error for higher-level handling
  }
};

export const getQuestionsFromTask = async (
  emailId,
  currentSessionId,
  taskID
) => {
  const requestParams = {
    emailId,
    currentSessionId,
  };
  if (taskID) {
    requestParams.taskID = taskID;
  }
  try {
    return await makeRequest("get_questions_forms_tasks", requestParams);
  } catch (error) {
    console.error("Error in getQuestionsFromTask:", error);
    throw error;
  }
};

export const getCommonQuestions = async (emailId, currentSessionId) => {
  const requestParams = {
    emailId,
    currentSessionId,
  };

  try {
    return await makeRequest("get_common_questions", requestParams);
  } catch (error) {
    console.error("Error in get_common_questions:", error);
    throw error;
  }
};

export const getQuestionsForPage2 = async (
  emailId,
  currentSessionId,
  task_id,
  created_date
) => {
  const requestParams = {
    emailId,
    currentSessionId,
    created_date,
  };
  if (task_id) {
    requestParams.task_id = task_id;
  }
  try {
    return await makeRequest("get_questions_forms_task_data", requestParams);
  } catch (error) {
    console.error("Error in get_questions_forms_task_data:", error);
    throw error;
  }
};

export const completeQuestionReview = async (
  requestData,
  emailId,
  currentSessionId,
  task_id
) => {
  try {
    // Construct request params
    const requestParams = {
      emailId,
      currentSessionId,
      task_id,
    };

    // Use the makeRequest function for the POST request
    return await makeRequest(
      "questions_form_operation", // endpoint
      requestParams, // query params
      "post", // method
      requestData // data (encrypted inside makeRequest)
    );
  } catch (error) {
    console.error("Error in completeQuestionReview:", error);
    throw error;
  }
};

export const getAnalyticData = async (start_date, end_date) => {
  try {
    // Construct request params
    const requestParams = {
      currentSessionId: sessionStorage.getItem("sessionId"),
      emailId: sessionStorage.getItem("mail"),
      start_date,
      end_date,
    };

    // Make the request using the makeRequest function
    return await makeRequest(
      "get_analytics", // endpoint
      requestParams, // query params
      "get" // method
    );
  } catch (error) {
    console.error("Error in getAnalyticData:", error);
    throw error; // re-throw the error for higher-level handling
  }
};
