import { Grid, Button, IconButton, SvgIcon } from "@mui/material";

const CallHistoryIcon = ({ themeMode }) => (
//   <SvgIcon>
    <img
      height={"23px"}
      src={
        themeMode === "dark"
          ? "/icons/call-history-mini-grey.svg"
          : "/icons/call-history-mini-grey-light.svg"
      }
      alt="Call History"
    />
//   </SvgIcon>
);

const CallProcessIcon = ({ themeMode }) => (
//   <SvgIcon>
    <img
      height={"23px"}
      src={
        themeMode === "dark"
          ? "/icons/call-process-mini-grey.svg"
          : "/icons/call-process-mini-grey-light.svg"
      }
      alt="Call Process"
    />
//   </SvgIcon>
);

const ActionButtons = ({ themeMode, row, handleCallHistoryClick, handleCallProcessClick, handleDownload }) => (
  <Grid
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      p:0, m:0
    }}
  >
    <IconButton onClick={() => handleCallHistoryClick(row.batch_request_id)} title="Call History">
      <CallHistoryIcon themeMode={themeMode} />
    </IconButton>
    <IconButton onClick={() => handleCallProcessClick(row.batch_request_id)} title="Call Status">
      <CallProcessIcon themeMode={themeMode} />
    </IconButton>
    <Button
      onClick={() => handleDownload(row)}
      sx={{
        width: "12vh",
        height: "2.6vh",
        color: "black",
        backgroundColor: themeMode === "dark" ? "#a8a7b4" : "#e1e1e1",
        outline: "none",
        // margin: "1vh",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "1vh",
        cursor: "pointer",
        fontSize: "0.7rem",
      }}
    >
      Download
    </Button>
  </Grid>
);

export default ActionButtons;
