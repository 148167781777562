import { ResponsivePie } from "@nivo/pie";
import React, { useState, useEffect } from "react";
import { useTheme } from "../../../../ThemeContext";

const CustomLegend = ({ data, onHover }) => {
  const { themeMode } = useTheme();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "10px",
        flexWrap:"wrap"
      }}
    >
      {data.map((item) => (
        <div
          key={item.id}
          onMouseEnter={() => onHover(item.id)}
          onMouseLeave={() => onHover(null)}
          style={{
            display: "flex",
            alignItems: "center",
            margin: "0 10px",
            cursor: "pointer",
            color: themeMode === "dark" ? "#ffffff" : "#000000",
            fontSize: "14px",
          }}
        >
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: item.color,
              marginRight: "8px",
            }}
          />
          {item.label}
        </div>
      ))}
    </div>
  );
};

const LoadingSpinner = () => {
  const { themeMode } = useTheme();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
        height: "100%",
        backgroundColor: themeMode === "dark" ? "#242344" : "#e3e2e5",
        borderRadius: "1rem",
      }}
    >
      <div
        className="spinner"
        style={{
          border: "4px solid rgba(255, 255, 255, 0.1)",
          borderRadius: "50%",
          borderTop: "4px solid #ff4e3a",
          width: "40px",
          height: "40px",
          animation: "spin 1s linear infinite",
        }}
      ></div>
      <style>
        {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
      </style>
    </div>
  );
};

const PieChart = ({ data, height = "450px", title }) => {
  const themeShades = ["#e64533", "#cc3c2d", "#b33327", "#992a20", "#80211a"];
  const [loading, setLoading] = useState(true);
  const [hoveredId, setHoveredId] = useState(null);

  const { themeMode } = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000); // Show loading for 1 second
    return () => clearTimeout(timer);
  }, []);

  const transformedData = data.map((d, index) => ({
    ...d,
    id: d.fullName,
    label: d.shortName,
    color: themeShades[index % themeShades.length],
  }));

  const filteredData = hoveredId
    ? transformedData.filter((d) => d.id === hoveredId)
    : transformedData;

  return (
    <div
      style={{
        height,
        backgroundColor: themeMode === "dark" ? "#242344" : "#e3e2e5",
        padding: "20px",
        borderRadius: "1rem",
        boxSizing: "border-box",
        overflow: "hidden",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
        position: "relative", // Ensure title is positioned correctly
      }}
    >
      <h3
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          margin: 0,
          color: themeMode === "dark" ? "#ffffff" : "#000000",
          zIndex: 10,
        }}
      >
        {title}
      </h3>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div style={{ height: "100%" }}>
          <CustomLegend data={transformedData} onHover={setHoveredId} />
          <ResponsivePie
            data={filteredData}
            margin={{ top: 80, right: 170, bottom: 80, left: 170 }} // Increased margins to prevent cutoff
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={themeShades}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            radialLabel={(d) => (hoveredId === d.id ? d.label : "")} // Only show label if hovered
            radialLabelsTextColor={themeMode === "dark" ? "#ffffff" : "#000000"}
            radialLabelsLinkColor={{ from: "color" }}
            radialLabelsLinkHorizontalLength={24} // Increased to make labels more visible
            radialLabelsLinkDiagonalLength={16} // Increased to make labels more visible
            radialLabelsLinkOffset={-8} // Offset the links to avoid overlapping
            radialLabelsLinkStrokeWidth={1}
            sliceLabelsTextColor={themeMode === "dark" ? "#ffffff" : "#000000"}
            theme={{
              tooltip: {
                container: {
                  background: themeMode === "dark" ? "#242344" : "#e3e2e5",
                  color: themeMode === "dark" ? "#ffffff" : "#000000",
                },
              },
              labels: {
                text: {
                  fill: themeMode === "dark" ? "#ffffff" : "#000000",
                  whiteSpace: "break-spaces",
                  fontSize:'10px'
                },
              },
              legends: {
                text: {
                  fill: themeMode === "dark" ? "#ffffff" : "#000000",
                },
              },
            }}
            legends={[]} // Remove default legends
            animate={true}
            motionConfig="wobbly"
          />
        </div>
      )}
    </div>
  );
};

export default PieChart;
