import "../IntakeForm.css";
import { useTheme } from "../../../ThemeContext";
import { useEffect, useState } from "react";
import data from "../../../utils/dummyData/page2.json";
import {
  completeQuestionReview,
  getQuestionsForPage2,
} from "../../../utils/CustomerUiAPI";
import Button from "../../common/button/Button";
import DataTableMini from "../../common/table/DataTableMini/DataTableMini";
import DataTable from "../../common/table/DataTable/DataTable";
import CustomMuiTable from "../../common/custom-mui-table/CustomDataGridBackendPagination/Index";
import { Table } from "@voicecare.ai/ui-library";

const Page2 = ({ taskId, createdDate, setIdClicked }) => {
  const { themeMode, currentTheme } = useTheme();
  const [isMedical, setIsMedical] = useState(true);
  const [response, setResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getQuestionsForPage2(
      sessionStorage.getItem("mail"),
      sessionStorage.getItem("sessionId"),
      taskId,
      createdDate
    )
      .then((res) => {
        setResponse(res.response);
      })
      .catch((res) => {
        console.log(res);
      });
  }, []);

  const questionTypes = [
    { value: "yes/no", label: "Yes/No" },
    { value: "text", label: "Text" },
    { value: "numerical", label: "Numerical" },
    { value: "date", label: "Date" },
  ];

  const operationTypes = [
    { value: "insert", label: "Insertion" },
    { value: "delete", label: "Deletion" },
    { value: "update", label: "Update" },
    { value: "none", label: "None" },
  ];

  const handleQuestionTypeChange = (row, selectedValue) => {
    const updatedResponse = { ...response };

    if (isMedical) {
      updatedResponse.medical = updatedResponse.medical.map((item) =>
        item.referenceId === row.referenceId
          ? { ...item, originalQuestionType: selectedValue.target.value }
          : item
      );
    } else {
      updatedResponse.dental = updatedResponse.dental.map((item) =>
        item.referenceId === row.referenceId
          ? { ...item, originalQuestionType: selectedValue.target.value }
          : item
      );
    }
    debugger;
    setResponse(updatedResponse);
  };

  const handleOperationTypeChange = (row, selectedValue) => {
    const updatedResponse = { ...response };

    if (isMedical) {
      updatedResponse.medical = updatedResponse.medical.map((item) =>
        item.referenceId === row.referenceId
          ? { ...item, operation: selectedValue.target.value }
          : item
      );
    } else {
      updatedResponse.dental = updatedResponse.dental.map((item) =>
        item.referenceId === row.referenceId
          ? { ...item, operation: selectedValue.target.value }
          : item
      );
    }

    setResponse(updatedResponse);
  };

  const onSubmit = () => {
    setIsLoading(true);
    completeQuestionReview(
      response,
      sessionStorage.getItem("mail"),
      sessionStorage.getItem("sessionId"),

      sessionStorage.getItem("currentIntakeTaskId")
    )
      .then(() => {
        setIsLoading(false);
        handleBack();
      })
      .catch(() => setIsLoading(false));
  };

  const userTableColumns = [
    {
      name: "referenceID",
      label: "Reference ID",
      selector: (row) => row.referenceId,
      width: "45vh",
    },
    {
      name: "originalQuestion",
      label: "Original Question",
      selector: (row) => row.originalQuestion,
      width: "45vh",
    },
    {
      name: "updatedQuestion",
      label: "Updated Question",
      selector: (row) => row.updatedQuestion,
      width: "45vh",
    },
    {
      name: "Action",
      label: "Original Question Type",
      selector: (row) => row.originalQuestionType,
      width: "45vh",
      render: (row) => (
        <select
          disabled={editDisabled}
          style={{
            background: "none",
            color: currentTheme.settingPage.textColor,
          }}
          value={row.originalQuestionType}
          onChange={(value) => handleQuestionTypeChange(row, value)}
        >
          {questionTypes.map((ques) => (
            <option style={{ backgroundColor: "none" }} value={ques.value}>
              {ques.label}
            </option>
          ))}
        </select>
      ),
    },
    {
      name: "Action",
      label: "Operation",
      selector: (row) => row.operation,
      width: "45vh",
      render: (row) => (
        <select
          disabled={editDisabled}
          style={{
            background: "none",
            color: currentTheme.settingPage.textColor,
          }}
          value={row.operation}
          onChange={(value) => handleOperationTypeChange(row, value)}
        >
          {operationTypes.map((ques) => (
            <option value={ques.value}>{ques.label}</option>
          ))}
        </select>
      ),
    },
  ];

  const getFreqColor = (isSelected) => {
    if (isSelected) {
      return themeMode === "dark" ? "#ff4e3a" : "#ff4e3a";
    } else {
      return themeMode === "dark" ? "#3e3b56" : "#e3e2e5";
    }
  };

  const getFreqTextColor = (isSelected) => {
    if (isSelected) {
      return themeMode === "dark" ? "#ffffff" : "#ffffff";
    } else {
      return themeMode === "dark" ? "#ffffff" : "#04002b";
    }
  };

  const handleBack = () => {
    setIdClicked(false);
  };

  const editDisabled =
    sessionStorage.getItem("currentIntakeStatus") === "complete";

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const flatRowCreator = (rows) => {
    const flatten = rows.map((row) => ({
      ...row,
      originalQuestionType: (
        <select
          disabled={editDisabled}
          style={{
            background: "none",
            color: currentTheme.settingPage.textColor,
          }}
          value={row.originalQuestionType}
          onChange={(value) => handleQuestionTypeChange(row, value)}
        >
          {questionTypes.map((ques) => (
            <option style={{ backgroundColor: "none" }} value={ques.value}>
              {ques.label}
            </option>
          ))}
        </select>
      ),
      operation: (
        <select
          disabled={editDisabled}
          style={{
            background: "none",
            color: currentTheme.settingPage.textColor,
          }}
          value={row.operation}
          onChange={(value) => handleOperationTypeChange(row, value)}
        >
          {operationTypes.map((ques) => (
            <option value={ques.value}>{ques.label}</option>
          ))}
        </select>
      ),
    }));

    return flatten;
  };

  const columns = [
    { headerName: "Reference ID", renderName: "referenceId" },
    { headerName: "Original Question", renderName: "originalQuestion" },
    { headerName: "Updated Question", renderName: "updatedQuestion" },
    {
      headerName: "Original Question Type",
      renderName: "originalQuestionType",
    },
    { headerName: "Operation", renderName: "operation" },
  ];

  return (
    <div
      className={`intake-root`}
      style={{
        background: currentTheme.settingPage.mainBackground,
        color: currentTheme.settingPage.textColor,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "20vw", display: "flex" }}>
          <div className="intake-medical-type" style={{ width: "100%" }}>
            <button
              className={isMedical ? "switch-freq-selected" : "switch-freq"}
              style={{
                backgroundColor: getFreqColor(isMedical),
                color: getFreqTextColor(isMedical),
              }}
              onClick={() => setIsMedical(true)}
            >
              Medical
            </button>
            <button
              className={!isMedical ? "switch-freq-selected" : "switch-freq"}
              style={{
                backgroundColor: getFreqColor(!isMedical),
                color: getFreqTextColor(!isMedical),
              }}
              onClick={() => setIsMedical(false)}
            >
              Dental
            </button>
          </div>
        </div>
        <div>
          <Button
            label={"Back"}
            onClick={handleBack}
            backgroundColor={"#ff4e3a"}
            width={"15vh"}
            style={{
              borderRadius: "1.5vh",
              marginTop: "1vh",
              marginRight: "1vh",
            }}
            height={"4vh"}
          />
          <Button
            label={"Submit"}
            onClick={onSubmit}
            backgroundColor={"#ff4e3a"}
            width={"15vh"}
            style={{ borderRadius: "1.5vh", marginTop: "1vh" }}
            height={"4vh"}
            disabled={editDisabled}
          />
        </div>
      </div>

      {isLoading ? (
        <img
          className="loader"
          src={
            themeMode === "dark"
              ? "/icons/loader_white.gif"
              : "/icons/loader_black.gif"
          }
        />
      ) : (
        <div
          style={{
            width: "100%",
            marginTop: "2rem",
            height: "-webkit-fill-available",
            marginBottom: "2rem",
          }}
        >
          {response &&
            (response.medical || response.dental) &&
            (response.medical.length > 0 || response.dental.length > 0) && (
              // <DataTable
              //   headerColor={currentTheme.settingPage.tableHeader}
              //   dataColor={currentTheme.settingPage.search}
              //   headerTextColor={currentTheme.settingPage.textColor}
              //   data={isMedical ? response.medical : response.dental}
              //   columns={userTableColumns}
              //   itemsPerPageOptions={[5, 10, 20]}
              //   defaultItemsPerPage={10}
              //   height={"56vh"}
              // />

              <Table
                rows={
                  isMedical
                    ? flatRowCreator(response.medical)
                    : flatRowCreator(response.dental)
                }
                columns={columns}
                frontendSort
                noCustomizeColumn
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                containerHeight="63vh"
                themeMode={themeMode}
              />
            )}
        </div>
      )}
    </div>
  );
};
export default Page2;
