import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "../../../../ThemeContext";

const BarChart = ({ data, title }) => {
  const { themeMode } = useTheme();

  // Extract keys dynamically from data
  const keys = Object.keys(data[0]).filter(
    (key) => key !== "date" && key !== "created_date"
  );

  const colors = ["#ff4e3a", "#b2b2b2", "#e64533", "#cc3c2d"]; // Adjust or add more colors as needed

  return (
    <div
      style={{
        height: "400px",
      }}
    >
      <h2
        style={{
          color: themeMode === "dark" ? "#ffffff" : "#000000",
          textAlign: "left",
          marginBottom: "20px",
        }}
      >
        {title}
      </h2>
      <ResponsiveBar
        data={data}
        keys={keys} // Dynamic keys
        indexBy="date"
        margin={{ top: 10, right: 50, bottom: 90, left: 50 }} // Increased bottom margin for x-axis labels
        padding={0.3}
        colors={({ id }) => colors[keys.indexOf(id) % colors.length]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0, // Remove rotation to keep labels horizontal
          legend: "",
          legendPosition: "middle",
          legendOffset: 40, // Increase offset if needed for label spacing
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [[themeMode === "dark" ? "brighter" : 'darker', 20]] }}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: themeMode === "dark" ? "#ffffff" : "#000000",
              },
            },
            legend: {
              text: {
                fill: themeMode === "dark" ? "#ffffff" : "#000000",
              },
            },
          },
          tooltip: {
            container: {
              background: themeMode === "dark" ? "#242344" : "#e3e2e5",
              color: themeMode === "dark" ? "#ffffff" : "#000000",
            },
          },
        }}
        animate={true}
        motionConfig="wobbly"
        groupMode="grouped" // Use "stacked" for stacked bars instead of grouped
        tooltip={({ id, value, indexValue }) => (
          <div
            style={{
              padding: "5px 10px",
              backgroundColor: themeMode === "dark" ? "#242344" : "#e3e2e5",
              color: themeMode === "dark" ? "#ffffff" : "#000000",
            }}
          >
            <strong>
              {indexValue} - {id}
            </strong>
            : {value}
          </div>
        )}
      />
    </div>
  );
};

const CallHandledCount = ({ data, title }) => {
  const { themeMode } = useTheme();
  return (
    <div
      style={{
        height: "450px",
        backgroundColor: themeMode === "dark" ? "#242344" : "#e3e2e5",
        padding: "20px",
        borderRadius: "1rem",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
        position: "relative",
      }}
    >
      <BarChart data={data} title={title} />
    </div>
  );
};

export default CallHandledCount;
