"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dropdown_multi_selection_styles_1 = require("./helpers/dropdown-multi-selection-styles");
var utils = {
    styles: {
        dropdowns: {
            multiSelectStyles: dropdown_multi_selection_styles_1.multiSelectDropdownStyles,
        },
    },
};
exports.default = utils;
