import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Layout.css";
// import Sidebar from "../sidebar/Sidebar";
// import Header from "../header/Header";
import Content from "../content/Content";
// import Footer from "../footer/Footer";
import { useLogout } from "../../utils/hooks";
import LogoutTimer from "../login/LogoutTimer";
import CustomPopup from "../../utils/CustomPopup";
import PopupContent from "./PopupContent";
// import { SidebarProvider } from "../sidebar/SidebarContext";

import {
  Footer,
  Header,
  Sidebar,
  SidebarProvider,
  useMenu,
  CustomDialog,
} from "@voicecare.ai/ui-library";
import { useTheme } from "../../ThemeContext";
import { insertUserActivity } from "../../utils/CustomerUiAPI";

const Layout = () => {
  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] =
    useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { selectedMenu, selectMenu } = useMenu();
  const { themeMode, currentTheme } = useTheme();

  const handleOpenLogoutConfirmation = () => setIsLogoutConfirmationOpen(true);
  const handleCloseLogoutConfirmation = () =>
    setIsLogoutConfirmationOpen(false);

  const handleLogout = async () => {
    await logout();
    handleCloseLogoutConfirmation();
    setTimeout(() => setIsAuthenticated(false), [2000]);
  };

  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // Check if the current route is "/"
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  );

  // const setIsAuthenticated = (status) => {
  //   localStorage.setItem("isAuthenticated", status);
  // };

  const setIsAuthenticatedAndPersist = (status) => {
    setIsAuthenticated(status);
    localStorage.setItem("isAuthenticated", status);
  };

  const isHomeRoute =
    location.pathname === "/" || location.pathname === "/success";

  const logout = useLogout();
  const [error, setError] = useState(
    sessionStorage.getItem("Error_401") === "true"
  );

  useEffect(() => {
    const storedError = sessionStorage.getItem("Error_401") === "true";
    setError(storedError);
  }, [sessionStorage.getItem("Error_401")]);

  const handleClose = () => {
    logout();
    setIsAuthenticated(false);
    setError(false);
    sessionStorage.setItem("Error_401", "false");
  };

  // const isLoginFirstTime = sessionStorage.getItem("isLoginFirstTime");
  // const [renderFirstTimePopup, setRenderFirstTimePopup] = useState(false);

  // const handleClosePopup = (bool) => {
  //   if (!bool) {
  //     setRenderFirstTimePopup(false);
  //     sessionStorage.setItem("isLoginFirstTime", false);
  //   }
  // };

  // useEffect(() => {
  //   if (isLoginFirstTime === "true") {
  //     setRenderFirstTimePopup(true);
  //   } else {
  //     setRenderFirstTimePopup(false);
  //   }
  // }, [isLoginFirstTime]);

  const customeruiMenus = [
    {
      name: "Home",
      path: "/home",
      icon: "Home",
    },
    {
      name: "Call Automation",
      path: "/call-automation",
      icon: "Call Automation",
    },
    {
      name: "Call History",
      path: "/call-history",
      icon: "Call History",
    },
    {
      name: "Analytics",
      path: "/analytics",
      icon: "Analytics"
    },
    // {
    //   name: "Advanced Analytics",
    //   path: "/advancedAnalytics",
    //   icon: "Analytics",
    // },
    {
      name: "Settings",
      path: "/settings",
      icon: "settings",
    },
  ];

  // const handleLogout = () => {
  //   setIsAuthenticated(false);
  //   insertUserActivity(
  //     sessionStorage.getItem("mail"),
  //     "logoutTime",
  //     "True",
  //     sessionStorage.getItem("sessionId")
  //   );
  //   logout();
  // };

  useEffect(() => {
    localStorage.removeItem("visibleColumns");
  }, [location, navigate]);

  // const isVisible = () => {
  //   return sessionStorage.getItem("Error_401") === "true";
  // };

  const isVisible = () => {
    // Check if the current URL is the root
    if (window.location.pathname === "/") {
      sessionStorage.setItem("Error_401", "false");
      return false;
    }
    return sessionStorage.getItem("Error_401") === "true";
  };

  return (
    <SidebarProvider>
      <div className="layout-root">
        {!isHomeRoute && (
          <Sidebar
            selectMenu={selectMenu}
            themeMode={themeMode}
            handleLogout={handleOpenLogoutConfirmation}
            menuItems={customeruiMenus}
            location={location}
            navigate={navigate}
          />
        )}
        <div className="layout-content">
          {!isHomeRoute && (
            <Header
              themeMode={themeMode}
              currentTheme={currentTheme}
              selectedMenu={selectedMenu}
            />
          )}

          {/* <CustomPopup
          visible={renderFirstTimePopup}
          content={
            <PopupContent setRenderFirstTimePopup={setRenderFirstTimePopup} />
          }
          setVisible={handleClosePopup}
        /> */}

          <Content
            // // isAuthenticated={isAuthenticated}
            // setIsAuthenticated={setIsAuthenticated}
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticatedAndPersist}
          />
          {!isHomeRoute && (
            <Footer themeMode={themeMode} currentTheme={currentTheme} />
          )}
          <div style={{ flexDirection: "row", display: "flex" }}>
            <CustomPopup
              content={
                <div>
                  <button className="close-btn" onClick={() => handleClose()}>
                    x
                  </button>
                  <span>Your Session got expired. Please re-login again.</span>
                </div>
              }
              visible={isVisible()}
              setVisible={setError}
              logout={logout}
              setIsAuthenticated={setIsAuthenticated}
            />
          </div>
          <LogoutTimer
            logout={logout}
            setIsAuthenticated={setIsAuthenticated}
          />
        </div>

        <CustomDialog
          open={isLogoutConfirmationOpen}
          message={`Are you sure you want to logout?`}
          onConfirm={handleLogout}
          onCancel={handleCloseLogoutConfirmation}
          themeMode={themeMode}
          currentTheme={currentTheme}
        />
      </div>
    </SidebarProvider>
  );
};

export default Layout;
