import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./Content.css";
import Home from "../home/Home";
import CallAutomation from "../callautomation/CallAutomation";
import CallHistory from "../callhistory/CallHistory";
import Analytics from "../analytics/Analytics";
import Settings from "../settings/Settings";
import Login from "../login/Login";
import ProtectedRoute from "../../utils/ProtectedRoute";
import IntakeForm from "../intakeForm/IntakeForm";
import Page2 from "../intakeForm/page2/page2";
import Page0 from "../intakeForm/page0/page0";
import SamlSuccess from "../saml/SamlSuccess";
import AdvancedAnalytics from "../advancedAnalytics/AdvancedAnalytics";

const Content = ({ isAuthenticated, setIsAuthenticated }) => {
  // const [isAuthenticated, setIsAuthenticated] = useState(
  //   localStorage.getItem("isAuthenticated") === "true"
  // );

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(localStorage.getItem("isAuthenticated") === "true");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className="content-root">
      <Routes>
        <Route
          path="/"
          element={<Login setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute
              element={Home}
              isAuthenticated={isAuthenticated}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/call-automation"
          element={
            <ProtectedRoute
              element={CallAutomation}
              isAuthenticated={isAuthenticated}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/call-history/:batchId?"
          element={
            <ProtectedRoute
              element={CallHistory}
              isAuthenticated={isAuthenticated}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/analytics"
          element={
            <ProtectedRoute
              element={Analytics}
              isAuthenticated={isAuthenticated}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/advancedAnalytics"
          element={
            <ProtectedRoute
              element={AdvancedAnalytics}
              isAuthenticated={isAuthenticated}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute
              element={Settings}
              isAuthenticated={isAuthenticated}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/intake"
          element={
            <ProtectedRoute
              element={IntakeForm}
              isAuthenticated={isAuthenticated}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/page2"
          element={
            <ProtectedRoute
              element={Page2}
              isAuthenticated={isAuthenticated}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/page0"
          element={
            <ProtectedRoute
              element={Page0}
              isAuthenticated={isAuthenticated}
              redirectTo="/"
            />
          }
        />
        <Route
          path="/success"
          element={<SamlSuccess setIsAuthenticated={setIsAuthenticated} />}
        />
      </Routes>
    </div>
  );
};

export default Content;
