"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
require("./Footer.css");
var Footer = function (_a) {
    var currentTheme = _a.currentTheme, themeMode = _a.themeMode;
    var _b = (0, react_1.useState)(""), greeting = _b[0], setGreeting = _b[1];
    (0, react_1.useEffect)(function () {
        var currentTime = new Date().getHours();
        if (currentTime >= 5 && currentTime < 12) {
            setGreeting("Good Morning");
        }
        else if (currentTime >= 12 && currentTime < 17) {
            setGreeting("Good Afternoon");
        }
        else if (currentTime >= 17 && currentTime < 20) {
            setGreeting("Good Evening");
        }
        else {
            setGreeting("Good Night");
        }
        var timer = setTimeout(function () {
            setGreeting("");
        }, 5000);
        return function () { return clearTimeout(timer); };
    }, []);
    var hideGreeting = function () {
        setGreeting("");
    };
    return (react_1.default.createElement("div", { className: "footer-root", role: "contentinfo", style: {
            background: currentTheme.homePage.mainBackground,
            color: currentTheme.footer.textColor,
            marginLeft: "0px",
        } },
        react_1.default.createElement("span", { style: { fontSize: "1.5vh", padding: "1vh" } },
            "\u00A9 ",
            new Date().getFullYear(),
            " Voicecare | All rights reserved"),
        greeting && (react_1.default.createElement("span", { className: "center-text" },
            react_1.default.createElement("span", { className: "welcome-msg", onClick: hideGreeting, style: { display: "flex", height: "2vh", alignContent: "center", justifyContent: "center" } },
                react_1.default.createElement("img", { style: { width: "2vh", marginRight: "2vh" }, src: "/icons/user-white.png", alt: "Profile Icon" }),
                greeting)))));
};
exports.default = Footer;
