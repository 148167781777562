import React, { useState, useEffect, useRef } from "react";
import CustomPopup from "../../utils/CustomPopup";
import { useLocation } from "react-router-dom";


const COUNT_DOWN_TIME = 120;
const CONFIRMATION_TIMEOUT = COUNT_DOWN_TIME * 1000;
const INACTIVITY_TIMEOUT = 300000;
// const INACTIVITY_TIMEOUT = 10000; // for test 10 seconds

const LogoutTimer = ({ logout, setIsAuthenticated }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [countdown, setCountdown] = useState(COUNT_DOWN_TIME);
  const location = useLocation();
  
  const timerIdRef = useRef(null);
  const confirmationTimerIdRef = useRef(null);
  const countdownIntervalRef = useRef(null);

  const handleLogout = () => {
    setIsAuthenticated(false);
    logout();
  };

  const handleActivity = () => {
    resetTimer();
    if (showConfirmation) {
      clearTimeout(confirmationTimerIdRef.current);
      clearInterval(countdownIntervalRef.current);
      setShowConfirmation(false);
      setCountdown(COUNT_DOWN_TIME);
    }
  };

  const showConfirmationPopup = () => {
    if (location.pathname === "/") return;
  
    setShowConfirmation(true);
    setCountdown(COUNT_DOWN_TIME);
  
    clearTimeout(confirmationTimerIdRef.current);
    clearInterval(countdownIntervalRef.current);
  
    confirmationTimerIdRef.current = setTimeout(() => {
      handleLogout();
      setShowConfirmation(false);
    }, CONFIRMATION_TIMEOUT);
  
    countdownIntervalRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownIntervalRef.current);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const resetTimer = () => {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }
    if (confirmationTimerIdRef.current) {
      clearTimeout(confirmationTimerIdRef.current);
    }
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
    setShowConfirmation(false);
    timerIdRef.current = setTimeout(showConfirmationPopup, INACTIVITY_TIMEOUT);
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    resetTimer();

    return () => {
      clearTimeout(timerIdRef.current);
      clearTimeout(confirmationTimerIdRef.current);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  }, [logout, setIsAuthenticated, location.pathname]);

  const handleStayLoggedIn = () => {
    setShowConfirmation(false);
    clearTimeout(confirmationTimerIdRef.current);
    resetTimer();
  };

  return showConfirmation ? (
    <CustomPopup
      content={
        <div>
          <span>
            {`Are you still using the application? If yes, move your mouse or
            press any key, else you will be logged out in ${countdown} seconds.`}
          </span>
          <div>
            {/* <Button
              label={"Stay Logged In"}
              onClick={handleStayLoggedIn}
              backgroundColor={"#ff4e3a"}
              width={"25vh"}
              style={{ borderRadius: "1vh", margin: "1vh" }}
              height={"4vh"}
            />
            <Button
              label={"Logout"}
              onClick={handleLogoutConfirmed}
              backgroundColor={"#ff4e3a"}
              width={"25vh"}
              style={{ borderRadius: "1vh", margin: "1vh" }}
              height={"4vh"}
            /> */}
          </div>
        </div>
      }
      visible={true}
      setVisible={() => setShowConfirmation(false)}
    />
  ) : null;
};

export default LogoutTimer;
