// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-root {
    color: white;
    flex-direction: row;
    height: 87.5vh;
    width: auto;
    /* padding-left: 2vw; */
}`, "",{"version":3,"sources":["webpack://./src/components/content/Content.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,cAAc;IACd,WAAW;IACX,uBAAuB;AAC3B","sourcesContent":[".content-root {\r\n    color: white;\r\n    flex-direction: row;\r\n    height: 87.5vh;\r\n    width: auto;\r\n    /* padding-left: 2vw; */\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
