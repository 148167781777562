import { useNavigate } from "react-router-dom";
import { insertUserActivity } from "./CustomerUiAPI";

export const useLogout = () => {
  //const navigate = useNavigate();
  const logout = async () => {
    await insertUserActivity(
      sessionStorage.getItem("mail"),
      "logoutTime",
      "False",
      sessionStorage.getItem("sessionId")
    )
      .then((res) => {
        setTimeout(() => {
          localStorage.removeItem("isAuthenticated");
          sessionStorage.setItem("samlSuccess", false);
          sessionStorage.setItem("userStatus", 'notWarned');
          window.location.href = "/";
        }, [2000]);
      })
      .catch((e) => console.log("error", e));
  };
  return logout;
};

export const useHome = () => {
  const home = () => {
    window.location.href = "/home";
  };
  return home;
};
