import React, { useState, useEffect, useRef } from "react";
import "./DataTableMini.css";
import { useTheme } from "../../../../ThemeContext";
import { Scrollbar } from "smooth-scrollbar-react";

const DataTableMini = ({
  isEditable,
  headerColor,
  dataColor,
  columns,
  data,
  itemsPerPageOptions,
  defaultItemsPerPage,
  maxHeight,
  headerTextColor,
  currentRow,
  setCurrentRow,
  width,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const [contentHeight, setContentHeight] = useState("auto"); // Initialize as "auto".
  const { themeMode } = useTheme();

  const scrollbarRef = useRef(null);
  const [shouldApplyPadding, setShouldApplyPadding] = useState(false);

  // Update the content height when the data or itemsPerPage changes.
  useEffect(() => {
    // Calculate the content height based on data length and items per page.
    const rowsPerPage = Math.min(data.length, itemsPerPage);
    const calculatedHeight =
      rowsPerPage * /* Height of a single row in pixels */ 50;

    // Set the content height based on whether it exceeds maxHeight.
    setContentHeight(calculatedHeight > maxHeight ? maxHeight : "auto");
  }, [data, itemsPerPage, maxHeight]);

  useEffect(() => {
    const scrollbarElement = scrollbarRef.current;
    if (scrollbarElement) {
      const hasOverflow =
        scrollbarElement.scrollHeight > scrollbarElement.clientHeight;
      setShouldApplyPadding(hasOverflow);
    }
  }, [scrollbarRef]);

  const handleAction = (actionType) => {
    // Handle the action here (e.g., perform a download)
    if (actionType === "Download") {
      // Add your download logic here
      console.log("Download button clicked");
    }
    // Add more conditions for other action types
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const canGoPrevious = currentPage > 1;
  const canGoNext = currentPage < totalPages;

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (
      let i = Math.max(1, currentPage - 1);
      i <= Math.min(currentPage + 1, totalPages);
      i++
    ) {
      pageNumbers.push(i);
    }

    return pageNumbers.map((number) => (
      <button
        key={number}
        onClick={() => paginate(number)}
        className={number === currentPage ? "current-page" : ""}
        style={{
          fontSize: "small",
          fontWeight: "600",
          color:
            themeMode === "light"
              ? "black"
              : number === currentPage
              ? "black"
              : "white",
        }}
      >
        {number}
      </button>
    ));
  };

  const handleCellChange = (e, rowIndex, columnName) => {
    const newData = [...data]; // Make a copy of the data array
    newData[rowIndex][columnName] = e.target.innerText; // Update the cell's value
    // Update your component's state or pass the change up to the parent component
    // This example assumes you have a way to update your data state.
    console.log("Updated data:", newData);
    // setData(newData); // Uncomment if you manage data at this component level
  };

  const getCurrentRowIndex = (rowIndex) => {
    if (currentPage === 1) {
      return rowIndex;
    } else {
      return rowIndex + currentPage * itemsPerPage;
    }
  };

  const handleRowClick = (rowIndex, row) => {
    setCurrentRow && setCurrentRow(getCurrentRowIndex(rowIndex));
  };

  return (
    data.length > 0 ? (<div>
      <table
        style={{
          overflowX: "auto",
          overflowY: "auto",
          borderBottomLeftRadius: "2vh",
        }}
      >
        <Scrollbar
          ref={scrollbarRef} // Attach the ref to the Scrollbar component
          key={`page-${currentPage}-size-${itemsPerPage}`} // Force remount when these change
          className={
            themeMode === "light" ? "light-scrollbar" : "dark-scrollbar"
          }
          damping={0.1} // Adjust the damping (scrolling momentum) as needed
          thumbMinSize={20} // Minimum size for the scrollbar thumb
          alwaysShowTracks={true} // Set to true to always show the scrollbar tracks
          continuousScrolling={true} // Enable or disable continuous scrolling
          style={{
            maxHeight: "50vh",
            borderRadius: "1rem",
            paddingRight: shouldApplyPadding ? ".5rem" : 0, // Apply paddingRight conditionally
          }}
        >
          <thead
            style={{
              background: headerColor,
              color: headerTextColor,
              position: "sticky",
            }}
          >
            <tr>
              {columns.map((column, index) => (
                <th key={index} style={{ textAlign: "center" }}>
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className={`${themeMode}-data-table-body`}>
            {currentItems.map((row, rowIndex) => (
              <tr
                key={`table-row-${rowIndex}`}
                style={{
                  background:
                    currentRow === getCurrentRowIndex(rowIndex)
                      ? "#f54f3b"
                      : dataColor,
                  borderBottom:
                    themeMode === "dark"
                      ? `0.5px solid #3d3b59`
                      : `0.5px solid #e0e0e5`,
                }}
              >
                {columns.map((column, colIndex) => (
                  <td
                    key={`table-column-${rowIndex}-${colIndex}`}
                    contentEditable={isEditable} // Makes the cell editable
                    onBlur={(e) => handleCellChange(e, rowIndex, column.name)} // Update the data on blur
                    onClick={() => handleRowClick(rowIndex, row)}
                    style={{
                      textAlign: "center",
                      width: column.width || "20vw",
                      padding: ".5rem",
                    }}
                    suppressContentEditableWarning={true} // To suppress the warning for controlled components
                  >
                    <div className="cell-content">
                      {column.name === "Action"
                        ? column.render(row)
                        : column.selector
                        ? column.selector(row)
                        : row[column.name]}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Scrollbar>
      </table>
      <div
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "inherit",
          position: "sticky",
          background: themeMode !== "light" ? headerColor : "#d6d5d8",
          color: themeMode === "light" ? "black" : "white",
          fontSize: "small"
        }}
      >
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <span style={{ display: "flex" }}>
          <span style={{ fontSize: "14px" }}>
            Rows:
            <select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{
                backgroundColor: "white",
                color: "black",
                border: "none",
                outline: "none",
                fontSize: "small"
              }}
            >
              {itemsPerPageOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </span>
          {canGoPrevious && (
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={!canGoPrevious}
              style={{
                display: "flex",
                color: themeMode === "light" ? "black" : "#b2b1bb !important",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {
                <img
                  width={"16px"}
                  height={"14px"}
                  src={
                    themeMode === "dark"
                      ? "/icons/dark-theme/Arrow-Left.png"
                      : "/icons/light-theme/Arrow-Left.png"
                  }
                />
              }
              Previous
            </button>
          )}

          {renderPageNumbers()}

          {canGoNext && (
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={!canGoNext}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: themeMode === "light" ? "black" : "#ffffff !important",
              }}
            >
              Next
              {
                <img
                  width={"16px"}
                  height={"14px"}
                  src={
                    themeMode === "dark"
                      ? "/icons/dark-theme/Arrow-Right.png"
                      : "/icons/light-theme/Arrow-Right.png"
                  }
                />
              }
            </button>
          )}
        </span>
      </div>
    </div>) : "No Records Found"
  );
};

export default DataTableMini;
