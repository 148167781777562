(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("@voicecare.ai/ui-library", ["React", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["@voicecare.ai/ui-library"] = factory(require("react"), require("react-dom"));
	else
		root["@voicecare.ai/ui-library"] = factory(root["React"], root["ReactDOM"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__9318__) => {
return 