/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from "react";
import { Space } from "antd";
import { AutoCompleteInput, UserCard } from "./../components";
import { useCallHistoryProvider } from "../context/CallHIstoryContext";
import { useTheme } from "../../../ThemeContext";
import { Scrollbar } from "smooth-scrollbar-react";
import { SearchInput } from "./SearchInput";

export const UserList = ({
  setSelectedUser,
  selectedUser,
  batchId,
  setRenderType,
  prevPageState,
  setPrevPageState,
  currentPage,
  setCurrentPage,
  totalRows,
  setTotalRows,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [sortValue, setSortValue] = useState("time");
  const { themeMode, currentTheme } = useTheme();
  const {
    getUniquePatientData,
    callerList,
    fetchPatientCallData,
    callList,
    selectedCall,
    handleCallSelect,
    setSelectedMember,
    selectedMember,
    filterBy,
    filterType,
  } = useCallHistoryProvider();

  const containerRef = useRef(null);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredItems = callList.filter((item) =>
    item.member_name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  // const canGoPrevious = currentPage > 1;
  // const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  // const canGoNext = currentPage < totalPages;

  const totalPages = Math.ceil(totalRows / itemsPerPage);
  const canGoPrevious = currentPage > 1;
  const canGoNext = currentPage < totalPages;

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (
      let i = Math.max(1, currentPage - 1);
      i <= Math.min(currentPage + 1, totalPages);
      i++
    ) {
      pageNumbers.push(i);
    }

    return pageNumbers.map((number) => (
      <button
        key={number}
        onClick={() => paginate(number)}
        className={number === currentPage ? "current-page" : ""}
        style={{
          color:
            themeMode === "light"
              ? "black"
              : number === currentPage
                ? "black"
                : "white",
        }}
      >
        {number}
      </button>
    ));
  };

  // const [scrollPosition, setScrollPosition] = useState(0);
  const scrollPosition = sessionStorage.getItem('scrollPosition')


  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPosition;
    }
    if (prevPageState.includes(currentPage)) {
      return;
    } else {
      setPrevPageState((prev) => [...prev, currentPage]);
      fetchMoreData(currentPage);
    }
  }, [itemsPerPage, currentPage]); // Fetch initial data when component mounts

  const fetchMoreData = async (page) => {
    if (prevPageState.includes(page)) return;
    console.log("fetching more data, paginationDebugger", filterBy, filterType);
    setLoading(true);
    await fetchPatientCallData(
      batchId ?? "",
      setLoading,
      page.toString(),
      "25",
      // itemsPerPage.toString(),
      setTotalRows,
      selectedMember ?? "",
      "",
      filterBy === "name" ? "member_name" : "call_start_time",
      filterType
    )
      .then((newData) => {
        if (newData && newData.length > 0) {
          // Update total rows and current page
          if (page === 1) {
            setTotalRows(newData?.total_count);
          }
          setCurrentPage(page);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const renderUserCardList = () => {
    return (
      <Scrollbar
        ref={containerRef}
        className={themeMode === "light" ? "light-scrollbar" : "dark-scrollbar"}
        damping={0.1}
        thumbMinSize={30}
        alwaysShowTracks={true}
        continuousScrolling={true}
        style={{ height: "61vh", overflowY: "auto" }}
      >
        {totalRows !== 0 &&
          currentItems.map((item, i) => {
            return (
              <UserCard
                key={item.call_id}
                i={i}
                callContent={item}
                username={item.member_name}
                datetime={item.call_start_time}
                onClick={() => {
                  sessionStorage.setItem('scrollPosition',containerRef.current.scrollTop)
                  handleCallSelect(item.call_id);
                  setSelectedMember(item.member_id);
                }}
                selectedUser={selectedCall}
                isActive={selectedCall === item.call_id}
                handlerType={item.caller_handler_type}
              />
            );
          })}

        {totalRows === 0 && <div style={{ display: 'flex', justifyContent: 'center', color: themeMode === 'dark' ? 'white' : 'black' }} >
          No Data</div>}
      </Scrollbar>
    );
  };

  return (
    <>
      {loading ? (
        <img
          className="loader"
          src={
            themeMode === "dark"
              ? "/icons/loader_white.gif"
              : "/icons/loader_black.gif"
          }
        />
      ) : (
        <Space
          direction="vertical"
          size="middle"
          className="call-history__user-list"
        >
          {/* <SearchInput
            placeholder="Search by name"
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          /> */}
          <AutoCompleteInput
            setTotalRows={setTotalRows}
            setLoading={setLoading}
            setRenderType={setRenderType}
            setCurrentPage={setCurrentPage}
            setPrevPageState={setPrevPageState}
            setSelectedMember={setSelectedMember}
          />
          {renderUserCardList()}
          <div
            className="pagination"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "inherit",
              height: "1rem",
              fontSize: "14px",
              position: "sticky",
              background:
                themeMode !== "light"
                  ? currentTheme.homePage.tableHeader
                  : "#d6d5d8",
              color: themeMode === "light" ? "black" : "white",
            }}
          >
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <span style={{ display: "flex" }}>
              {canGoPrevious && (
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={!canGoPrevious}
                  style={{
                    display: "flex",
                    color:
                      themeMode === "light" ? "black" : "#b2b1bb !important",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    width={"16px"}
                    height={"14px"}
                    src={
                      themeMode === "dark"
                        ? "/icons/dark-theme/Arrow-Left.png"
                        : "/icons/light-theme/Arrow-Left.png"
                    }
                  />
                  Previous
                </button>
              )}

              {renderPageNumbers()}

              {canGoNext && (
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={!canGoNext}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color:
                      themeMode === "light" ? "black" : "#ffffff !important",
                  }}
                >
                  Next
                  <img
                    width={"16px"}
                    height={"14px"}
                    src={
                      themeMode === "dark"
                        ? "/icons/dark-theme/Arrow-Right.png"
                        : "/icons/light-theme/Arrow-Right.png"
                    }
                  />
                </button>
              )}
            </span>
          </div>
        </Space>
      )}
    </>
  );
};
