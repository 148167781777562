"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
require("./TextBox.css");
var TextBox = function (_a) {
    var onChange = _a.onChange, value = _a.value, iconUrl = _a.iconUrl, _b = _a.style, style = _b === void 0 ? {} : _b, _c = _a.textBoxBackground, textBoxBackground = _c === void 0 ? "#fff" : _c, _d = _a.textBoxText, textBoxText = _d === void 0 ? "#000" : _d, _e = _a.type, type = _e === void 0 ? "text" : _e, _f = _a.placeholder, placeholder = _f === void 0 ? "Search" : _f, onBlur = _a.onBlur, _g = _a.themeMode, themeMode = _g === void 0 ? "dark" : _g;
    var _h = (0, react_1.useState)(false), isRevealPwd = _h[0], setIsRevealPwd = _h[1];
    var handleType = function () {
        return type === "text" ? "text" : isRevealPwd ? "text" : "password";
    };
    var mergedStyles = __assign(__assign({}, style), { background: textBoxBackground });
    var getShowUrl = function () {
        return themeMode === "dark" ? "/icons/show-white.png" : "/icons/show.png";
    };
    var getHideUrl = function () {
        return themeMode === "dark" ? "/icons/hide-white.png" : "/icons/hide.png";
    };
    return (react_1.default.createElement("div", { className: "search-container", style: mergedStyles },
        iconUrl && react_1.default.createElement("img", { className: "search-icon", src: iconUrl, alt: "icon" }),
        react_1.default.createElement("input", { type: handleType(), className: "search-input", placeholder: placeholder, style: {
                background: textBoxBackground,
                color: textBoxText,
                WebkitTextFillColor: textBoxText,
                WebkitBoxShadow: "0 0 0 1000px ".concat(textBoxBackground, " inset"),
            }, value: value, onChange: onChange, autoComplete: "off", onBlur: onBlur }),
        type === "password" && (react_1.default.createElement("img", { className: "search-icon", onClick: function () { return setIsRevealPwd(!isRevealPwd); }, src: isRevealPwd ? getHideUrl() : getShowUrl(), alt: "toggle visibility" }))));
};
exports.default = TextBox;
