import React, { useEffect } from "react";
import "./Analytics.css";
import { useTheme } from "../../ThemeContext";

const Analytics = () => {
  const { currentTheme, themeMode } = useTheme();

  // const streamLitLightSRC = process.env.REACT_APP_STREAMLIT_LIGHT_APP;
  const streamLitLightSRC = "https://streamlit-appv1-light-api-uat-lkxlp3ykoq-uc.a.run.app";

  // const streamLitDarkSrc = process.env.REACT_APP_STREAMLIT_DARK_APP;
  const streamLitDarkSrc = "https://streamlit-appv1-dark-api-uat-lkxlp3ykoq-uc.a.run.app/";

  const streamLitSRC = themeMode === 'light' ? streamLitLightSRC : streamLitDarkSrc

  return (
    <div
      className="analitics-root"
      style={{
        background: currentTheme.homePage.mainBackground,
        color: currentTheme.homePage.textColor,
        padding: "2vh",
        height: "100%",
      }}
    >
      <iframe
        src={streamLitSRC}
        style={{
          width: "99%",
          height: "100%",
          border: "none",
        }}
        title="Analytics Dashboard"
      />
    </div>
  );
};

export default Analytics;
