/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-lone-blocks */
import { Flex, Space, Typography } from "antd";
import React, { useState } from "react";
import { Colors } from "../helpers";
import { useCallHistoryProvider } from "../context/CallHIstoryContext";
import { useTheme } from "../../../ThemeContext";
import Button from "../../common/button/Button";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { Scrollbar } from "smooth-scrollbar-react";
import { getPatientsCallDataPDF } from "../../../utils/CustomerUiAPI";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, IconButton, Tooltip } from "@mui/material";

const { Text } = Typography;

export const CallSummary = ({ viewMore, setViewMore }) => {
  const { currentTheme } = useTheme();
  const { callContent, getSelectedMemberInfo } = useCallHistoryProvider();
  const { copay_verified, white_sheet } = callContent;
  const { member_name, member_id } = getSelectedMemberInfo() || {};

  // const white_sheet = {
  //   "IVR Given Benefits Information": [
  //     { "Individual Deductible": "$550" },
  //     { "Remaining Individual Deductible": "$550" },
  //     { "Individual Deductible Coinsurance": "not included" },
  //     { "Family Deductible": "$1,100" },
  //     { "Remaining Family Deductible": "$1,100" },
  //     { "Family Deductible Coinsurance": "not included" },
  //     { "Individual Coinsurance Limit": "$2,500" },
  //     { "Remaining Individual Coinsurance Limit": "$2,500" },
  //     { "Family Coinsurance Limit": "$5,000" },
  //     { "Remaining Family Coinsurance Limit": "$5,000" },
  //     { "Individual Annual Maximum Copay Limit": "$3,500" },
  //     { "Remaining Individual Annual Maximum Copay Limit": "$3,500" },
  //     { "Family Annual Maximum Copay Limit": "$7,000" },
  //     { "Remaining Family Annual Maximum Copay Limit": "$6,950" },
  //     { "Lifetime Maximum": "unlimited" },
  //     { "Effective Date": "January 1, 2023" },
  //   ],
  //   "Benefits Informations": [
  //     {
  //       service:
  //         "COLONOSCOPY, FLEXIBLE; WITH REMOVAL OF TUMOR(S), POLYP(S), OR OTHER LESION(S) BY SNARE TECHNIQUE",
  //       code: { cpt: "45385" },
  //       information: [
  //         { "Individual Copay": "$20" },
  //         { "Individual Coinsurance": "80%" },
  //         { "Deductible Waived": "no" },
  //         { "Individual Deductible": "$92" },
  //         { "Individual Out Of Pocket": "$2257" },
  //         { "Remaining Individual Out Of Pocket": "$1679" },
  //         { "Family Out Of Pocket": "$2015" },
  //         { "Remaining Family Out Of Pocket": "$1350" },
  //         { "Referral Required": "Required" },
  //         { "Preauthorization Predetermination": "Required" },
  //         { "Preauthorization Predetermination Active": "No" },
  //       ],
  //     },
  //     {
  //       service: "Monitored Anesthesia",
  //       code: { cpt: "00812" },
  //       information: [
  //         { "Individual Copay": "$35" },
  //         { "Individual Coinsurance": "90%" },
  //         { "Deductible Waived": "no" },
  //         { "Individual Deductible": "$102" },
  //         { "Individual Out Of Pocket": "$2167" },
  //         { "Remaining Individual Out Of Pocket": "$1786" },
  //         { "Family Out Of Pocket": "$2199" },
  //         { "Remaining Family Out Of Pocket": "$1438" },
  //         { "Referral Required": "Not required" },
  //         { "Preauthorization Predetermination": "Required" },
  //         { "Preauthorization Predetermination Active": "No" },
  //       ],
  //     },
  //   ],
  //   "Reference Number": "1116217",
  //   "Effective Date": "January 1, 2023",
  //   "Termination Date": "January 17, 2023",
  // };

  const containsPriorApproval = (text) => {
    const priorApprovalTerms = [
      "pre approval",
      "prior approval",
      "pre authorization",
      "prior authorization",
      "pre authorisation",
      "prior authorisation",
      "pre cert",
      "pre certification",
      "pre certified",
      "pre certify",
      "pre-approval",
      "pre-cert",
      "pre-certification",
      "Preauthorization",
      "Predetermination",
      "preauthorization",
      "predetermination",
    ];

    const lowerCaseText = text.toLowerCase();
    // Check if ANY term exists in the text using some
    const containsTerm = priorApprovalTerms.some((term) =>
      lowerCaseText.includes(term)
    );
    return containsTerm;
  };

  const renderIndividualService = (info) => {
    if (info && info.information) {
      return (
        <Flex vertical gap={4}>
          <span style={{ fontWeight: "700" }}>{info.service}</span>
          {info.information.map((item, index) => {
            const key = Object.keys(item)[0];
            const isBold = containsPriorApproval(key);
            return (
              <Flex
                key={index}
                justify="space-between"
                style={{
                  paddingLeft: "2vh",
                  fontWeight: isBold ? "bold" : "normal",
                }}
              >
                <Text
                  style={{
                    color: isBold
                      ? "#ff4e3a"
                      : themeMode === "dark"
                      ? "white"
                      : "black",
                    fontSize: 13,
                    width: "70%",
                  }}
                >
                  {key}
                </Text>
                <Text
                  style={{
                    color: isBold
                      ? "#ff4e3a"
                      : themeMode === "dark"
                      ? "white"
                      : "black",
                    fontSize: 13,
                    width: "30%",
                    marginLeft: "auto",
                  }}
                >
                  {item[key]}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      );
    }
    return null;
  };

  const renderIndividualIVRService = (info) => {
    if (info) {
      return (
        <Flex vertical gap={4} style={{ maxHeight: "46vh" }}>
          <Flex
            justify="space-between"
            style={{
              paddingLeft: "2vh",
            }}
          >
            <Text
              style={{
                color: themeMode === "dark" ? "white" : "black",
                fontSize: 13,
                width: "70%",
              }}
            >
              {Object.keys(info)[0]}
            </Text>
            <Text
              style={{
                color: themeMode === "dark" ? "white" : "black",
                fontSize: 13,
                width: "30%",
                marginLeft: "auto",
              }}
            >
              {Object.values(info)[0]}
            </Text>
          </Flex>
        </Flex>
      );
    }
    return null;
  };
  const toCamelCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space between camel case words
      .replace(/([A-Z]+)([A-Z][a-z])/g, "$1 $2") // Add a space between consecutive capital letters and a capital followed by lowercase
      .replace(/([a-z])([0-9])/g, "$1 $2") // Add a space between letters and digits
      .replace(/([0-9])([a-zA-Z])/g, "$1 $2") // Add a space between digits and letters
      .replace(/\s+/g, " ") // Replace multiple spaces with a single space
      .trim() // Remove leading and trailing spaces
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()); // Capitalize first letter of each word
  };

  const convertValuesToCamelCase = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => convertValuesToCamelCase(item));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key] =
          typeof value === "string"
            ? toCamelCase(value)
            : convertValuesToCamelCase(value);
        return acc;
      }, {});
    }
    return obj;
  };

  const handleDownload = () => {
    let workbook = XLSX.utils.book_new();

    // Iterate through IVR Given Benefits Information if it exists
    if (white_sheet["IVR Given Benefits Information"]) {
      let jsonRow = {};
      const name = `CPT-IVR Given Benefits Information`;
      for (
        let i = 0;
        i < white_sheet["IVR Given Benefits Information"].length;
        i++
      ) {
        jsonRow = {
          ...jsonRow,
          ...convertValuesToCamelCase(
            white_sheet["IVR Given Benefits Information"][i]
          ),
        };
      }
      let worksheet = XLSX.utils.json_to_sheet([jsonRow]);
      const truncatedName = truncateSheetName(name);
      XLSX.utils.book_append_sheet(workbook, worksheet, truncatedName);
    }

    // Iterate through Benefits Informations
    for (let i = 0; i < white_sheet["Benefits Informations"].length; i++) {
      let jsonRow = {};
      for (
        let j = 0;
        j < white_sheet["Benefits Informations"][i].information.length;
        j++
      ) {
        jsonRow = {
          ...jsonRow,
          ...convertValuesToCamelCase(
            white_sheet["Benefits Informations"][i].information[j]
          ),
        };
      }

      const maxLength =
        31 - `CPT-${white_sheet["Benefits Informations"][i].code.cpt}-`.length;
      const truncatedService = white_sheet["Benefits Informations"][
        i
      ].service.substring(0, maxLength);
      const name = `CPT-${white_sheet["Benefits Informations"][i].code.cpt}-${truncatedService}`;
      let worksheet = XLSX.utils.json_to_sheet([jsonRow]);
      const truncatedName = truncateSheetName(name);
      XLSX.utils.book_append_sheet(workbook, worksheet, truncatedName);
    }

    // Other Information
    let otherInfoData = {
      "Effective Date":
        white_sheet["Effective Date"] !== null
          ? white_sheet["Effective Date"]
          : "N/A",
      "Termination Date":
        white_sheet["Termination Date"] !== null
          ? white_sheet["Termination Date"]
          : "N/A",
      "Reference Number":
        white_sheet["Reference Number"] !== null
          ? white_sheet["Reference Number"]
          : "N/A",
    };

    let otherInfoSheet = XLSX.utils.json_to_sheet([otherInfoData]);
    XLSX.utils.book_append_sheet(workbook, otherInfoSheet, "Other Information");

    XLSX.writeFile(workbook, `CallSummary-${member_id}-${member_name}.xlsx`, {
      compression: true,
    });
  };

  const truncateSheetName = (name) => {
    return name.length > 31 ? name.substring(0, 31) : name;
  };

  const handleDownloadPDF = async () => {
    try {
      const pdfUrlResponse = await getPatientsCallDataPDF(
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId"),
        callContent.pdf_path
      );

      const pdfUrl = pdfUrlResponse.response; // Access the signed URL from the 'response' field
      const memberId = member_id; // Replace with actual member_id
      const memberName = member_name; // Replace with actual member_name

      const filename = `CallSummary-${memberId}-${memberName}.pdf`;

      // Fetch the PDF file
      const response = await fetch(pdfUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch PDF: ${response.statusText}`);
      }

      // Convert the response to a Blob
      const blob = await response.blob();

      // Create a Blob URL
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;

      // Append the anchor element to the document body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the anchor element from the document body
      document.body.removeChild(link);

      // Revoke the Blob URL
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  // // Assuming 'data' is the Excel data you provided earlier
  // const handleDownloadPDF = () => {
  //   // let workbook = XLSX.utils.book_new();
  //   // for (let i = 0; i < white_sheet.length; i++) {
  //   //   let jsonRow = {};
  //   //   for (let j = 0; j < white_sheet[i].information.length; j++) {
  //   //     jsonRow = { ...jsonRow, ...white_sheet[i].information[j] };
  //   //   }
  //   //   let worksheet = XLSX.utils.json_to_sheet([jsonRow]);
  //   //   XLSX.utils.book_append_sheet(workbook, worksheet, data[i].service);
  //   // }

  //   // // Convert the Excel data to an array of objects
  //   // const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

  //   // // Generate PDF using the converted data
  //   // generatePDF(sheetData);

  //   const pdfUrl = callContent.pdf_path;
  //   // Open a new tab and load the PDF URL
  //   window.open(pdfUrl, "_blank");
  // };

  const { themeMode } = useTheme();

  const getContent = () => {
    if (white_sheet) {
      const content = [];
      let refernceNumber = null;
      let effectiveDate = null;
      let terminationDate = null;

      if (white_sheet !== "None") {
        if (white_sheet["Reference Number"] !== null) {
          refernceNumber = Object.values(white_sheet["Reference Number"]);
        } else {
          refernceNumber = "No reference number";
        }

        if (white_sheet["Effective Date"] !== null) {
          effectiveDate = Object.values(white_sheet["Effective Date"]);
        } else {
          effectiveDate = "No effective date";
        }

        if (white_sheet["Termination Date"] !== null) {
          terminationDate = Object.values(white_sheet["Termination Date"]);
        } else {
          terminationDate = "No termination date";
        }
      }

      content.push(
        <div style={{ fontWeight: "bold", marginBottom: "1rem" }}>
          EFFECTIVE DATE: <span>{effectiveDate}</span>
        </div>
      );
      content.push(
        <div style={{ fontWeight: "bold", marginBottom: "1rem" }}>
          TERMIATION DATE: <span>{terminationDate}</span>
        </div>
      );

      {
        white_sheet &&
          white_sheet["Benefits Informations"] &&
          white_sheet["Benefits Informations"].map((info, index) => {
            content.push(
              <div key={index}>{renderIndividualService(info)}</div>
            );
          });
      }

      content.push(
        <div style={{ fontWeight: "bold" }}>
          IVR Given Benefits Informations
        </div>
      );
      {
        white_sheet &&
          white_sheet["IVR Given Benefits Information"] &&
          white_sheet["IVR Given Benefits Information"].map((info, index) => {
            content.push(
              <div key={index}>{renderIndividualIVRService(info)}</div>
            );
          });
      }

      content.push(
        <div style={{ fontWeight: "bold", marginTop: "1rem" }}>
          REFERENCE NUMBER: <span>{refernceNumber}</span>
        </div>
      );

      return <div>{content}</div>;
    }
  };

  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);

  const handleCopyToClipboard = () => {
    const effectiveDate = `EFFECTIVE DATE: ${
      white_sheet["Effective Date"] || "No effective date"
    }`;
    const terminationDate = `TERMINATION DATE: ${
      white_sheet["Termination Date"] || "No termination date"
    }`;
    const referenceNumber = `REFERENCE NUMBER: ${
      white_sheet["Reference Number"] || "No reference number"
    }`;

    let benefitsInformation = "";
    white_sheet["Benefits Informations"].forEach((info) => {
      benefitsInformation += `\n${info.service}\n`;
      info.information.forEach((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        benefitsInformation += `${key}: ${value}\n`;
      });
    });

    let ivrInformation = "IVR Given Benefits Information\n";
    white_sheet["IVR Given Benefits Information"].forEach((info) => {
      const key = Object.keys(info)[0];
      const value = info[key];
      ivrInformation += `${key}: ${value}\n`;
    });

    const textToCopy = `${effectiveDate}\n${terminationDate}\n${benefitsInformation}\n${ivrInformation}\n${referenceNumber}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // Show tooltip
        setCopyTooltipOpen(true);

        // Hide the tooltip after a short delay
        setTimeout(() => {
          setCopyTooltipOpen(false);
        }, 2000);
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  return (
    <Space
      direction="vertical"
      className="call-summary-copay-wrapper"
      style={{
        background: currentTheme.callhistory.searchBackground,
        color: themeMode === "dark" ? "white" : "black",
        height: viewMore ? "75vh" : "40vh",
      }}
    >
      <Scrollbar
        className={themeMode === "light" ? "light-scrollbar" : "dark-scrollbar"}
        damping={0.1} // Adjust the damping (scrolling momentum) as needed
        thumbMinSize={20} // Minimum size for the scrollbar thumb
        alwaysShowTracks={true} // Set to true to always show the scrollbar tracks
        continuousScrolling={true} // Enable or disable continuous scrolling
        style={{
          maxHeight: viewMore ? "65vh" : "40vh",
          height: viewMore ? "65vh" : "35vh",
          paddingRight: "1rem",
        }}
      >
        <Typography
          style={{
            color: themeMode === "dark" ? "white" : "black",
            fontSize: 19,
            marginTop: 14,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span style={{ display: "flex" }}>
            <span style={{ marginRight: "1rem", marginBottom: "1rem" }}>
              Call Summary
            </span>

            {copay_verified === "True" && (
              <span
                style={{
                  display: "flex",
                  textAlign: "right",
                  alignItems: "center",
                }}
              >
                <img
                  src="/icons/verified.svg"
                  width={"28rem"}
                  height={"28rem"}
                  title="Human verifed"
                />
                <span style={{ marginLeft: ".5rem", fontSize: "14px" }}>Human Verified</span>
              </span>
            )}
          </span>
          <span>
            {/* <input
              className={`${themeMode}-check-box`}
              type="checkbox"
              value={copay_verified}
              style={{
                margin: "5px",
                accentColor: "#ff4e3a",
                border:
                  themeMode === "dark" ? "1px solid white" : "1px solid black",
                borderRadius: "5px",
              }}
              checked={copay_verified === "True"}
              disabled={true}
            /> */}
          </span>
          <span
            style={{
              fontSize: "13px",
              textDecoration: "underline",
              cursor: "pointer",
              display: white_sheet === "None" ? "none" : "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <span style={{ paddingRight: "1vh" }}>
              <Tooltip
                title="Copied"
                open={copyTooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={() => setCopyTooltipOpen(false)}
              >
                <IconButton sx={{ p: 0 }} onClick={handleCopyToClipboard}>
                  <ContentCopyIcon
                    style={{
                      marginRight: "0.5rem",
                      color: themeMode === "dark" ? "white" : "black",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </span>
            <span style={{ paddingRight: "1vh" }}>
              <img
                src={
                  themeMode === "dark"
                    ? "/icons/dark-theme/pdf-download.png"
                    : "/icons/light-theme/pdf-download.png"
                }
                onClick={handleDownloadPDF}
                height={"30vh"}
              />
            </span>
            <span style={{ paddingRight: "1vh" }}>
              <img
                src={
                  themeMode === "dark"
                    ? "/icons/dark-theme/excel-download.png"
                    : "/icons/light-theme/excel-download.png"
                }
                onClick={handleDownload}
                height={"30vh"}
              />
            </span>
            {!viewMore ? "View More" : "View Less"}
          </span>
        </Typography>
        {/* {white_sheet &&
          white_sheet["Benefits Informations"] &&
          white_sheet["Benefits Informations"].map((info, index) => {
            return <div key={index}>{renderIndividualService(info)}</div>;
          })} */}
        {getContent()}
      </Scrollbar>
    </Space>
  );
};

export const MoreCallDetails = () => {
  const { currentTheme, themeMode } = useTheme();
  const [activeTab, setActiveTab] = useState("more-details");
  const { callContent } = useCallHistoryProvider();
  const { overall_call_metadata } = callContent || {};
  const { before_transfer, after_transfer } = overall_call_metadata || {};

  const keys = {
    id: "Conversation ID",
    call_end_time: "End Time Stamp",
    call_start_time: "Start Time Stamp",
    total_talk_time: "Total Duration",
    caller_name: "Caller Name",
  };
  const data = {
    "more-details": before_transfer || {},
    "transfer-details": after_transfer || {},
  };

  const renderKeyValues = ({ key, value }) => {
    const title = keys[key];
    return (
      <Flex justify="space-between" style={{ width: "100%" }}>
        <Text
          style={{
            color: themeMode === "dark" ? "white" : "black",
            fontSize: 13,
            width: "50%",
            paddingLeft: 20,
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            color: themeMode === "dark" ? "white" : "black",
            fontSize: 13,
            width: "20%",
          }}
        >
          :
        </Text>
        <Text
          style={{
            color: themeMode === "dark" ? "white" : "black",
            fontSize: 13,
            width: "30%",
            marginLeft: "auto",
          }}
        >
          {value}
        </Text>
      </Flex>
    );
  };
  return (
    <div style={{ display: "flex", gap: "2vh" }}>
      <Space
        className="call-summary-more-wrapper"
        direction="vertical"
        style={{
          background: currentTheme.callhistory.searchBackground,
          color: themeMode === "dark" ? "white" : "black",
          maxHeight: "30vh",
          height: "40vh",
        }}
      >
        <Flex className="call-summary-more-headers-wrapper">
          <div
            className={`more-details ${
              activeTab === "more-details" ? "active" : ""
            }`}
            onClick={() => setActiveTab("more-details")}
            style={{
              background: currentTheme.callhistory.orange,
            }}
          >
            <Text
              style={{
                color:
                  activeTab === "more-details"
                    ? "#ffffff"
                    : themeMode === "dark"
                    ? "white"
                    : "black",
                fontSize: 13,
              }}
            >
              More Call Details
            </Text>
          </div>
        </Flex>

        <Flex vertical gap={4}>
          {Object.entries(data["more-details"])?.map(([key, value]) => {
            return renderKeyValues({ key, value });
          })}
        </Flex>
      </Space>
      <Space
        className="call-summary-more-wrapper"
        direction="vertical"
        style={{
          background: currentTheme.callhistory.searchBackground,
          color: themeMode === "dark" ? "white" : "black",
          maxHeight: "30vh",
          height: "40vh",
        }}
      >
        <Flex className="call-summary-more-headers-wrapper">
          <div
            className={`transfer-details ${
              activeTab === "transfer-details" ? "active" : ""
            }`}
            onClick={() => setActiveTab("transfer-details")}
            style={{
              background: currentTheme.callhistory.orange,
            }}
          >
            <Text
              style={{
                color:
                  activeTab === "transfer-details"
                    ? "#ffffff"
                    : themeMode === "dark"
                    ? "white"
                    : "black",
                fontSize: 13,
              }}
            >
              Transfer Call Details
            </Text>
          </div>
        </Flex>
        <Flex vertical gap={4}>
          {Object.entries(data["transfer-details"])?.map(([key, value]) => {
            return renderKeyValues({ key, value });
          })}
        </Flex>
      </Space>
    </div>
  );
};
