import "../IntakeForm.css";
import { useTheme } from "../../../ThemeContext";
// import data from "../../../utils/dummyData/page0.json";
import { getQuestionsFromTask } from "../../../utils/CustomerUiAPI";
import { useEffect, useState } from "react";
import "./page0.css";
import DataTableMini from "../../common/table/DataTableMini/DataTableMini";
import Button from "../../common/button/Button";
import DataTable from "../../common/table/DataTable/DataTable";
import CustomMuiTable from "../../common/custom-mui-table/CustomDataGridBackendPagination/Index";
import { Table } from "@voicecare.ai/ui-library";

const Page0 = ({
  setIdClicked,
  setTaskId,
  setCreatedData,
  setIntakeClicked,
}) => {
  const { themeMode, currentTheme } = useTheme();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      getQuestionsFromTask(
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId")
      )
        .then((res) => {
          setLoading(false);
          const { response } = res;

          const sortedResponse = response.sort((a, b) => {
            const dateA = new Date(a.created_date);
            const dateB = new Date(b.created_date);
            return dateB - dateA;
          });

          const flatten = flatRowCreator(sortedResponse);

          setData(flatten);
        })
        .catch((res) => {
          setLoading(false);
          console.log(res);
        });
    }
    fetchData();
  }, []);

  const handleIdClick = (row) => {
    setIdClicked(true);
    setTaskId(row.id);
    console.log("lll", row);
    sessionStorage.setItem("currentIntakeStatus", row.status);
    sessionStorage.setItem("currentIntakeTaskId", row.id);
    setCreatedData(row.created_date);
  };

  const flatRowCreator = (rows) => {
    const flatten = rows.map((row) => ({
      ...row,
      id: (
        <div
          className="page-zero-id"
          onClick={() => {
            handleIdClick(row);
          }}
        >
          {row.id}
        </div>
      ),
    }));

    return flatten;
  };

  const columns = [
    { headerName: "ID", renderName: "id" },
    { headerName: "Reported By", renderName: "reported_by" },
    { headerName: "Reviewed By", renderName: "reported_by" },
    { headerName: "Complete Date", renderName: "completed_date" },
    { headerName: "Created Date", renderName: "created_date" },
    { headerName: "Status", renderName: "status" },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const reportTableColumns = [
    {
      name: "Action",
      label: "ID",
      selector: (row) => row.id,
      width: "45vh",
      render: (row) => (
        <div
          className="page-zero-id"
          onClick={() => {
            handleIdClick(row);
          }}
        >
          {row.id}
        </div>
      ),
    },
    {
      name: "reported_by",
      label: "Reported By",
      selector: (row) => row.reported_by,
      width: "45vh",
    },
    {
      name: "reviewed_by",
      label: "Reviewed By",
      selector: (row) => row.reviewed_by,
      width: "45vh",
    },
    {
      name: "completed_date",
      label: "Complete Date",
      selector: (row) => row.completed_date,
      width: "45vh",
    },
    {
      name: "created_date",
      label: "Created Date",
      selector: (row) => row.created_date,
      width: "45vh",
    },
    {
      name: "status",
      label: "Status",
      selector: (row) => row.status,
      width: "45vh",
    },
  ];

  return (
    <div
      className={`intake-root`}
      style={{
        background: currentTheme.settingPage.mainBackground,
        color: currentTheme.settingPage.textColor,
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* <span
              style={{
                display: "flex",
                margin: "1vh",
                height: "2.5vh",
                width: "26vh",
                backgroundColor: currentTheme.settingPage.button,
                color: "#f5f5f5",
                padding: ".5vh",
                borderRadius: "1vh",
                alignItems: "center",
                marginBottom: "2vh",
                justifyContent: "center",
              }}
              onClick={() => setIntakeClicked(true)}
            >
              <img
                src="/icons/add-user.svg"
                width={22}
                style={{ marginRight: "1vh" }}
              />
              <span>View Intake Form</span>
            </span> */}
        <Button
          label={"View Intake Form"}
          backgroundColor={currentTheme.settingPage.button}
          color="#F5F5F5"
          style={{
            display: "flex",
            margin: ".5rem",
            height: "1.5rem",
            width: "auto",
            backgroundColor: currentTheme.settingPage.button,
            color: "#f5f5f5",
            padding: ".5vh",
            borderRadius: "1vh",
            alignItems: "center",
            marginBottom: "2vh",
            justifyContent: "center",
            padding: "15px",
          }}
          iconSrc={"/icons/add-user.svg"}
          onClick={() => setIntakeClicked(true)}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "2rem",
          height: "-webkit-fill-available",
          marginBottom: "2rem",
        }} 
       > */}
      {/* <DataTable
        headerColor={currentTheme.settingPage.tableHeader}
        dataColor={currentTheme.settingPage.search}
        headerTextColor={currentTheme.settingPage.textColor}
        data={data}
        columns={reportTableColumns}
        itemsPerPageOptions={[5, 10, 20]}
        defaultItemsPerPage={10}
        maxHeight={"45vh"}
        height={"52vh"}
      /> */}

      <Table
        rows={data}
        columns={columns}
        frontendSort
        noCustomizeColumn
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        containerHeight="60vh"
        themeMode={themeMode}
      />
    </div>
    // </div>
  );
};
export default Page0;
