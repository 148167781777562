// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-checkbox {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-checkbox input[type='checkbox'] {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.custom-checkbox input[type='checkbox']:checked {
  background-color: #ff4e3a;
  border-color: #ff4e3a;
}

.custom-checkbox input[type='checkbox']:checked::after {
  content: "\\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: small;
}
`, "",{"version":3,"sources":["webpack://./src/components/checkbox/Checkbox.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,qBAAqB;EACrB,gBAAgB;EAChB,6BAA6B;EAC7B,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".custom-checkbox {\r\n  position: relative;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.custom-checkbox input[type='checkbox'] {\r\n  width: 20px;\r\n  height: 20px;\r\n  -webkit-appearance: none;\r\n  -moz-appearance: none;\r\n  appearance: none;\r\n  background-color: transparent;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n}\r\n\r\n.custom-checkbox input[type='checkbox']:checked {\r\n  background-color: #ff4e3a;\r\n  border-color: #ff4e3a;\r\n}\r\n\r\n.custom-checkbox input[type='checkbox']:checked::after {\r\n  content: \"\\2713\";\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  color: white;\r\n  font-size: small;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
