// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-root {
    height: 4.5vh;
    font-size: 2vh;
    padding-left: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .center-text {
    display: flex;
    justify-content: center; /* Horizontally center the text */
    align-items: center; /* Vertically center the text */
    flex: 1; /* To take remaining space and center the text */
    margin-bottom: 2vh;
    margin-left: -20vh;
    z-index: 1000;
  }
  
  .welcome-msg {
    background-color: #8c00d5;
    color: white;
    width: 20vh;
    align-items: center;
    justify-content: center;
    text-align: center;  
    padding: 1vh;
    border-radius: 1vh;
  }`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB,EAAE,iCAAiC;IAC1D,mBAAmB,EAAE,+BAA+B;IACpD,OAAO,EAAE,gDAAgD;IACzD,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".footer-root {\r\n    height: 4.5vh;\r\n    font-size: 2vh;\r\n    padding-left: 2vh;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n  }\r\n  \r\n  .center-text {\r\n    display: flex;\r\n    justify-content: center; /* Horizontally center the text */\r\n    align-items: center; /* Vertically center the text */\r\n    flex: 1; /* To take remaining space and center the text */\r\n    margin-bottom: 2vh;\r\n    margin-left: -20vh;\r\n    z-index: 1000;\r\n  }\r\n  \r\n  .welcome-msg {\r\n    background-color: #8c00d5;\r\n    color: white;\r\n    width: 20vh;\r\n    align-items: center;\r\n    justify-content: center;\r\n    text-align: center;  \r\n    padding: 1vh;\r\n    border-radius: 1vh;\r\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
