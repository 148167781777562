import React, { useState } from "react";

const Button = ({
  label,
  onClick,
  backgroundColor,
  color,
  width,
  height,
  disabled,
  style,
  borderRadius,
  iconSrc,
  className,
  fontSize,
  id
}) => {
  const buttonStyle = {
    backgroundColor: backgroundColor || "blue",
    color: color || "white",
    padding: "4px 8px",
    border: "none",
    borderRadius: borderRadius || "1.5vh",
    cursor: disabled ? "not-allowed" : "pointer",
    width: width || "auto",
    height: height || "auto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...style, // Merge additional styles passed as prop
  };

  const [isAnimating, setIsAnimating] = useState(false);

  const viewportWidth = window.innerWidth;
  const adjustTextSize = () => {
    let textSize = fontSize ?? "inherit";

    if (viewportWidth < 768) {
      textSize = "8px";
    } else if (viewportWidth >= 768 && viewportWidth < 1260) {
      textSize = "10px";
    } else {
      textSize = "0.8rem";
    }

    return {
      ...buttonStyle,
      fontSize: textSize,
    };
  };

  const buttonStyles = adjustTextSize();

  const handleOnClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
      onClick();
    }, 1000);
  };

  return (
    <button
      id={id}
      className={`button ${isAnimating ? "button-fire" : ""}`}
      style={buttonStyles}
      onClick={() => handleOnClick()}
      disabled={disabled}
    >
      {iconSrc && (
        <img
          src={iconSrc}
          alt="Icon"
          style={{ marginRight: "5px", height: "1.5rem", width: "auto" }}
        />
      )}
      {label}
    </button>
  );
};

export default Button;
