import React, { useState, useEffect } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./PersonalInfo.css";
import {
  getCustomerUiRbca,
  addCustomerUiUser,
  updateCustomerUiUser,
} from "../../../../../utils/CustomerUiAPI";
import Button from "../../../../common/button/Button";
import { useTheme } from "../../../../../ThemeContext";
import TextBox from "../../../../common/textBox/TextBox";
import Select from "../../../../common/select/Select";
import { useLogout } from "../../../../../utils/hooks";
import { updateUserPassword } from "../../../../../firebase";
import { CustomDialog, Dropdown, InputField } from "@voicecare.ai/ui-library";
import LogoLoader from "../../../../common/loaders/gif-logo-loader";

const PersonalInfo = ({
  editData,
  setVisible,
  handleClose,
  mode,
  notifyMessage,
  setMode,
  roles,
  isLoading,
  setIsLoading,
}) => {
  const { themeMode, currentTheme } = useTheme();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobilePrompt, setMobilePrompt] = useState(true);
  const [emailPrompt, setEmailPrompt] = useState(true);
  const [textPrompt, setTextPrompt] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const auth = getAuth();
  const user = auth.currentUser;

  // State variables for validation errors
  const [roleError, setRoleError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [role, setRole] = useState("");

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleConfirmationClose = () => setIsConfirmationOpen(false);
  const handleConfirmationOpen = () => setIsConfirmationOpen(true);

  useEffect(() => {
    reset();
    if (editData) {
      setEmail(editData.email);
      setEmailPrompt(editData.email_message);
      setFirstName(editData.first_name);
      setLastName(editData.last_name);
      setMobilePrompt(editData.mobile_prompt);
      setPhoneNumber(editData.phone);
      setRole(editData.role);
      setTextPrompt(editData.text_message);
    }
  }, []);

  const logout = useLogout();
  const handleLogout = () => {
    logout();
  };

  const reset = () => {
    setRole("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setPassword("");
    setConfirmPassword("");
    setMobilePrompt(true);
    setEmailPrompt(true);
    setTextPrompt(true);
  };

  const validateName = (name) => {
    if (name) {
      const alphabetsOnlyRegex = /^[a-zA-Z\s]*$/;

      // Check if it contains only alphabets and spaces
      if (!alphabetsOnlyRegex.test(name)) {
        return "Only alphabets and spaces are allowed.";
      }

      // // Check for recurring alphabets
      // for (let i = 0; i < name.length - 1; i++) {
      //   if (name[i] === name[i + 1]) {
      //     return "Recurring alphabets are not allowed.";
      //   }
      // }

      // Check for sequential alphabets
      for (let i = 0; i < name.length - 2; i++) {
        if (
          name.charCodeAt(i) === name.charCodeAt(i + 1) - 1 &&
          name.charCodeAt(i) === name.charCodeAt(i + 2) - 2
        ) {
          return "Sequential alphabets are not allowed.";
        }
      }

      // Check length (adjust the maximum length as needed)
      if (name.length > 50) {
        return "Name is too long (maximum 50 characters).";
      }

      // If all validations pass, return an empty string (no error)
      return "";
    }
  };

  const handleSave = () => {
    setIsLoading(true);
    const clinicName = sessionStorage.getItem("clinicName");
    const data = {
      clinic_name: clinicName,
      email: email,
      role: role.value,
      first_name: firstName,
      last_name: lastName,
      name: `${firstName} ${lastName}`,
      phone: phoneNumber,
      // password: password,
      mobile_prompt: mobilePrompt,
      text_message: textPrompt,
      email_message: emailPrompt,
      locations: [],
    };
    handleConfirmationClose();
    if (mode === "edit") {
      notifyMessage("Editing User Details!");
      // Update the user's password
      const newPassword = password; // New password you want to set

      if (!user) {
        console.error("No user signed in");
        setIsLoading(false);
        return;
      }

      // Check if the password is provided
      if (!password) {
        console.error("Password is required for updating.");
        setIsLoading(false);
        return;
      }

      // Check if the user's authentication provider supports updating passwords
      if (
        !user.providerData ||
        !user.providerData.some(
          (provider) => provider.providerId === "password"
        )
      ) {
        console.error(
          "User's authentication provider does not support updating passwords."
        );
        setIsLoading(false);
        return;
      }

      // Perform the password update
      updateUserPassword(newPassword);

      updateCustomerUiUser(
        data,
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId")
      )
        .then((res) => {
          notifyMessage("Successfully Edited!");
          setIsLoading(false);
          handleClose();
          setMode("save");
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    } else {
      notifyMessage("Saving User Details!");
      addCustomerUiUser(
        data,
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId")
      )
        .then((res) => {
          createUserWithEmailAndPassword(auth, email, password);
          notifyMessage("Successfully Saved!");
          handleClose();
          setIsLoading(false);
          setMode("save");
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    }
  };

  const validateRole = () => {
    if (!role) {
      setRoleError("Role is required");
      return false;
    }
    setRoleError("");
    return true;
  };

  const validatePhoneNumber = () => {
    if (!phoneNumber) {
      setPhoneNumberError("Phone Number is required");
      return false;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      setPhoneNumberError("Invalid phone number");
      return false;
    }

    setPhoneNumberError("");
    return true;
  };

  const validateFirstName = () => {
    const nameRegex = /^[A-Za-z\s]+$/; // Regular expression to match alphabets and spaces

    if (!firstName) {
      setFirstNameError("First Name is required");
      return false;
    }

    if (!nameRegex.test(firstName)) {
      setFirstNameError("First Name can only contain alphabets and spaces");
      return false;
    }

    setFirstNameError("");
    return true;
  };

  const validateLastName = () => {
    const nameRegex = /^[A-Za-z\s]+$/; // Regular expression to match alphabets and spaces

    if (!lastName) {
      setLastNameError("Last Name is required");
      return false;
    }

    if (!nameRegex.test(lastName)) {
      setLastNameError("Last Name can only contain alphabets and spaces");
      return false;
    }

    setLastNameError("");
    return true;
  };

  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      return false;
    }

    setEmailError("");
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Password is required");
      return false;
    } else if (password.length < 8 || password.length > 20) {
      setPasswordError("Password must be between 8 and 20 characters");
      return false;
    }

    // Password should contain a combination of alphabet, special character, number,
    // lowercase, and uppercase characters
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password should contain at least one lowercase, one uppercase, one number, one special character"
      );
      return false;
    }

    // Check if the password is the same as the first name, last name, or email
    const lowerFirstName = firstName && firstName.toLowerCase();
    const lowerLastName = lastName && lastName.toLowerCase();
    const lowerEmail = email && email.toLowerCase();
    const lowerPassword = password && password.toLowerCase();

    if (
      lowerPassword.includes(lowerFirstName) ||
      lowerPassword.includes(lowerLastName) ||
      lowerPassword.includes(lowerEmail)
    ) {
      setPasswordError(
        "Password cannot contain your first name, last name, or email"
      );
      return false;
    }

    setPasswordError("");
    return true;
  };

  const validateConfirmPassword = () => {
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      return false;
    } else if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
      return false;
    }

    setConfirmPasswordError("");
    return true;
  };

  const handleNextClick = () => {
    // Update state variables before performing validation
    setRoleError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPhoneNumberError("");

    const isRoleValid = validateRole();
    const isFirstNameValid = validateFirstName();
    const isLastNameValid = validateLastName();

    const isEmailValid = validateEmail();
    const isPhoneNumberValid = validatePhoneNumber();
    const isPasswordValid = validatePassword();
    const isConfirmPasswordValid = validateConfirmPassword();

    if (
      isRoleValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isPhoneNumberValid &&
      isPasswordValid &&
      isConfirmPasswordValid
    ) {
      handleConfirmationOpen();
    }
  };

  {
    roles.map((res) => <option value={res}>{res}</option>);
  }

  const getOptions = () => {
    const options = [];
    roles.map((res) => {
      options.push({ value: res, label: res });
    });
    return options;
  };

  return (
    <div
      className="personal-info-div"
      style={{
        background: currentTheme.settingPage.popupBackground,
        color: currentTheme.settingPage.textColor,
      }}
    >
      <div className="personal-info-title">
        {pageNumber === 1 ? "Personal User Information" : "Practice Location"}
      </div>
      {pageNumber === 1 && (
        <div className="page1">
          <div className="personal-info-description">
            You can add/update the basic information of your own account here.
          </div>
          <div className="role-parent">
            <div className="personal-info-role">
              Role <span style={{ color: "red" }}>*</span>
            </div>
            <div onBlur={validateRole}>
              {/* <Select
          key={role}
          value={role}
          onValueChange={setRole}
          options={getOptions()}
          backgroundColor={themeMode === "dark" ? "#535167" : "#ededed"}
          textColor={currentTheme.settingPage.textColor}
          width={"16vw"}
        /> */}
              <Dropdown
                value={role}
                onChange={(selectedOption) => setRole(selectedOption)}
                options={getOptions()}
                placeholder="Select Role"
                type="basic"
                themeMode={themeMode}
                width={"16vw"}
                height={"4vh"}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor:
                      themeMode === "light" ? "#ededed" : "#535167",
                    boxShadow: "none",
                    border: "none",
                  }),
                }}
              />
            </div>

            {roleError && <div className="error-message">{roleError}</div>}
          </div>
          <div style={{ display: "flex" }}>
            <div className="first-name-parent">
              <div className="personal-info-role">
                First Name <span style={{ color: "red" }}>*</span>
              </div>
              {/* <input
          type={"text"}
          style={{
            background: themeMode === "dark" ? "#535167" : "#ededed",
            color: currentTheme.settingPage.textColor,
          }}
          className="personal-info-text-box"
          placeholder="Type here your first name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          onBlur={() => setFirstNameError(validateName(firstName))}
        /> */}
              <InputField
                value={firstName}
                onChange={setFirstName}
                placeholder="Enter your first name"
                error={firstNameError}
                themeMode={themeMode}
                currentTheme={currentTheme}
                style={{ width: "15vw" }}
              />
              {firstNameError && (
                <div className="error-message">{firstNameError}</div>
              )}
            </div>
            <div className="last-name-parent">
              <div className="personal-info-role">
                Last Name <span style={{ color: "red" }}>*</span>
              </div>
              <InputField
                value={lastName}
                onChange={setLastName}
                placeholder="Enter your last name"
                error={lastNameError}
                themeMode={themeMode}
                currentTheme={currentTheme}
                style={{ width: "15vw" }}
              />
              {lastNameError && (
                <div className="error-message">{lastNameError}</div>
              )}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="email-parent">
              <div className="personal-info-role">
                Email ID <span style={{ color: "red" }}>*</span>
              </div>
              <InputField
                value={email}
                onChange={setEmail}
                placeholder="Enter your email"
                error={emailError}
                themeMode={themeMode}
                currentTheme={currentTheme}
                style={{ width: "15vw" }}
                disabled={mode === "edit"} // Disable in edit mode
              />
              {emailError && <div className="error-message">{emailError}</div>}
            </div>
            <div className={themeMode}>
              <div className="personal-info-role">
                Phone Number <span style={{ color: "red" }}>*</span>
              </div>
              <PhoneInput
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                defaultCountry="US"
                flagsOnly={true}
                required
              />
              {phoneNumberError && (
                <div className="error-message">{phoneNumberError}</div>
              )}
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: "2vh" }}>
            <div className="new-password-parent">
              <div className="personal-info-role">
                Password <span style={{ color: "red" }}>*</span>
              </div>
              <TextBox
                value={password}
                type="password"
                iconUrl={
                  themeMode === "dark"
                    ? "/icons/login-lock.svg"
                    : "/icons/login-lock-light.svg"
                }
                style={{
                  width: "15vw",
                  padding: "1vh",
                  backgroundColor: themeMode === "dark" ? "#535167" : "#ededed",
                  color: currentTheme.settingPage.textColor,
                  marginRight: "5vh",
                  border: "none",
                }}
                onChange={setPassword}
                placeholder={"Type your new password"}
                textBoxBackground={themeMode === "dark" ? "#535167" : "#ededed"}
                textBoxText={currentTheme.loginPage.textBoxText}
                onBlur={validatePassword}
              />
              {passwordError && (
                <div className="error-message">{passwordError}</div>
              )}
            </div>
            <div className="confirm-password-parent">
              <div className="personal-info-role">
                Confirm Password <span style={{ color: "red" }}>*</span>
              </div>
              <TextBox
                value={confirmPassword}
                type="password"
                iconUrl={
                  themeMode === "dark"
                    ? "/icons/login-lock.svg"
                    : "/icons/login-lock-light.svg"
                }
                style={{
                  width: "15vw",
                  padding: "1vh",
                  backgroundColor: themeMode === "dark" ? "#535167" : "#ededed",
                  color: currentTheme.settingPage.textColor,
                  marginRight: "5vh",
                  border: "none",
                }}
                onChange={setConfirmPassword}
                placeholder={"Confirm your new password"}
                textBoxBackground={themeMode === "dark" ? "#535167" : "#ededed"}
                textBoxText={currentTheme.loginPage.textBoxText}
                onBlur={validateConfirmPassword}
              />
              {confirmPasswordError && (
                <div className="error-message">{confirmPasswordError}</div>
              )}
            </div>
          </div>
          <Button
            label={"Save"}
            disabled={isLoading}
            onClick={handleNextClick}
            backgroundColor={"#ff4e3a"}
            width={"15vh"}
            style={{ borderRadius: "1.5vh", marginTop: "2vh" }}
            height={"4vh"}
          />
        </div>
      )}
      <CustomDialog
        open={isConfirmationOpen}
        message={`Are you sure you want to ${
          mode === "edit" ? "update this" : "create a new"
        } user?`}
        onConfirm={handleSave}
        onCancel={handleConfirmationClose}
        themeMode={themeMode}
        currentTheme={currentTheme}
      />
    </div>
  );
};

export default PersonalInfo;
