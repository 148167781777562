// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-switch-checked .ant-switch-handle::before {
  background-color: #04002b; /* Change the color of the circle to blue */
}

.ant-switch .ant-switch-handle::before {
  background-color: #04002b; /* Change the color of the circle to blue */
}`, "",{"version":3,"sources":["webpack://./src/components/toggle/Toggle.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,2CAA2C;AACxE;;AAEA;EACE,yBAAyB,EAAE,2CAA2C;AACxE","sourcesContent":[".ant-switch-checked .ant-switch-handle::before {\r\n  background-color: #04002b; /* Change the color of the circle to blue */\r\n}\r\n\r\n.ant-switch .ant-switch-handle::before {\r\n  background-color: #04002b; /* Change the color of the circle to blue */\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
