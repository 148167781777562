/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import "./IntakeForm.css";
import { useTheme } from "../../ThemeContext";
import { useEffect, useState } from "react";
import Button from "../common/button/Button";
import QuestionInfo from "./questionInfo/QuestionInfo";
import { getCommonQuestions } from "../../utils/CustomerUiAPI";
import { Scrollbar } from "smooth-scrollbar-react";

const IntakeForm = ({ setIntakeClicked }) => {
  const { themeMode, currentTheme } = useTheme();
  const [isMedical, setIsMedical] = useState(true);
  const [isDefaultQuestions, setIsDefaultQuestions] = useState(true);
  const [questionClicked, setQuestionClicked] = useState(false);
  const [userQuestions, setUserQuestions] = useState({medical: [], dental: []});
  const [questionData, setQuestionData] = useState({medical: [], dental: []});

  const handleOpen = () => {
    setQuestionClicked(true);
    setIsDefaultQuestions(false);
  };

  useEffect(() => {
    getCommonQuestions(
      sessionStorage.getItem("mail"),
      sessionStorage.getItem("sessionId")
    )
      .then((res) => {
        setQuestionData(res.response);
      })
      .catch((res) => {
        console.log(res);
      });
  }, []);

  const transformData = (data) => {
    const transformedData = [];

    if (Object.keys(data).length !== 0) {
      // Check if "medical" data exists
      if (isMedical) {
        transformedData.push(
          ...data.medical.map((item) => ({
            question: isDefaultQuestions
              ? item.originalQuestion
              : item.question,
            questionType: isDefaultQuestions
              ? item.originalQuestionType
              : item.questionType,
          }))
        );
      } else {
        // dental
        transformedData.push(
          ...data.dental.map((item) => ({
            question: isDefaultQuestions
              ? item.originalQuestion
              : item.question,
            questionType: isDefaultQuestions
              ? item.originalQuestionType
              : item.questionType,
          }))
        );
      }
    }

    return transformedData;
  };

  const getFreqColor = (isSelected) => {
    if (isSelected) {
      return themeMode === "dark" ? "#ff4e3a" : "#ff4e3a";
    } else {
      return themeMode === "dark" ? "#3e3b56" : "#e3e2e5";
    }
  };

  const getFreqTextColor = (isSelected) => {
    if (isSelected) {
      return themeMode === "dark" ? "#ffffff" : "#ffffff";
    } else {
      return themeMode === "dark" ? "#ffffff" : "#04002b";
    }
  };

  const renderQuestionType = (questionType) => {
    let formattedQuestionType = "";
    let formattedQuestionIcon = "";
    switch (questionType) {
      case "text":
      case "None":
        formattedQuestionType = "Text";
        formattedQuestionIcon =
          themeMode === "light"
            ? "icons/light-theme/Text.svg"
            : "/icons/text.svg";
        break;
      case "numerical":
        formattedQuestionType = "Numerical";
        formattedQuestionIcon =
          themeMode === "light"
            ? "icons/light-theme/Text.svg"
            : "/icons/text.svg";
        break;
      case "yes/no":
        formattedQuestionType = "Yes/No";
        formattedQuestionIcon =
          themeMode === "light"
            ? "icons/light-theme/no.svg"
            : "/icons/yesNo.svg";
        break;
      case "date":
        formattedQuestionType = "Date";
        formattedQuestionIcon =
          themeMode === "light"
            ? "icons/light-theme/Calendar.svg"
            : "/icons/calendar-dark.svg";
        break;
    }

    return (
      <div style={{ display: "flex", width: "6rem" }}>
        <img
          src={formattedQuestionIcon}
          style={{ height: "1rem", marginRight: ".5rem" }}
        />
        <span>{formattedQuestionType}</span>
      </div>
    );
  };

  const renderQuestion = (question, questionType) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "1rem",
          padding:'0.4rem',
          borderBottom: "0.2px solid grey",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              width: "80%",
              overflowWrap: "break-word",
              paddingRight: "1rem",
            }}
          >
            {question}
          </span>
          <span> {renderQuestionType(questionType)} </span>
        </div>
        {/* <hr style={{ borderColor: "#302d4b" }} /> */}
      </div>
    );
  };

  const renderQuestions = (questions) => {
    const list = [];
    questions.map((question) => {
      list.push(renderQuestion(question.question, question.questionType));
    });
    return (
      <Scrollbar
        damping={0.1}
        className={themeMode === "light" ? "light-scrollbar" : "dark-scrollbar"}
        thumbMinSize={20}
        alwaysShowTracks={true}
        syncCallbacks={true}
        plugins={{
          overscroll: { effect: "bounce" },
        }}
        style={{ height: "90%" }}
      >
        {list}
      </Scrollbar>
    );
  };

  const handleBack = () => {
    setIntakeClicked(false);
  };

  return (
    <div
      className={`intake-root`}
      style={{
        background: currentTheme.settingPage.mainBackground,
        color: currentTheme.settingPage.textColor,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="intake-medical-type" style={{ width: "20vw" }}>
          <button
            className={isMedical ? "switch-freq-selected" : "switch-freq"}
            style={{
              backgroundColor: getFreqColor(isMedical),
              color: getFreqTextColor(isMedical),
            }}
            onClick={() => setIsMedical(true)}
          >
            Medical
          </button>
          <button
            className={!isMedical ? "switch-freq-selected" : "switch-freq"}
            style={{
              backgroundColor: getFreqColor(!isMedical),
              color: getFreqTextColor(!isMedical),
            }}
            onClick={() => setIsMedical(false)}
          >
            Dental
          </button>
        </div>
        {setIntakeClicked && (
          <Button
            label={"Back"}
            onClick={handleBack}
            backgroundColor={"#ff4e3a"}
            width={"15vh"}
            style={{
              borderRadius: "1.5vh",
              marginTop: "1vh",
              marginRight: "1vh",
            }}
            height={"4vh"}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "2rem",
          height: "63vh",
          marginBottom: "3rem",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            background: themeMode === "light" ? "#e3e2e5" : "#131032",
            color: currentTheme.settingPage.textColor,
            marginRight: "1rem",
            height: "100%",
            borderRadius: ".5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              className="intake-question-type"
              style={{ background: currentTheme.settingPage.tableHeader }}
            >
              <button
                className={
                  isDefaultQuestions ? "switch-freq-selected" : "switch-freq"
                }
                style={{
                  backgroundColor: getFreqColor(isDefaultQuestions),
                  color: getFreqTextColor(isDefaultQuestions),
                }}
                onClick={() => setIsDefaultQuestions(true)}
              >
                Default Questions
              </button>
              <button
                className={
                  !isDefaultQuestions ? "switch-freq-selected" : "switch-freq"
                }
                style={{
                  backgroundColor: getFreqColor(!isDefaultQuestions),
                  color: getFreqTextColor(!isDefaultQuestions),
                }}
                onClick={() => setIsDefaultQuestions(false)}
              >
                User Specific Questions
              </button>
            </div>
            {isDefaultQuestions
              ? renderQuestions(transformData(questionData))
              : renderQuestions(transformData(userQuestions))}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            background: themeMode === "light" ? "#e3e2e5" : "#131032",
            borderRadius: ".5rem",
          }}
        >
          {questionClicked ? (
            <QuestionInfo
              setQuestionClicked={setQuestionClicked}
              isMedical={isMedical}
              setUserQuestions={setUserQuestions}
              userQuestions={userQuestions}
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Button
                label={"Add New Question"}
                backgroundColor={"#ff4e3a"}
                style={{ borderRadius: ".5rem" }}
                height={"4vh"}
                onClick={handleOpen}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntakeForm;
