import React, { useState } from "react";
import {
  RightOutlined,
  CalendarOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Flex, Typography, Button, Spin } from "antd";
import { useTheme } from "../../../ThemeContext";

import "./EnterNewQuestion.css";
import { InputField } from "@voicecare.ai/ui-library";

export const Colors = {
  primaryBg: "#07012b",
  secondaryBg: "#141132",
  bgLite: "#3f3c57",
  white: "#ffffff",
  orange: "#f54f3b",
  text: "#efedf6",
  textLight: "#84849b",
};

const { Text } = Typography;

export const EnterNewQuestion = ({
  onChangeAnswerType,
  selectedAnswerType,
  question,
  setQuestion,
  yNQuestion,
  setYnQuestion,
  setAnswerTypeYN,
  handleYNQuestion,
}) => {
  const answerTypes = {
    YES_OR_NO: "yes/no",
    TEXT: "text",
    DATE: "date",
    NUMERICAL: "numerical",
  };

  const { currentTheme, themeMode } = useTheme();

  const answerTypeButtons = [
    {
      type: answerTypes.YES_OR_NO,
      title: "Yes/No",
      icon: (
        <img
          src={
            themeMode === "light"
              ? "icons/light-theme/no.svg"
              : "/icons/yesNo.svg"
          }
          style={{ width: "1rem", height: "1rem", marginRight: ".5rem" }}
        />
      ),
    },
    {
      type: answerTypes.TEXT,
      title: "Text",
      icon: (
        <img
          src={
            themeMode === "light"
              ? "icons/light-theme/Text.svg"
              : "/icons/text.svg"
          }
          style={{ width: "1rem", height: "1rem", marginRight: ".5rem" }}
        />
      ),
    },
    {
      type: answerTypes.DATE,
      title: "Date",
      icon: (
        <img
          src={
            themeMode === "light"
              ? "icons/light-theme/Calendar.svg"
              : "/icons/calendar-dark.svg"
          }
          style={{ width: "1rem", height: "1rem", marginRight: ".5rem" }}
        />
      ),
    },
    {
      type: answerTypes.NUMERICAL,
      title: "Numerical",
      icon: (
        <img
          src={
            themeMode === "light"
              ? "icons/light-theme/Text.svg"
              : "/icons/text.svg"
          }
          style={{ width: "1rem", height: "1rem", marginRight: ".5rem" }}
        />
      ),
    },
  ];

  const answerTypeYesNo = ["YES", "NO"];

  return (
    <Flex
      style={{
        display: "flex",
        alignItems: "center",
        padding: 40,
        color: currentTheme.settingPage.textColor,
      }}
    >
      {
        <Flex vertical gap={10} style={{ width: "-webkit-fill-available" }}>
          <Flex vertical gap={10}>
            <Text
              style={{
                fontSize: 16,
                color: currentTheme.settingPage.textColor,
              }}
            >
              Question
            </Text>
            <InputField
              value={question}
              onChange={setQuestion}
              placeholder="Enter your question"
              type="text"
              style={{
                width: "auto",
                backgroundColor: themeMode === "light" ? "#ededed" : "#242243",
                color: themeMode === "dark" ? "#efedf6" : "#000",
                // border: "none",
              }}
              textBoxBackground={themeMode === "light" ? "#ededed" : "#242243"}
              themeMode={themeMode}
              currentTheme={currentTheme}
            />
          </Flex>
          <Flex vertical gap={5}>
            <Text
              style={{
                fontSize: 16,
                color: currentTheme.settingPage.textColor,
              }}
            >
              Answer Type
            </Text>
            <Flex gap={20} justify="flex-start" style={{ marginTop: 5 }}>
              {answerTypeButtons.map((item) => {
                return (
                  <Button
                    key={item.type}
                    style={{
                      border: "none",
                      outline: "none",
                      paddingLeft: 12,
                      paddingRight: 12,
                      color:
                        selectedAnswerType === item.type
                          ? "#ffffff"
                          : currentTheme.settingPage.textColor,
                      borderRadius: 4,
                      backgroundColor:
                        selectedAnswerType === item.type
                          ? Colors.orange
                          : themeMode === "dark"
                          ? Colors.bgLite
                          : "#e3e2e5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                    onClick={() => {
                      onChangeAnswerType(item.type);
                    }}
                  >
                    {item.icon}
                    {item.title}
                  </Button>
                );
              })}
            </Flex>
            {/* {selectedAnswerType === answerTypes.YES_OR_NO && (
              <Flex vertical gap={20} style={{ marginTop: 20 }}>
                {answerTypeYesNo.map((type) => {
                  return (
                    <Flex vertical gap={10} key={type}>
                      <Flex>
                        <div style={{ width: 250 }}>
                          <Text
                            style={{ fontSize: 16, color: Colors.textLight }}
                          >
                            if the answer is{" "}
                            <Text style={{ fontSize: 16, color: Colors.text }}>
                              {type}
                            </Text>
                          </Text>
                        </div>
                        <Text
                          onClick={() => setAnswerTypeYN(type)}
                          style={{
                            fontSize: 16,
                            color: Colors.orange,
                            marginLeft: 40,
                            fontWeight: 500,
                          }}
                        >
                          Add Question
                        </Text>
                      </Flex>
                      {yNQuestion[type] && (
                        <Flex style={{ position: "relative" }}>
                          <input
                            className="auto-info-input"
                            style={{ width: "-webkit-fill-available" }}
                            type={"text"}
                            placeholder="Question"
                            value={yNQuestion?.[type]?.["question"] || ""}
                            onChange={(e) =>
                              handleYNQuestion(e.target.value, type)
                            }
                          />
                          <div
                            onClick={() => setAnswerTypeYN(type, true)}
                            style={{
                              position: "absolute",
                              right: 10,
                              top: "30%",
                              color: Colors.text,
                            }}
                          >
                            <DeleteOutlined />
                          </div>
                        </Flex>
                      )}
                    </Flex>
                  );
                })}
              </Flex>
            )} */}
          </Flex>
        </Flex>
      }
    </Flex>
  );
};
