import CryptoJS from "crypto-js";

const base64Key = process.env.REACT_APP_BASE64_KEY;

export const encryptData = (data) => {
  // return data;
  const key = CryptoJS.enc.Base64.parse(base64Key);

  // Convert JSON data to string and then to bytes
  const dataStr = JSON.stringify(data);

  // Generate a random 16-byte IV (Initialization Vector)
  const iv = CryptoJS.lib.WordArray.random(16);

  // Encrypt using AES and the same key
  const encrypted = CryptoJS.AES.encrypt(dataStr, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Convert encrypted data and IV to Base64 for transmission
  const ciphertext = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  const ivBase64 = iv.toString(CryptoJS.enc.Base64);

  // Return the encrypted data as an object
  return {
    ciphertext: ciphertext,
    iv: ivBase64,
  };
};

export const decryptData = (encryptedData) => {
  if(!encryptedData.ciphertext) return encryptedData;

  const key = CryptoJS.enc.Base64.parse(base64Key);
  const iv = CryptoJS.enc.Base64.parse(encryptedData.iv); // Decode IV from base64
  const ciphertext = CryptoJS.enc.Base64.parse(encryptedData.ciphertext); // Decode ciphertext from base64

  // Decrypt using AES and the same key
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Convert decrypted data to a string (UTF-8)
  const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);

  // Parse decrypted string as JSON and return it
  return JSON.parse(decryptedStr);
};