import React, { useState } from "react";
import {
  RightOutlined,
  CalendarOutlined,
  CaretDownOutlined,
  FieldTimeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Flex, Typography, Spin } from "antd";
import { Button } from "@voicecare.ai/ui-library";
import "./callAutomationInfo.css";
import { Colors } from "../callhistory/helpers";
import { useTheme } from "../../ThemeContext";
import * as XLSX from "xlsx";

const { Text } = Typography;

function convertTo12HourFormat(time24) {
  const [hours, minutes, seconds = "00"] = time24.split(":");
  let period = "AM";

  let hour = parseInt(hours, 10);
  let formattedHour = hour;

  if (hour >= 12) {
    period = "PM";
    if (hour > 12) {
      formattedHour = hour - 12;
    }
  }

  formattedHour = String(formattedHour).padStart(2, "0"); // Add leading zero if needed

  return `${formattedHour}:${minutes} ${period}`;
}

const selectedDateRange = (input = {}) => {
  const dates = [];
  for (let i = 0; i < input["startDates"]?.length; i++) {
    const formattedStartDate = input["startDates"][i].split("-").join("/");
    const formattedEndDate = input["endDates"][i].split("-").join("/");
    const dateRange = `${formattedStartDate} to ${formattedEndDate}`;
    dates.push(dateRange);
  }
  return dates;
};

export const DataValidationOverlay = ({
  batchId,
  open,
  loading,
  success,
  failed,
  children,
  closeOverlay,
  csvData,
  errorList,
  setErrorOpen,
}) => {
  function formatDate() {
    const date = new Date();

    const pad = (num) => num.toString().padStart(2, "0");

    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const year = date.getFullYear();

    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
  }

  // const downloadCSV = (response) => {
  //   console.log(response, "downloadCSV")
  //   // Create a Blob from the response data
  //   const blob = new Blob([response], { type: "text/csv" });
  //   const url = window.URL.createObjectURL(blob);

  //   // Create a link element and simulate a click to trigger the download
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = `ERROR-${formatDate(new Date())}-${batchId}.csv`;
  //   document.body.appendChild(a);
  //   a.click();

  //   // Cleanup
  //   window.URL.revokeObjectURL(url);
  //   document.body.removeChild(a);
  // };

  const downloadCSV = (response) => {
    console.log(response, "downloadCSV");
  
    // Access the csv_data property
    const csvData = response.csv_data;
  
    // Create a Blob from the csvData
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
  
    // Create a link element and simulate a click to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `ERROR-${formatDate(new Date())}-${batchId}.csv`;
    document.body.appendChild(a);
    a.click();
  
    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleDownload = () => {
    downloadCSV(errorList);
    setTimeout(() => {
      closeOverlay();
    }, [3000]);
  };

  const { themeMode } = useTheme();
  const renderOverlay = () => {
    const getData = () => {
      const item = sessionStorage.getItem("insufficient_storage_error");
      if (item !== "{}") {
        return JSON.parse(item);
      }
      return { recommended_date: "", recommended_time: "" };
    };

    const isDataReady = () => {
      console.log(
        "lllop",
        sessionStorage.getItem("insufficient_storage_error")
      );
      return JSON.parse(
        sessionStorage.getItem("insufficient_storage_error").includes("date")
      );
    };
    return (
      <Flex
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          bottom: 0,
          left: "2.5vh",
          inset: "0px 0px 0px 10px",
          right: 0,
          height: "84vh",
          backgroundColor:
            themeMode === "dark"
              ? "rgba(18, 30, 78, 0.97)"
              : "rgb(250,249,251, 0.90)",
          color: themeMode === "dark" ? "white" : "black",
          zIndex: 1000,
        }}
      >
        {loading && (
          <img
            className="loader"
            src={
              themeMode === "dark"
                ? "/icons/loader_white.gif"
                : "/icons/loader_black.gif"
            }
          />
        )}
        {isDataReady() && (
          <div>
            <span>
              We recommend selecting an end date of {getData().recommended_date}
              , and an end time of {getData().recommended_time} PT to allow
              sufficient time for completing the calls
            </span>
            <br />
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                border: "none",
                outline: "none",
                paddingLeft: 32,
                paddingRight: 32,
                margin: "auto",
                background: Colors.orange,
                color: Colors.text,
                borderRadius: 4,
              }}
              onClick={() => {
                setErrorOpen(false);
                sessionStorage.setItem("insufficient_storage_error", "{}");
              }}
              label="Close"
            >
              Close
            </Button>
          </div>
        )}
        {!isDataReady() && success && (
          <Flex vertical gap={10} justify="center" align="center">
            <Text
              style={{
                fontSize: 19,
                color: themeMode === "dark" ? Colors.text : "black",
                fontWeight: "600",
              }}
            >
              Data Validated Successfully
            </Text>
            <Button
              style={{
                border: "none",
                outline: "none",
                paddingLeft: 32,
                paddingRight: 32,
                margin: "auto",
                background: Colors.orange,
                color: Colors.text,
                borderRadius: 4,
              }}
              label="Return Home"
              onClick={() => closeOverlay()}
            />
          </Flex>
        )}
        {failed && (
          <Flex vertical gap={10} justify="center" align="center">
            <Text
              style={{
                fontSize: 19,
                color: themeMode === "dark" ? Colors.text : "black",
              }}
            >
              Data Not Valid
            </Text>
            <Text
              style={{
                fontSize: 13,
                color: themeMode === "dark" ? Colors.text : "black",
              }}
            >
              Download the case file to detect errors
            </Text>
            <Button
              style={{
                border: "none",
                outline: "none",
                paddingLeft: 32,
                paddingRight: 32,
                margin: "20px, auto",
                background: Colors.orange,
                color: Colors.text,
                borderRadius: 4,
              }}
              onClick={handleDownload}
              label="Download Case File"
            />
            <Text
              style={{
                fontSize: 13,
                color: themeMode === "dark" ? Colors.text : "black",
              }}
            >
              Please revise the document and try again
            </Text>
          </Flex>
        )}
      </Flex>
    );
  };
  return (
    <Flex vertical style={{ width: "100%", position: "relative" }}>
      {open && renderOverlay()}
      {children}
    </Flex>
  );
};

export const SelectedDates = ({ isMultipleDaysSelected, selectedDates }) => {
  const [open, setOpen] = useState(false);
  const { themeMode } = useTheme();
  return (
    <div
      className={`${themeMode}-call-automation-selected-dates-wrapper`}
      style={{ zIndex: 999 }}
    >
      <Flex
        justify="center"
        align="center"
        gap={10}
        style={{
          marginLeft: 10,
          cursor: "pointer",
          userSelect: "none",
          backgroundColor: "inherit",
          zIndex: 900,
        }}
        onClick={() => setOpen(!open)}
      >
        <CalendarOutlined
          style={{
            color: Colors.orange,
            fontSize: 17,
          }}
        />
        <Text style={{ color: Colors.orange }}>
          {isMultipleDaysSelected
            ? "Multiple Dates Selected"
            : "Random Dates Selected"}{" "}
          (Click to view)
        </Text>
        <CaretDownOutlined
          style={{
            fontSize: 17,
            rotate: open ? "180deg" : "0deg",
            marginLeft: 40,
          }}
        />
      </Flex>
      {open && (
        <Flex
          className={`${themeMode}-call-automation-selected-dates`}
          gap={isMultipleDaysSelected ? 8 : 10}
        >
          {selectedDates?.map((item, index) => {
            return (
              <Text
                key={index}
                style={{
                  color: themeMode === "dark" ? Colors.text : "black",
                  fontSize: 13,
                }}
                className="call-automation-selected-date"
              >
                {item}
              </Text>
            );
          })}
        </Flex>
      )}
    </div>
  );
};

const SelectedTime = ({ startTime, endTime }) => {
  return (
    <Flex style={{ marginLeft: 20 }}>
      <img src="icons/clock.svg" style={{ width: "2vh", marginRight: "1vh" }} />
      <Flex gap={5}>
        <Text style={{ color: Colors.textLight, fontSize: 17 }}>
          {startTime}
        </Text>
        <Text
          style={{ color: Colors.textLight, margin: "0 10px", fontSize: 17 }}
        >
          -
        </Text>
        <Text style={{ color: Colors.textLight, fontSize: 17 }}>{endTime}</Text>
      </Flex>
    </Flex>
  );
};

export const CallAutomationInfo = ({
  isRecursive,
  isMultipleDaysSelected,
  selectedRanges,
  handleValidate,
  startTime,
  endTime,
  handleBack,
  handleAdd,
  handleDelete,
  loading,
}) => {
  const getDates = () => {
    const dateConversion = (date) => {
      const mm = date.month.number.toString().padStart(2, "0");
      const dd = date.day.toString().padStart(2, "0");
      const year = date.year;
      return `${mm}-${dd}-${year}`;
    };
    const data = {
      selectedDates: [],
      startDates: [],
      endDates: [],
    };
    if (!isMultipleDaysSelected) {
      // If random is false, populate startDates and endDates arrays
      selectedRanges?.forEach((range) => {
        data.selectedDates.push(dateConversion(range));
      });
    } else {
      selectedRanges?.forEach((ranges) => {
        data.startDates.push(dateConversion(ranges[0]));
        data.endDates.push(dateConversion(ranges[1]));
      });
    }
    return data;
  };
  const { selectedDates, startDates, endDates } = getDates();
  const dates = isMultipleDaysSelected
    ? selectedDateRange({ startDates, endDates })
    : selectedDates.map((item) => item?.replaceAll("-", "/"));
  const { currentTheme, themeMode } = useTheme();
  return (
    <div
      style={{ margin: "12px auto", width: "100%", boxSizing: "border-box" }}
    >
      <Flex justify="start" align="center">
        <Text
          style={{
            color: currentTheme.homePage.textColor,
            fontSize: 17,
            marginRight: 10,
          }}
        >
          {isRecursive ? "Recursive" : "One Time"}
        </Text>
        <div>
          <RightOutlined
            style={{
              fontSize: 15,
            }}
          />
        </div>
        <SelectedDates
          isMultipleDaysSelected={isMultipleDaysSelected}
          selectedDates={dates}
        />
        <SelectedTime
          startTime={convertTo12HourFormat(startTime)}
          endTime={convertTo12HourFormat(endTime)}
        />
        <div style={{ marginLeft: "auto", display: "flex", gap: "1vh" }}>
          <Button
            onClick={handleAdd}
            style={{ borderRadius: "1vh", width: "8rem" }}
            label="Add Patient"
            className="validate-button"
          >
            Add Patient
          </Button>
          <Button
            onClick={handleDelete}
            style={{ borderRadius: "1vh", width: "8rem" }}
            label="Delete Patient"
            className="validate-button"
          >
            Delete Patient
          </Button>

          <Button
            onClick={handleBack}
            style={{ borderRadius: "1vh", width: "8rem" }}
            label="Back"
            className="validate-button"
          >
            Back
          </Button>

          <Button
            onClick={handleValidate}
            disabled={loading}
            style={{ borderRadius: "1vh", width: "8rem" }}
            label="Validate Data"
            className="validate-button"
          >
            Validate Data
          </Button>
        </div>
      </Flex>
    </div>
  );
};
