import React, { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import WaveSurferTimeline from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js";
import { WaveForm } from "wavesurfer-react";
import Data from "./transcript.json";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js";
import "./CustomAudioPlayer.css";
import { Transcripts } from "../../components/Transcript";
import { useTheme } from "../../../../ThemeContext";

export default function CustomAudioPlayer({
  src,
  transcriptFilter,
  searchInput,
}) {
  const { currentTheme, themeMode } = useTheme();
  const wavesurferRef = useRef(null);
  const timelineRef = useRef(null);
  const [audioSrc, setAudioSrc] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);

  const volumeRef = useRef(null);
  const [volume, setVolume] = useState(1);
  const [filtredTranscript, setFilteredTranscript] = useState(Data);

  useEffect(() => {
    if (transcriptFilter) {
      const response = Data.filter((res) =>
        res.text.includes(transcriptFilter)
      );
      setFilteredTranscript(response);
    } else {
      setFilteredTranscript(Data);
    }
  }, [transcriptFilter]);

  useEffect(() => {
    document.documentElement.classList.toggle("dark-mode", themeMode === "dark");
  }, [themeMode]);

  useEffect(() => {
    const wavesurfer = WaveSurfer.create({
      container: wavesurferRef.current,
      waveColor: currentTheme.callhistory.waveColor,
      progressColor: currentTheme.callhistory.progressColor,
      cursorWidth: 1,
      scrollParent: true,
      hideScrollbar: true,
      cursorColor: "#6b42de",
      barWidth: 2,
      barGap: 6,
      height: 50,
      normalize: true,
      responsive: true,
      backend: "WebAudio",
      mediaControls: true,
      mono: true,
      plugins: [
        WaveSurferTimeline.create({
          container: timelineRef.current,
          scrollParent: true,
          primaryFontColor: themeMode === 'dark' ? '#fff' : '#000',
          secondaryFontColor: themeMode === 'dark' ? '#fff' : '#000',
          offset:0
        }),
      ],
    });
    wavesurfer.load(src);
    setAudioSrc(wavesurfer);
    wavesurfer.on("audioprocess", () => {
      updateTranscript(wavesurfer.getCurrentTime());
    });
    return () => {
      wavesurfer.destroy();
    };
  }, [src]);

  useEffect(() => {
    const currentTranscriptItem = document.querySelector(".highlight");
    if (currentTranscriptItem) {
      currentTranscriptItem.scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }
  }, [currentTime]);

  function updateTranscript(time) {
    setCurrentTime(time);
  }

  function handleVolumeChange(e) {
    const newVolume = parseFloat(e.target.value);
    audioSrc.setVolume(newVolume);
    setVolume(newVolume);
  }

  const handlePlayPause = () => {
    audioSrc.playPause();
  };

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return (
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (remainingSeconds < 10
        ? "0" + Math.trunc(remainingSeconds)
        : Math.trunc(remainingSeconds))
    );
  }

  return (
    <div>
      <div
        style={{
          background: currentTheme.callhistory.waveBackground,
          borderRadius: "1vh",
        }}
        ref={wavesurferRef}
      />
      <div ref={timelineRef} />
      <WaveForm
        id="waveform"
        waveSurfer={wavesurferRef.current}
        onWaveformReady={(waveform) => {
          waveform.addPlugin(
            TimelinePlugin.create({
              container: waveform.container,
              scrollParent: true,
            })
          );
        }}
        onPosChange={updateTranscript}
      />

      <div>
        <div style={{ height: "4vh", float: "left" }}>
          <img
            src={
              themeMode === "dark"
                ? "/icons/dark-theme/play-pause.png"
                : "/icons/light-theme/play-pause.png"
            }
            style={{ marginTop: ".5vh", float: "left", height: "2rem", width: "2rem" }}
            onClick={handlePlayPause}
          />
        </div>
        <div style={{ height: "4vh", float: "right" }}>
          <img
            src={
              themeMode === "dark" ? "/icons/dark-theme/volume.png" : "/icons/light-theme/volume.png" }
            style={{ marginTop: ".5vh", verticalAlign: "middle", height: "3vh" }}
            onClick={handlePlayPause}
          />
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            ref={volumeRef}
            onChange={handleVolumeChange}
            style={{
              marginLeft: "1vh",
              marginTop: "1vh",
              verticalAlign: "middle",
            }}
          />
        </div>
      </div>
      <Transcripts searchInput={searchInput} />
    </div>
  );
}
