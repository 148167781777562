import Popup from "react-animated-popup";
import { useEffect, useState } from "react";

import { updateUserRole, addUserRole } from "../../../../utils/CustomerUiAPI";
import Button from "../../../common/button/Button";
import { useTheme } from "../../../../ThemeContext";
import "../UserRoles.css";
import { CustomDialog, InputField } from "@voicecare.ai/ui-library";

export const UserRole = ({
  visible,
  editData,
  setEditData,
  setVisible,
  mode,
  notifyMessage,
  setMode,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const { currentTheme, themeMode } = useTheme();

  const handleOpenConfirmation = () => setIsConfirmationOpen(true);
  const handleCloseConfirmation = () => setIsConfirmationOpen(false);

  useEffect(() => {
    setSelectedOptions([]);
    if (editData.permissions) {
      setSelectedOptions(editData.permissions);
    }
    if (editData.role) {
      setRole(editData.role);
    }
  }, [visible]);

  const handleClose = () => {
    setEditData({});
    setRole("");
    setSelectedOptions([]);
    setVisible(false);
  };

  const permissionsData = [
    {
      value: "view_call_home",
      label: "View Home Page",
    },
    {
      value: "view_call_status",
      label: "View Call Status",
    },
    {
      value: "view_call_automation",
      label: "View Call Automation",
    },
    {
      value: "view_call_history",
      label: "View Call History",
    },
    {
      value: "view_call_analytics",
      label: "View Call Analytics",
    },
    {
      value: "view_user_access",
      label: "View User Access",
    },
    // {
    //   value: "update_user_access",
    //   label: "Update User Access",
    // },
    {
      value: "view_user_roles",
      label: "View User Roles",
    },
    // {
    //   value: "update_user_roles",
    //   label: "Update User Roles",
    // },
  ];

  const handleCheckboxChange = (event) => {
    const option = event.target.value;
    const newSelectedOptions = [...selectedOptions];

    if (event.target.checked) {
      newSelectedOptions.push(option);
    } else {
      const index = newSelectedOptions.indexOf(option);
      newSelectedOptions.splice(index, 1);
    }
    setSelectedOptions(newSelectedOptions);
  };

  const handleSave = () => {
    const data = {
      role: role,
      permissions: selectedOptions,
    };
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!role) {
      setRoleError("Role name is mandatory.");
      return;
    }
    if (!nameRegex.test(role)) {
      setRoleError("Role name must contain only alphabets.");
      return;
    } else {
      setRoleError(""); // Clear the role error if it's valid
    }
    if (selectedOptions.length === 0) {
      return;
    }
    handleCloseConfirmation();
    if (mode === "edit") {
      notifyMessage("Editing User Roles!");
      updateUserRole(
        data,
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId")
      ).then((res) => {
        notifyMessage("Succesfully Edited Roles!");
        handleClose();
        setMode("save");
      });
    } else {
      notifyMessage("Saving User Roles!");
      addUserRole(
        data,
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId")
      ).then((res) => {
        notifyMessage("Succesfully Saved Roles!");
        handleClose();
        setMode("save");
      });
    }
  };

  return (
    <Popup
      visible={visible}
      onClose={() => handleClose()}
      className="add-new-user-div"
      style={{
        height: "auto",
        width: "38vw",
        background: currentTheme.settingPage.popupBackground,
        color: currentTheme.settingPage.textColor,
      }}
    >
      <button
        className="close-btn"
        onClick={() => handleClose()}
        style={{ color: currentTheme.settingPage.textColor }}
      >
        {themeMode === "dark" ? (
          <img src="/icons/cancel-grey.svg" width={"20vh"} />
        ) : (
          <img src="/icons/cancel-orange.svg" width={"20vh"} />
        )}
      </button>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: currentTheme.settingPage.textColor,
          margin: "auto",
          marginLeft: "5vh",
          marginTop: "2vh",
          height: "auto",
        }}
      >
        <span
          style={{
            fontSize: "2.5vh",
            fontWeight: "600",
            letterSpacing: ".5px",
          }}
        >
          Role Name:
        </span>
        <InputField
          value={role}
          onChange={setRole}
          placeholder="Role name"
          type="text"
          style={{
            width: "auto",
            padding: "0.3rem",
            backgroundColor: themeMode === "light" ? "#ededed" : "#535167",
            color: currentTheme.settingPage.textColor,
            border: "none",
            outline: "none",
          }}
          textBoxBackground={themeMode === "light" ? "#ededed" : "#535167"}
          textBoxText={currentTheme.settingPage.textColor}
          themeMode={themeMode}
          currentTheme={currentTheme}
          disabled={mode === "edit"}
        />
        <span style={{ color: "red", fontSize: "small" }}>{roleError}</span>
        <br />
        <span style={{ marginBottom: "15px", marginTop: "15px" }}>
          Permissions:
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {permissionsData.map((permission) => (
              <span
                key={permission.value}
                style={{
                  width: "50%", // Displaying in two columns
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label className="custom-checkbox">
                  <input
                    className={`${themeMode}-check-box`}
                    type="checkbox"
                    value={permission.value}
                    style={{
                      margin: "5px",
                      accentColor: "#ff4e3a",
                      border:
                        themeMode === "dark"
                          ? "1px solid white"
                          : "1px solid black",
                      borderRadius: "5px",
                    }}
                    checked={selectedOptions.includes(permission.value)}
                    onChange={(e) => handleCheckboxChange(e)}
                  />
                </label>
                {permission.label}
              </span>
            ))}
          </div>
          {selectedOptions.length === 0 && (
            <span style={{ color: "red", fontSize: "small" }}>
              Atleast one permission is mandatory
            </span>
          )}
        </div>
        <Button
          label={"Save"}
          onClick={handleOpenConfirmation}
          backgroundColor={"#ff4e3a"}
          width={"14vh"}
          height={"4vh"}
          style={{ borderRadius: "1.5vh" }}
        />
      </div>
      <CustomDialog
        open={isConfirmationOpen}
        message={`Are you sure you want to ${
          mode === "edit" ? "update this" : "create a new"
        } Role?`}
        onConfirm={handleSave}
        onCancel={handleCloseConfirmation}
        themeMode={themeMode}
        currentTheme={currentTheme}
      />
    </Popup>
  );
};
