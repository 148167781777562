/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useTheme } from "../../../ThemeContext";

const LogoLoader = () => {
  const { themeMode } = useTheme();

  return (
    <img
      className="loader"
      src={
        themeMode === "dark"
          ? "/icons/loader_white.gif"
          : "/icons/loader_black.gif"
      }
    />
  );
};

export default LogoLoader;
