import { Flex, Space, Typography } from "antd";
import React from "react";
import { convertMsToTime } from "../helpers";
import { useCallHistoryProvider } from "../context/CallHIstoryContext";
import { useTheme } from "../../../ThemeContext";
import { Scrollbar } from "smooth-scrollbar-react";

const { Text } = Typography;

export const Transcripts = ({ searchInput }) => {
  const { callContent, selectedCall, callList } = useCallHistoryProvider();
  const { currentTheme, themeMode } = useTheme();
  const { call_transcription_fragment, transcription_verified } =
    callContent || {};

  // Find the start time of the call from callList based on call_id
  const startTime = callList.find(
    (res) => res.call_id === callContent.call_id
  )?.call_start_time;

  const renderTranscript = ({
    telecom_persona_type,
    time_end,
    text,
    time_start,
  }) => {
    // Calculate the time difference in milliseconds
    const timeDifference = new Date(time_start) - new Date(startTime);

    // Function to convert milliseconds to HH:MM:SS format
    function convertMsToTime(ms) {
      const seconds = Math.floor((ms / 1000) % 60);
      const minutes = Math.floor((ms / (1000 * 60)) % 60);
      const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }

    // Calculate adjusted time
    const adjustedTime = convertMsToTime(timeDifference);

    const isComplete = false; // TODO:
    return (
      <Flex className="call-history__transcript-wrapper">
        <Text style={{ color: currentTheme.callhistory.searchColor }}>
          {telecom_persona_type} {adjustedTime}
        </Text>
        <Flex
          className={`call-history__transcript ${
            isComplete ? "completed" : ""
          }`}
          style={{ background: currentTheme.callhistory.waveBackground }}
        >
          <Text>{text}</Text>
        </Flex>
      </Flex>
    );
  };

  let height = "30vh";

  // Adjust height based on screen size

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <hr />
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          className="ant-typography css-dev-only-do-not-override-xu9wm8"
          style={{
            color: "rgb(229, 229, 234)",
            fontSize: "18px",
            marginTop: "10px",
            flex: 1,
          }}
        >
          Call Transcript
        </span>
        <span
          style={{
            display: "flex",
            textAlign: "right",
            alignItems: "center",
          }}
        >
          {transcription_verified === "True" && (
            <img
              src="/icons/verified.svg"
              width={"28rem"}
              height={"28rem"}
              title="Human Verifed"
            />
          )}
          <span style={{ marginLeft: ".5rem" }}>Human Verified</span>
        </span>
      </div>
      <Scrollbar
        className={themeMode === "light" ? "light-scrollbar" : "dark-scrollbar"}
        damping={0.1}
        thumbMinSize={20}
        alwaysShowTracks={true}
        continuousScrolling={true}
        style={{ height: height }}
      >
        {call_transcription_fragment
          ?.filter((item) =>
            item.text.toLowerCase().includes(searchInput.toLowerCase())
          )
          .map((item, i) => {
            return renderTranscript(item);
          })}
      </Scrollbar>
    </Space>
  );
};
