"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeProvider = exports.useTheme = void 0;
var react_1 = __importStar(require("react"));
var ThemeContext = (0, react_1.createContext)(undefined);
var useTheme = function () {
    var context = (0, react_1.useContext)(ThemeContext);
    if (context === undefined) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
exports.useTheme = useTheme;
var darkTheme = {
    loginPage: {
        mainBackground: "#04002b",
        textColor: "#ffffff",
        textBoxBackground: "#242143",
        textBoxText: "#9e9dac",
        forgotPasswordColor: "#9b99aa",
        loginButtonColor: "#ff4e3a",
        themeBackground: "#242243",
        copyrightColor: "#9b99aa",
        versionColor: "#9b99aa",
        forceLoginBgColor: "#242243",
    },
    homePage: {
        mainBackground: "#04002b",
        textColor: "#ffffff",
        tableHeader: "#302d4c",
        tableHover: "#6d6b7f",
        tableButton: "#bbbbc3",
        searchBackground: "#242143",
        search: "#242243",
    },
    settingPage: {
        mainBackground: "#04002b",
        themeBackground: "#242243",
        textColor: "#ffffff",
        search: "#242243",
        tableHeader: "#302d4c",
        tableHover: "#6d6b7f",
        tableButton: "#bbbbc3",
        button: "#ff4e3a",
        popupBackground: "#3e3c57",
    },
    sidebar: {
        mainBackground: "#3e3b56",
        textColor: "#ffffff",
    },
    callAutomationPage: {
        mainBackground: "#04002b",
        textColor: "#ffffff",
        dateBackground: "#3e3b56",
        timeBackground: "#3e3b56",
        timeButtonOpen: "#04002b",
        timeButtonClose: "#04002b",
        timeButtonCloseColor: "#ffffff",
        timeButtonOpenColor: "#ee4939",
        timeboxColor: "#7f7d8b",
        timeBoxHoverColor: "#ff4e3a",
        processTypeColor: "#3e3b56",
        automationTypeColor: "#3e3b56",
        buttonColor: "#3e3b56",
        activeButtonColor: "#ff4e3a",
        carsPageOneBackground: "#131032",
        carsPageOneColor: "#464263",
        submitColor: "#ffffff",
    },
    header: {
        mainBackground: "#151138",
        textColor: "#ffffff",
    },
    footer: {
        mainBackground: "#151138",
        textColor: "#9b99aa",
    },
    content: "#151138",
    loginRight: "#04002b",
    callhistory: {
        mainBackground: "#04002b",
        searchBackground: "#242243",
        searchColor: "#e5e5ea",
        callListSelectedBackground: "#3d3b56",
        searchTranscriptColor: "#302e49",
        waveColor: "#5b5773",
        progressColor: "#ffffff",
        waveBackground: "#302e49",
        primaryBg: "#f2f1f5",
        secondaryBg: "#141132",
        bgLite: "#3f3c57",
        white: "#ffffff",
        orange: "#f54f3b",
        text: "#efedf6",
        textLight: "#84849b",
        callSummaryInActive: "#302e49",
    },
};
var lightTheme = {
    loginPage: {
        mainBackground: "#ffffff",
        textColor: "#0b0731",
        textBoxBackground: "#dedede",
        textBoxText: "#04002b",
        forgotPasswordColor: "#686680",
        loginButtonColor: "#ff4e3a",
        themeBackground: "#d7d4e0",
        copyrightColor: "#686680",
        versionColor: "#686680",
        forceLoginBgColor: "#e2e1e5",
    },
    homePage: {
        mainBackground: "#f2f1f5",
        textColor: "#0b0731",
        tableHeader: "#e9e8ea",
        tableHover: "#f0f0f0",
        tableButton: "#bbbbc3",
        search: "#ffffff",
        searchBackground: "#e3e2e5",
    },
    settingPage: {
        mainBackground: "#f2f1f5",
        textColor: "#0b0731",
        themeBackground: "#d7d4e0",
        tableHeader: "#e9e8ea",
        tableHover: "#f0f0f0",
        tableButton: "#bbbbc3",
        search: "#ffffff",
        button: "#ff4e3a",
        popupBackground: "#ffffff",
    },
    sidebar: {
        mainBackground: "#f7f6f9",
        textColor: "#000000",
    },
    callAutomationPage: {
        mainBackground: "#f7f6f9",
        textColor: "#000000",
        dateBackground: "#e3e2e5",
        timeBackground: "#e3e2e5",
        timeButtonOpen: "#ffffff",
        timeButtonClose: "#d7d6d8",
        timeButtonCloseColor: "#3d3a5a",
        timeButtonOpenColor: "#ff6250",
        timeboxColor: "#f1f1f2",
        timeBoxHoverColor: "#ff4e3a",
        processTypeColor: "#e3e2e5",
        automationTypeColor: "#e3e2e5",
        buttonColor: "#e3e2e5",
        activeButtonColor: "#ff4e3a",
        carsPageOneBackground: "#e3e2e5",
        carsPageOneColor: "#5d5a76",
        submitColor: "#ffffff",
    },
    header: {
        mainBackground: "#faf9fb",
        textColor: "#0b0731",
    },
    footer: {
        mainBackground: "#faf9fb",
        textColor: "#9b99aa",
    },
    callhistory: {
        mainBackground: "#f2f1f5",
        searchBackground: "#e3e2e5",
        searchColor: "#5d5a76",
        searchTranscriptColor: "#d7d6d8",
        callListSelectedBackground: "#bababc",
        textLight: "#84849b",
        waveBackground: "#d7d6d8",
        waveColor: "#777589",
        progressColor: "#04002b",
        primaryBg: "#f2f1f5",
        secondaryBg: "#e3e2e5",
        bgLite: "#e3e2e5",
        white: "#ffffff",
        orange: "#f54f3b",
        text: "#0b0731",
        callSummaryInActive: "#d7d6d8",
    },
    content: "#f2f1f5",
    loginRight: "#ffffff",
};
var ThemeProvider = function (_a) {
    var children = _a.children;
    var previousTheme = localStorage.getItem("theme");
    var _b = (0, react_1.useState)(previousTheme !== null && previousTheme !== void 0 ? previousTheme : "dark"), themeMode = _b[0], setThemeMode = _b[1];
    var toggleTheme = function () {
        var mode = themeMode === "light" ? "dark" : "light";
        localStorage.setItem("theme", mode);
        setThemeMode(mode);
    };
    var currentTheme = themeMode === "dark" ? darkTheme : lightTheme;
    var value = {
        themeMode: themeMode,
        toggleTheme: toggleTheme,
        currentTheme: currentTheme,
    };
    return (react_1.default.createElement(ThemeContext.Provider, { value: value }, children));
};
exports.ThemeProvider = ThemeProvider;
exports.default = exports.ThemeProvider;
