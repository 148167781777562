import React from "react";
import "./NoData.css"; // Add styling if needed

const NoData = () => (
  <div className="no-data-container">
    <p>No data available</p>
  </div>
);

export default NoData;
