"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.multiSelectDropdownStyles = void 0;
var ColorConstants_1 = require("utils/constants/color-constants/ColorConstants");
var multiSelectDropdownStyles = function (currentTheme, themeMode) { return ({
    container: function (provided) { return (__assign(__assign({}, provided), { backgroundColor: "transparent", margin: "1rem" })); },
    control: function (provided, state) { return (__assign(__assign({}, provided), { backgroundColor: themeMode === "dark" ? ColorConstants_1.blue3 : ColorConstants_1.white, color: themeMode === "dark" ? ColorConstants_1.white : ColorConstants_1.black, border: "none", borderRadius: "5px", boxShadow: state.isFocused ? "0 0 0 2px rgba(0, 0, 0, 0.2)" : "none" })); },
    menu: function (provided) { return (__assign(__assign({}, provided), { zIndex: 9999, borderRadius: "5px", backgroundColor: themeMode === "dark" ? ColorConstants_1.blue4 : ColorConstants_1.white, color: themeMode === "dark" ? ColorConstants_1.white : ColorConstants_1.black })); },
    option: function (provided, state) { return (__assign(__assign({}, provided), { backgroundColor: state.isFocused ? "#e6e6e6" : "transparent", "&:hover": {
            backgroundColor: themeMode === "dark" ? "#e6e6e6" : "#e6e6e6",
            color: "#ff4e3a",
        }, color: themeMode === "dark" ? (state.isFocused ? ColorConstants_1.black : ColorConstants_1.white) : ColorConstants_1.black })); },
    singleValue: function (provided) { return (__assign(__assign({}, provided), { color: themeMode === "dark" ? ColorConstants_1.white : ColorConstants_1.black })); },
    placeholder: function (provided) { return (__assign(__assign({}, provided), { color: themeMode === "dark" ? ColorConstants_1.white : ColorConstants_1.black })); },
    menuList: function (provided) { return (__assign(__assign({}, provided), { scrollbarWidth: "thin", scrollBehavior: "smooth", scrollbarColor: themeMode === "light" ? "#504e67 #c2c2c2" : "#cac9d4 #312e4d", "&::-webkit-scrollbar": { width: "8px", height: "8px" }, "&::-webkit-scrollbar-track": { background: "#f1f1f1" }, "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px",
        }, "&::-webkit-scrollbar-thumb:hover": { background: "#555" } })); },
}); };
exports.multiSelectDropdownStyles = multiSelectDropdownStyles;
