import TextBox from "../common/textBox/TextBox";
import "./Login.css";
import { useState, useEffect } from "react";
import Button from "../common/button/Button";
import { Switch } from "antd";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import ForceLoginDialog from "./ForceLoginDialog";
import { insertUserActivity } from "../../utils/CustomerUiAPI";
import { useTheme } from "../../ThemeContext";
import { auth, passwordResetEmail } from "../../firebase";
import { ToastContainer, toast } from "react-toast";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../utils/hooks";
import LogoutTimer from "./LogoutTimer";

import GoogleLoginButton from "../googleLogin/GoogleLoginButton";
import NoSessionDialog from "./NoSessionDialog";
import TermsAndConditionsPopup from "./TermsAndConditionsPopup";

const Login = ({ setIsAuthenticated }) => {
  const { themeMode, toggleTheme, currentTheme } = useTheme();
  const [userMail, setUserMail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState(false);
  const [signInClicked, setSignInClicked] = useState(false);
  const [error, setError] = useState("");
  const [forceLoginDialogVisible, setForceLoginDialogVisible] = useState(false);
  const [noSessionDialogVisible, setNoSessionDialogVisible] = useState(false);
  const [forceLoginDialogResult, setForceLoginDialogResult] =
    useState("cancel");
  const [validateExecuted, setValidateExecuted] = useState(false);
  const [isSsoError, setIsSsoError] = useState(false);
  const backgroundStyle = {
    background: 'url("/icons/login-background.svg")',
    backgroundSize: "cover", // You can use other values like 'contain' or specific dimensions
    backgroundPosition: "center center", // You can adjust this as needed
  };

  useEffect(() => {
    if (sessionStorage.getItem("samlSuccess") === "true") {
      const email = sessionStorage.getItem("mail");
      if (email) {
        handleLoginSaml(email);
      }
    }
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && !signInClicked) {
        document.getElementById("loginButton").click();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [signInClicked]);

  const extractEmailFromSamlResponse = (samlResponse) => {
    // Decode the Base64-encoded SAML response
    const decodedResponse = atob(samlResponse);

    // Parse the XML response
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(decodedResponse, "text/xml");

    // Extract the email from the XML response
    // Assume that the email is contained in an element with a specific tag, e.g., 'NameID'
    const emailElement = xmlDoc.getElementsByTagName("NameID")[0];
    const email = emailElement ? emailElement.textContent : null;

    return email;
  };

  const handleLoginSuccess = (response) => {
    const { email, access_token } = response;
    console.log("Login Success:", response);

    const sessionId = generateAlphanumeric();
    sessionStorage.setItem("sessionId", sessionId);
    localStorage.setItem("sessionId", sessionId);
    sessionStorage.setItem("mail", email);
    loginValidate(email, "loginTime", "True", sessionId, "sso");

    // Set the flag to indicate that loginValidate has been executed
    setValidateExecuted(true);

    setIsAuthenticated(true); // remove this line post testing which bypass login

    // You can now send this token to your backend for further processing.
  };

  const handleLoginFailure = (error) => {
    console.log("Login Failed:", error);
  };

  const logout = useLogout();

  const notifyMessage = (msg) => {
    toast.info(msg, {
      backgroundColor: "#f54f3b",
      color: "#ffffff",
      autoClose: 4000,
    });
  };
  const handleEmailChange = (value) => {
    setUserMail(value);
  };

  function handleOtherStatusCodes(statusCode) {
    // Your code to handle other HTTP status codes here
    console.log(`HTTP Status Code ${statusCode}: Handle accordingly.`);
  }

  const loginValidate = (
    userMail,
    activityType,
    forceFlagLogin,
    sessionId,
    type
  ) => {
    insertUserActivity(userMail, activityType, forceFlagLogin, sessionId)
      .then((res) => {
        setIsSsoError(false);
        if (res.statusCode === "SUCCESS") {
          // Handle successful response here
          sessionStorage.setItem("name", res.response.username);
          sessionStorage.setItem("permissions", res.response.permissions);
          sessionStorage.setItem("role", res.response.role);
          sessionStorage.setItem("userStatus", "notWarned");
          setLoginStatus(false);
          navigate("/home");
          setIsAuthenticated(true);
        } else {
          // Handle other HTTP status codes here
          handleOtherStatusCodes(res.status);
        }
      })
      .catch((error) => {
        // Handle network errors or other exceptions
        if (error.response.status === 506) {
          // Handle error with code 506 here
          setForceLoginDialogVisible(true);
          if (type === "sso") {
            setIsSsoError(true);
          } else {
            setIsSsoError(false);
          }
        } else if (error.response.status === 510) {
          setNoSessionDialogVisible(true);
        }
        console.error(error);
      });
  };

  function validateEmail(userMail) {
    const emailRegex = /^.{1,64}@[^\s@]+(\.[^\s@]+){1,255}$/;
    if (!emailRegex.test(userMail)) {
      return "Please enter a valid email address with the following constraints:\n1. Local part (before @) should be 1 to 64 characters.\n2. Domain part (after @) should be 1 to 255 characters.";
    }
    return null; // Return null if the email is valid.
  }

  // const setIsAuthenticated = (status) => {
  //   localStorage.setItem("isAuthenticated", status);
  // };

  // const handlePasswordResetEmail = async (email) => {
  //   setError("");
  //   if (email) {
  //     try {
  //       await passwordResetEmail(auth, email);
  //       console.log("Password reset email sent successfully!");
  //       notifyMessage("Password reset email sent successfully!");
  //     } catch (error) {
  //       if (error.code === "auth/invalid-email") {
  //         setError("Invalid email address.");
  //       } else if (error.code === "auth/user-not-found") {
  //         setError("Email or User not found.");
  //       } else if (error.code === "auth/too-many-requests") {
  //         setError("Too many requests. Try again later.");
  //       } else {
  //         // Handling other errors or 400 error specifically
  //         if (error.code === 400) {
  //           setError("Bad request. Please check your input.");
  //         } else {
  //           console.error("Error sending password reset email:", error);
  //         }
  //       }
  //     }
  //   } else {
  //     setError("Email is required for reset password email");
  //   }
  // };

  const handlePasswordResetEmail = async (email) => {
    setError("");
  
    if (email) {
      try {
        await passwordResetEmail(auth, email);
        notifyMessage("If this email exists, a password reset link has been sent.");
        setError("If this email exists, a password reset link has been sent.");
      } catch (error) {
        if (error.code === "auth/too-many-requests") {
          setError("Too many requests. Try again later.");
        } else {
          // Generic message for all other cases, including `auth/` errors
          setError("If this email exists, a password reset link has been sent.");
        }
  
        // Log error for debugging purposes
        console.error("Error sending password reset email:", error);
      }
    } else {
      setError("Email is required for reset password email.");
    }
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setSignInClicked(false);
  };

  const handleForceLoginDialogResult = (result) => {
    setForceLoginDialogResult(result);
    setForceLoginDialogVisible(false);

    if (isSsoError) {
      loginValidate(
        sessionStorage.getItem("mail"),
        "loginTime",
        "True",
        sessionStorage.getItem("sessionId"),
        "sso"
      );
    } else if (result === "continue") {
      // Handle continue action (e.g., call handleLogin again)
      handleLogin(2);
    }
  };

  const handleNoSessionsDialogResult = (result) => {
    setNoSessionDialogVisible(false);
  };

  const handleSamlLogin = () => {
    // Get the current base URL
    const currentBaseUrl = window.location.origin;
    console.log("cxcx".currentBaseUrl, window.location);

    // Construct the target URL using the current base URL
    const targetUrl = `https://customer-ui-new-audio-saml-dot-b-n-c-uat.uc.r.appspot.com/login?redirect_url_base=${currentBaseUrl}`;

    // Redirect to the constructed target URL
    window.location.href = targetUrl;
  };

  const handleLoginSaml = (email) => {
    const sessionId = generateAlphanumeric();
    sessionStorage.setItem("sessionId", sessionId);
    localStorage.setItem("sessionId", sessionId);
    sessionStorage.setItem("mail", email);
    loginValidate2(email, "loginTime", "True", sessionId, "sso")
      .then((response) => {
        if (response.success) {
          setValidateExecuted(true);
          setIsAuthenticated(true);
          navigate("/home");
        } else {
          setIsSsoError(true);
          setForceLoginDialogVisible(true);
        }
      })
      .catch((error) => {
        console.error("Error during SAML login validation:", error);
      });
  };

  const loginValidate2 = (
    userMail,
    activityType,
    forceFlagLogin,
    sessionId,
    type
  ) => {
    return new Promise((resolve, reject) => {
      insertUserActivity(userMail, activityType, forceFlagLogin, sessionId)
        .then((res) => {
          setIsSsoError(false);
          if (res.statusCode === "SUCCESS") {
            sessionStorage.setItem("name", res.response.username);
            sessionStorage.setItem("permissions", res.response.permissions);
            sessionStorage.setItem("role", res.response.role);
            setLoginStatus(false);
            sessionStorage.setItem("userStatus", "notWarned");
            resolve({ success: true });
          } else {
            handleOtherStatusCodes(res.status);
            reject(new Error("Failed to validate login"));
          }
        })
        .catch((error) => {
          if (error.response.status === 506 || error.response.status === 510) {
            setForceLoginDialogVisible(true);
            if (type === "sso") {
              setIsSsoError(true);
            }
          } else {
            console.log("Error inserting user activity:", error);
          }
          reject(error);
        });
    });
  };

  function generateAlphanumeric() {
    const length = 32;
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    return Array.from({ length }, () =>
      characters.charAt(Math.floor(Math.random() * charactersLength))
    ).join("");
  }
  const ValidateEmail = () => {
    if (!userMail) {
      setError("Email is required");
      setSignInClicked(false);
      return false;
    } else {
      return true;
    }
  }

  const validatePassword = () => {
    if (!password) {
      setError("Password is required");
      setSignInClicked(false);
      return false;
    } else if (password.length < 8 || password.length > 20) {
      setError("Password must be between 8 and 20 characters");
      setSignInClicked(false);
      return false;
    }

    // Password should contain a combination of alphabet, special character, number,
    // lowercase, and uppercase characters
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

    if (!passwordRegex.test(password)) {
      setError(
        "Password should contain at least one lowercase, one uppercase, one number, one special character"
      );
      return false;
    }
    setError("");
    return true;
  };
  const handleLogin = (triggerCount) => {
    setSignInClicked(true);
    // Clear previous errors
    setError("");

    const userMailTemp = userMail || sessionStorage.getItem("mail");

    if (triggerCount === 1) {
      if (!userMailTemp) {
        setError("Please enter your email address.");
        return;
      }

      const error = validateEmail(userMailTemp);

      if (!ValidateEmail()) {
        return;
      }

      if (!validatePassword()) {
        return;
      }

      if (error) {
        setError(error); // Set the error message if validation fails.
        return;
      }

      if (!password) {
        setError("Please enter your password.");
        return;
      }

      // Add password length validation
      if (password.length < 6) {
        setError("Password must be at least 6 characters long.");
        return;
      }

      if (password.length > 20) {
        setError("Password cannot exceed 20 characters.");
        return;
      }
    }

    const authentication = getAuth();
    sessionStorage.setItem("mail", userMailTemp);
    notifyMessage("Signing In!");
    signInWithEmailAndPassword(authentication, userMailTemp, password)
      .then((response) => {
        console.log("user", response);
        const sessionId =
          sessionStorage.getItem("mail") === userMailTemp &&
          localStorage.getItem("sessionId") !== "null"
            ? localStorage.getItem("sessionId")
            : generateAlphanumeric();
        sessionStorage.setItem("sessionId", sessionId);
        localStorage.setItem("sessionId", sessionId);
        setLoginStatus(true);
        sessionStorage.setItem("mail", userMailTemp);
        if (!validateExecuted || triggerCount === 2) {
          loginValidate(
            userMailTemp,
            "loginTime",
            triggerCount === 1 ? "False" : "True",
            sessionId,
            "login"
          );

          // Set the flag to indicate that loginValidate has been executed
          setValidateExecuted(true);

          setIsAuthenticated(true); // remove this line post testing which bypass login
          // sessionStorage.setItem("isLoginFirstTime", true);
        } else {
          // setSignInClicked(true);
          // setIsAuthenticated(true);
          // getCustomerUiUser(userMail, "test-session-id", userMail)
          //   .then((res) => {
          //     let name = "";
          //     if (res.response.length > 0) {
          //       name = `${res.response[0].first_name} ${res.response[0].last_name}`;
          //     }
          //     sessionStorage.setItem("name", name || userMail);
          //     getCustomerUiRbca(
          //       res.response[0].role,
          //       userMail,
          //       "test-session-id"
          //     ).then((info) => {
          //       sessionStorage.setItem("permissions", info.response[0].permissions);
          //       setLoginStatus(false);
          //       navigate("/home");
          //       setSignInClicked(false);
          //     });
          //   })
          //   .catch((e) => console.log(e));
          setSignInClicked(false);
          setLoginStatus(false);
          navigate("/home");
          setIsAuthenticated(true);
        }
      })
      .catch((error) => {
        setSignInClicked(false);
        setLoginStatus(false);
        // if (error.code === "auth/invalid-email") {
        //   setError("Invalid email");
        // } else if (error.code === "auth/user-not-found") {
        //   setError("Email or User not found.");
        // } else if (error.code === "auth/wrong-password") {
        //   setError("Incorrect password.");
        // } else {
        //   setError("An error occurred during sign-in.");
        // }
        if (error.code && error.code.startsWith("auth/")) {
          // Generic error message for authentication issues
          setError("Invalid email or password. Please try again.");
        } else {
          // Specific error message for non-authentication-related issues
          setError("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        // Reset UI states
        setSignInClicked(false);
        setLoginStatus(false);

        // Provide a generic error message regardless of the error code
        setError("Invalid email or password. Please try again.");

        // Optionally log the actual error for internal debugging (don't expose this to users)
        console.error("Login error: ", error);
      });
    // .catch((error) => {
    //   // setSignInClicked(false);
    //   setLoginStatus(false);
    //   if (error.code === "auth/invalid-email") {
    //     setError("Invalid email");
    //   }
    //   else if(error.code === "auth/user-not-found") {
    //     setError("Email or User not found.");
    //   } else if (error.code === "auth/wrong-password") {
    //     setError("Incorrect password.");
    //   } else {
    //     setError("An error occurred during sign-in.");
    //   }

    //   setSignInClicked(false);
    // });
    // setSignInClicked(false);
  };
  return (
    <div
      className="login-root"
      style={{ color: currentTheme.loginPage.textColor }}
    >
      <div className="login-left" style={backgroundStyle}></div>
      <div
        className="login-right"
        style={{ backgroundColor: currentTheme.loginPage.mainBackground }}
      >
        <span className="login-logo">
          {themeMode === "light" ? (
            <img src="/icons/new-logo-light.svg" width={250} />
          ) : (
            <img src="/icons/new-logo-dark.svg" width={250} />
          )}
        </span>
        <span className="login-sigin-text">Sign In</span>
        <span className="login-email-section">
          <span>
            <span
              style={{
                display: "flex",
                justifyContent: "left",
                marginBottom: "1vh",
                fontSize: "small",
                // fontWeight: themeMode === "light" ? "600" : "0",
              }}
            >
              Email ID
            </span>
            <TextBox
              type="text"
              value={userMail}
              iconUrl={
                themeMode === "dark"
                  ? "/icons/login-user.svg"
                  : "/icons/login-user-light.svg"
              }
              style={{
                width: "45vh",
                padding: "1vh",
              }}
              textBoxBackground={currentTheme.loginPage.textBoxBackground}
              textBoxText={currentTheme.loginPage.textBoxText}
              onChange={handleEmailChange}
              placeholder={"Enter your email"}
            />
          </span>
        </span>
        <span className="login-password-section">
          <span>
            <span
              style={{
                display: "flex",
                justifyContent: "left",
                marginBottom: "1vh",
                fontSize: "small",
                // fontWeight: themeMode === "light" ? "600" : "0",
              }}
            >
              Password
            </span>
            <TextBox
              value={password}
              type="password"
              iconUrl={
                themeMode === "dark"
                  ? "/icons/login-lock.svg"
                  : "/icons/login-lock-light.svg"
              }
              style={{ width: "45vh", padding: "1vh" }}
              onChange={handlePasswordChange}
              placeholder={"Enter your password"}
              textBoxBackground={currentTheme.loginPage.textBoxBackground}
              textBoxText={currentTheme.loginPage.textBoxText}
            />
          </span>
        </span>

        <span className="login-error-section">
          <span style={{ display: "flex", justifyContent: "middle" }}>
            {error}
          </span>
        </span>
        <span className="login-forgot-password-section">
          <span
            className="login-fp-text"
            style={{ color: currentTheme.loginPage.forgotPasswordColor }}
            onClick={() => handlePasswordResetEmail(userMail)}
          >
            Forgot Password?
          </span>
        </span>
        <span className="login-btn-section">
          <Button
            id="loginButton"
            label={"Login"}
            backgroundColor={currentTheme.loginPage.loginButtonColor}
            width={"47vh"}
            height={"4vh"}
            style={{ padding: "1vh", margin: "2vh" }}
            onClick={() => handleLogin(1)}
            disabled={signInClicked}
          />
          <span
            className="login-version"
            style={{ color: currentTheme.loginPage.versionColor }}
          >
            Version : 1.0.1
          </span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <img
              src="/icons/line-dark.png"
              alt="line"
              style={{ margin: "10px" }}
            />
            Sign In with
            <img
              src="/icons/line-dark.png"
              alt="line"
              style={{ margin: "10px" }}
            />
          </span>
          <span>
            <img
              src="/icons/saml-logo.png"
              alt="line"
              style={{ margin: "5px", width: "45px" }}
              onClick={() => handleSamlLogin()}
              disabled={signInClicked}
            />
            <GoogleLoginButton
              onLoginSuccess={handleLoginSuccess}
              onLoginFailure={handleLoginFailure}
            />
          </span>
        </span>

        <span className="login-footer">
          <span
            style={{
              display: "flex",
              background: currentTheme.loginPage.themeBackground,
              // padding: ".5vh",
              width: "16vh",
              justifyContent: "space-evenly",
              alignItems: "center",
              borderRadius: "1vh",
              marginRight: "22vw",
              height: "4vh",
            }}
          >
            <img
              src={
                themeMode === "light"
                  ? "/icons/theme-icon-light.svg"
                  : "/icons/theme-icon.svg"
              }
              height="20vh"
            />
            Theme{" "}
            <Switch
              style={{ background: "white" }}
              defaultChecked
              onChange={() => toggleTheme()}
              size="small"
            />
          </span>
          <span style={{ color: currentTheme.loginPage.copyrightColor }}>
            © {new Date().getFullYear()} Voicecare | All rights reserved
          </span>
        </span>
      </div>
      <ToastContainer autoClose={4000} delay={4000} />
      {forceLoginDialogVisible && (
        <ForceLoginDialog
          onResult={handleForceLoginDialogResult}
          onCancel={setForceLoginDialogVisible}
        />
      )}

      <TermsAndConditionsPopup />

      {noSessionDialogVisible && (
        <NoSessionDialog
          onResult={handleNoSessionsDialogResult}
          onCancel={setNoSessionDialogVisible}
        />
      )}
    </div>
  );
};

export default Login;
