import "./UserAccess.css";
import AddNewUser from "./addNewUser/AddNewUser";
import { useEffect, useState } from "react";
import {
  getCustomerUiUser,
  deleteCustomerUiUser,
  getCustomerUiRbca,
} from "../../../utils/CustomerUiAPI";
import { ToastContainer, toast } from "react-toast";
import { useTheme } from "../../../ThemeContext";
import Button from "../../common/button/Button";
import DataTableMini from "../../common/table/DataTableMini/DataTableMini";
import DataTable from "../../common/table/DataTable/DataTable";
import CustomMuiTable from "../../common/custom-mui-table/CustomDataGridBackendPagination/Index";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomDialog from "../../common/dialogs/CustomDialog";
import { Table } from "@voicecare.ai/ui-library";

const UserAccess = ({}) => {
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState({});
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState("add");
  const [loading, setLoading] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState(null);
  const [roles, setRoles] = useState([]);
  
  const { themeMode, currentTheme } = useTheme();

  const handleCloseDeleteConfirmation = () => setIsDeleteConfirmationOpen(false);
  const handleOpenDeleteConfirmation = () => setIsDeleteConfirmationOpen(true);

  useEffect(() => {
    const mail = sessionStorage.getItem("mail");
    const sessionId = sessionStorage.getItem("sessionId");
    setLoading(true);
    async function fetchData() {
      getCustomerUiUser(mail, sessionId)
        .then((res) => {
          setLoading(false);
          const flatten = flatRowCreator(res.response);
          console.log(flatten, 'flattenDebugger')
          const sortedFlatten = flatten.sort((a, b) => {
            const dateA = a.created_at ? new Date(a.created_at) : new Date(0);
            const dateB = b.created_at ? new Date(b.created_at) : new Date(0);
            return dateB - dateA;
        });
          setData(sortedFlatten);
        })
        .catch((res) => {
          setLoading(false);
        });
    }
    fetchData();
  }, [mode]);

  useEffect(() => {
    getCustomerUiRbca(
      "",
      sessionStorage.getItem("mail"),
      sessionStorage.getItem("sessionId")
    ).then((res) => {
      if ("supervisor" === "supervisor") {
        setRoles(res?.response?.map((data) => data.role));
      } else {
        setRoles(
          res.response
            .map((data) => data.role)
            .filter((res) => res !== "super_admin")
        );
      }
    });
  }, [])

  const flatRowCreator = (rows) => {
    const flatten = rows.map((row) => ({
      ...row,
      userName: row.first_name + " " + row.last_name,
      action: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton onClick={() => handleEdit(row.email)}>
            <EditIcon sx={{ color: "#A9A9A9" }} />
          </IconButton>
          <IconButton onClick={() => {
            setSelectedUserToDelete(row);
            handleOpenDeleteConfirmation(true)
          }}>
            <DeleteIcon sx={{ color: "#ff4e3a" }} />
          </IconButton>
        </div>
        // <div style={{ display: "flex", justifyContent: "center" }}>
        //   <img
        //     className="add-user-icon"
        //     alt=""
        //     src={
        //       themeMode === "dark"
        //         ? "./icons/edit.svg"
        //         : "./icons/edit-black.svg"
        //     }
        //     onClick={() => handleEdit(row.email)}
        //   />
        //   <img
        //     className="add-user-icon"
        //     alt=""
        //     src={
        //       themeMode === "dark"
        //         ? "./icons/delete.svg"
        //         : "./icons/delete-black.svg"
        //     }
        //     onClick={() => handleDeleteUser(row)}
        //   />
        // </div>
      ),
    }));

    return flatten;
  };

  const columns = [
    { headerName: "User Name", renderName: "userName" },
    { headerName: "Role", renderName: "role" },
    { headerName: "Email", renderName: "email" },
    { headerName: "Actions", renderName: "action" },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const userTableColumns = [
    {
      name: "User Name",
      label: "User Name",
      selector: (row) => row.first_name + " " + row.last_name,
      width: "35vh",
    },
    {
      name: "Role",
      label: "Role",
      selector: (row) => row.role,
      width: "25vh",
    },
    {
      name: "Email",
      label: "Email",
      selector: (row) => row.email,
      width: "35vh",
    },
    {
      name: "Action",
      label: "Actions",
      width: "30vw",
      render: (row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            className="add-user-icon"
            alt=""
            src={
              themeMode === "dark"
                ? "./icons/edit.svg"
                : "./icons/edit-black.svg"
            }
            onClick={() => handleEdit(row.email)}
          />
          <img
            className="add-user-icon"
            alt=""
            src={
              themeMode === "dark"
                ? "./icons/delete.svg"
                : "./icons/delete-black.svg"
            }
            onClick={() => handleDeleteUser(row)}
          />
        </div>
      ),
    },
  ];

  const handleEdit = (email) => {
    setMode("edit");
    setEditData(data.find((val) => val.email === email));
    setVisible(true);
  };

  const notifyMessage = (msg) =>
    toast(msg, {
      backgroundColor: "#f54f3b",
      color: "#ffffff",
      zIndex: 10001,
    });

  const handleDeleteUser = (user) => {
    setMode("delete");
    notifyMessage("Deleting User Details!");
    handleCloseDeleteConfirmation();
    deleteCustomerUiUser(
      user.email,
      sessionStorage.getItem("mail"),
      sessionStorage.getItem("sessionId")
    )
      .then((res) => notifyMessage("Deleted User!"))
      .then(() => setEditData({}));

    setData(data.filter((u) => u.email !== user.email))
  };

  const handleAdd = () => {
    setVisible(true);
    setMode("add");
  };

  return (
    <div>
      {visible && <div className="overlay"></div>}
      {loading ? (
        <img
          className="loader"
          src={
            themeMode === "dark"
              ? "/icons/loader_white.gif"
              : "/icons/loader_black.gif"
          }
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: currentTheme.settingPage.textColor,
            flexGrow: 1,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              label={"Add User"}
              backgroundColor={currentTheme.settingPage.button}
              color="#F5F5F5"
              style={{
                display: "flex",
                margin: ".5rem",
                height: "1.5rem",
                width: "auto",
                backgroundColor: currentTheme.settingPage.button,
                color: "#f5f5f5",
                padding: ".5vh",
                borderRadius: "1vh",
                alignItems: "center",
                marginBottom: "2vh",
                justifyContent: "center",
                padding: "15px",
              }}
              onClick={() => handleAdd()}
              iconSrc={"/icons/add-user.svg"}
            />
            {/* <span
              style={{
                display: "flex",
                margin: "1vh",
                height: "3vh",
                width: "16vh",
                backgroundColor: currentTheme.settingPage.button,
                color: "#f5f5f5",
                padding: ".5vh",
                borderRadius: "1vh",
                alignItems: "center",
                marginBottom: "2vh",
                justifyContent: "center",
              }}
              onClick={() => handleAdd()}
            >
              <img
                src="/icons/add-user.svg"
                width={22}
                style={{ marginRight: "1vh" }}
              />
              <span>Add User</span>
            </span> */}
          </div>
          {data.length > 0 ? (
            // <DataTable
            //   isEditable={false}
            //   headerColor={currentTheme.settingPage.tableHeader}
            //   dataColor={currentTheme.settingPage.search}
            //   headerTextColor={currentTheme.settingPage.textColor}
            //   columns={userTableColumns}
            //   data={data}
            //   itemsPerPageOptions={[5, 10, 20]}
            //   defaultItemsPerPage={10}
            //   height={"60vh"}
            // />
            <Table
              rows={data}
              columns={columns}
              frontendSort
              noCustomizeColumn
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              containerHeight="63vh"
              themeMode={themeMode}
            />
          ) : (
            "No Records Found"
          )}
        </div>
      )}
      <AddNewUser
        editData={editData}
        mode={mode}
        visible={visible}
        setEditData={setEditData}
        setVisible={setVisible}
        notifyMessage={notifyMessage}
        setMode={setMode}
        roles={roles}
      />

      <CustomDialog
        open={isDeleteConfirmationOpen}
        message={`Are you sure you want to delete this user?`}
        onConfirm={() => handleDeleteUser(selectedUserToDelete)}
        onCancel={handleCloseDeleteConfirmation}
      />
      <div style={{ zIndex: "10001" }}>
        <ToastContainer delay={"2000"} />
      </div>
    </div>
  );
};

export default UserAccess;
