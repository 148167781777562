// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-root {
    background-color: #151138;
    flex-direction: row;
    width: -webkit-fill-available;
    height: 4vh;
    color: white;
    padding: 2vh;
    padding-left: 5vh;
  }
  
  .header-left {
    float: left;
    font-weight: 650;
    letter-spacing: 1px;
  }
  
  .header-right {
    float: right;
    display: flex;
  }
  
  .header-user-icon {
    width: 4vh;
    margin-left: 2vh;
  }
  
  .gradient-line {
      border: 0;
      height: 3px;
      background: linear-gradient(90deg, #3e3b56 25%, #fc2b23 90%);
      border-radius: 5px;
    }
    
    `, "",{"version":3,"sources":["webpack://./src/components/header/Header.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB;;EAEA;MACI,SAAS;MACT,WAAW;MACX,4DAA4D;MAC5D,kBAAkB;IACpB","sourcesContent":[".header-root {\r\n    background-color: #151138;\r\n    flex-direction: row;\r\n    width: -webkit-fill-available;\r\n    height: 4vh;\r\n    color: white;\r\n    padding: 2vh;\r\n    padding-left: 5vh;\r\n  }\r\n  \r\n  .header-left {\r\n    float: left;\r\n    font-weight: 650;\r\n    letter-spacing: 1px;\r\n  }\r\n  \r\n  .header-right {\r\n    float: right;\r\n    display: flex;\r\n  }\r\n  \r\n  .header-user-icon {\r\n    width: 4vh;\r\n    margin-left: 2vh;\r\n  }\r\n  \r\n  .gradient-line {\r\n      border: 0;\r\n      height: 3px;\r\n      background: linear-gradient(90deg, #3e3b56 25%, #fc2b23 90%);\r\n      border-radius: 5px;\r\n    }\r\n    \r\n    "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
