"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetSidebarOpen = exports.useIsSideBarOpen = exports.SidebarProvider = void 0;
var react_1 = __importStar(require("react"));
// Create the context with a default value
var SidebarContext = (0, react_1.createContext)(undefined);
// SidebarProvider component to wrap around parts of the app
var SidebarProvider = function (_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)(false), isSidebarOpen = _b[0], setIsSidebarOpen = _b[1];
    return (react_1.default.createElement(SidebarContext.Provider, { value: { isSidebarOpen: isSidebarOpen, setIsSidebarOpen: setIsSidebarOpen } }, children));
};
exports.SidebarProvider = SidebarProvider;
// Hook to access the isSidebarOpen value
var useIsSideBarOpen = function () {
    var context = (0, react_1.useContext)(SidebarContext);
    if (!context) {
        throw new Error('useIsSideBarOpen must be used within a SidebarProvider');
    }
    return context.isSidebarOpen;
};
exports.useIsSideBarOpen = useIsSideBarOpen;
// Hook to access the setIsSidebarOpen function
var useSetSidebarOpen = function () {
    var context = (0, react_1.useContext)(SidebarContext);
    if (!context) {
        throw new Error('useSetSidebarOpen must be used within a SidebarProvider');
    }
    return context.setIsSidebarOpen;
};
exports.useSetSidebarOpen = useSetSidebarOpen;
