/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "./DateRangePicker.css";
import { useTheme } from "../../../ThemeContext";
import { Flex, Typography } from "antd";

const { Text } = Typography;
const format = "MM/DD/YYYY";

const DataRangePicker = ({
  selectedRanges,
  setSelectedRanges,
  selectRandom,
  style,
}) => {
  const { themeMode, currentTheme } = useTheme();
  const [minDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [showOverlay, setShowOverlay] = useState(false);

  const handleDateSelection = (selectedDates) => {
    setSelectedRanges(selectedDates);
    // setOpenOverlay(true);
  };

  const weekDaysShort = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

  useEffect(() => {
    document.documentElement.classList.toggle(
      "dark-mode",
      themeMode === "dark"
    );
  }, [themeMode]);

  return (
    <div className={`${themeMode}-date-picker`}>
      <div
        style={{
          textAlign: "left",
          margin: "1vh",
          display: "flex",
          flexDirection: "row",
          background: currentTheme.callAutomationPage.dateBackground,
          borderRadius: "1vh",
        }}
      >
        <img
          style={{ marginLeft: "1vh" }}
          src={`/icons/calendar${themeMode === "dark" ? "-dark" : ""}.svg`}
          width={"20"}
          alt="Calendar Icon"
        />
        <DatePicker
          className={`${themeMode}-ca-date-picker`}
          inputClass="date-picker-input"
          value={selectedRanges}
          placeholder="Select Dates"
          onChange={handleDateSelection}
          multiple
          style={style}
          sort
          format={format}
          calendarPosition="bottom-left"
          plugins={[
            <DatePanel
              position="bottom"
              markFocused={true}
              className="table-scroll-container"
            />,
          ]}
          range={!selectRandom || undefined}
          minDate={minDate}
          highlightToday={true}
        />
      </div>
    </div>
  );
};

export default DataRangePicker;
