/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { UserInfoCard, SearchInput } from "./../components";
import { Space, Typography } from "antd";
import CustomAudioPlayer from "../components/customAudioPlayer/CustomAudioPlayer";
import { useCallHistoryProvider } from "../context/CallHIstoryContext";
import { useTheme } from "../../../ThemeContext";
import { API_BASE_URL, getCallAUDIO } from "../../../utils/CustomerUiAPI";
import { Box } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import moment from "moment/moment";

export const AudioVisualizerWithTranscription = () => {
  const { currentTheme, themeMode } = useTheme();
  const [searchInput, setSearchInput] = useState("");
  const [csvData, setCsvData] = useState("");
  const [audioPresignedUrl, setAudioPresignedUrl] = useState(null);

  const { callContent } = useCallHistoryProvider();

  const url = callContent && callContent.call_audio_url;
  const { call_transcription_fragment } = callContent || {};

  const startTime =
    callContent?.overall_call_metadata?.before_transfer?.call_start_time;
  const endTime =
    callContent?.overall_call_metadata?.before_transfer?.call_end_time;

  const duration = moment.duration(moment(endTime).diff(moment(startTime)));
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const callDuration = `${minutes}m ${seconds}s`;

  // Calculate call date with time
  const startDate = moment(startTime);
  console.log("zz", startTime, callContent);
  
  let callDate;

  if (startDate.isSame(moment(), "day")) {
    callDate = `Today ${startDate.format("hh:mm A")}`;
  } else if (startDate.isSame(moment().subtract(1, "day"), "day")) {
    callDate = `Yesterday ${startDate.format("hh:mm A")}`;
  } else {
    callDate = `${startDate.format("MM/DD/YYYY : hh:mm A")}`;
  }

  console.log(callDuration, callDate);

  const setPresignedUrl = async () => {
    const resp = await getCallAUDIO(
      url,
      sessionStorage.getItem("mail"),
      sessionStorage.getItem("sessionId")
    );

    if (resp?.data?.response) {
      setAudioPresignedUrl(resp?.data?.response);
    }
  };

  useEffect(() => {
    if (!audioPresignedUrl) setPresignedUrl();
  }, []);

  const { Text } = Typography;

  const finalUrl1 =
    url &&
    `${API_BASE_URL}/get_audio_response?emailId=${sessionStorage.getItem(
      "mail"
    )}&currentSessionId=${sessionStorage.getItem(
      "sessionId"
    )}&audio_url=${url}`;

  const { getSelectedMemberInfo, setRenderType, fetchAudioCall } =
    useCallHistoryProvider();
  const { member_name, member_id } = getSelectedMemberInfo() || {};
  useEffect(() => {}, [searchInput]);
  const convertDateFormat = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [month, day, year] = datePart.split("-");
    const [hours, minutes, seconds] = timePart.split(":");
  
    // Construct a date string in a format that Date.parse can understand (YYYY-MM-DDTHH:MM:SS)
    const formattedDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds.split('.')[0]}`;
  
    const date = new Date(formattedDateString);
  
    if (isNaN(date.getTime())) {
      console.warn("Invalid date:", dateString);
      return "";
    }
  
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    console.log("Formatted Date:", dateString, formattedDate);
  
    return formattedDate;
  };
  

  const convertToCSV = () => {
    const keys = Object.keys(call_transcription_fragment[0]).filter(
      (key) =>
        ![
          "copay_prediction",
          "intent_classification_vertexAI_prediction",
          "sentiment",
          "utterance_id",
        ].includes(key)
    );
    const csvContent =
      keys.join(",") +
      "\n" +
      call_transcription_fragment
        .map((row) => {
          return keys
            .map((key) => {
              let value = row[key];

              // Convert 'time_end' and 'time_start' to desired format
              if (key === "time_end" || key === "time_start") {
                value = convertDateFormat(value);
              }

              // Check if the value is an object and stringify it
              value = typeof value === "object" ? JSON.stringify(value) : value;

              // Encapsulate 'text' field value within double quotes if it contains commas
              return key === "text" && value.includes(",")
                ? `"${value.replace(/"/g, '""')}"`
                : value;
            })
            .join(",");
        })
        .join("\n");
    return csvContent;
  };

  const handleConvertClick = () => {
    const csvContent = convertToCSV();
    setCsvData(csvContent);
  };

  const handleDownload = () => {
    const csvContent = convertToCSV();

    // Create a download link
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Transcription-${member_id}-${member_name}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Space
      direction="vertical"
      size="middle"
      className="call-history__audio-visual"
      style={{
        color: themeMode === "dark" ? "white" : "black",
        background: currentTheme.callhistory.searchBackground,
        borderRadius: "1vh",
        width: "-webkit-fill-available",
      }}
    >
      <UserInfoCard
        setRenderType={setRenderType}
        fetchAudioCall={fetchAudioCall}
      />
      <span
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <SearchInput
            className={"audio-transcript-search"}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            width={"40vw"}
            placeholder={"Search Transcript"}
          />
          <Box style={{ paddingLeft: "1vh" }}>
            <img
              src={
                themeMode === "dark"
                  ? "/icons/dark-theme/download.svg"
                  : "/icons/light-theme/download.svg"
              }
              onClick={handleDownload}
              height={"35vh"}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <CalendarTodayIcon style={{ marginRight: "0.5rem" }} />
          <Text
            style={{
              color: currentTheme.callhistory.text,
              fontSize: 14,
              paddingRight: "0.5rem",
              marginRight: "0.5rem",
              borderRight: `1px solid ${currentTheme.callhistory.text}`,
            }}
            ellipsis
            type="secondary"
          >
            {callDate}
          </Text>
          <AccessTimeFilledIcon style={{ marginRight: "0.5rem" }} />
          <Text
            style={{ color: currentTheme.callhistory.text, fontSize: 14 }}
            ellipsis
            type="secondary"
          >
            {callDuration}
          </Text>
        </Box>
      </span>
      {audioPresignedUrl && (
        <CustomAudioPlayer src={audioPresignedUrl} searchInput={searchInput} />
      )}
    </Space>
  );
};
