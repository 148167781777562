/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./CalendarFilter.css"; // Import custom CSS for styling
import { useTheme } from "../../../../../ThemeContext";

const CalendarFilter = ({
  onDateRangeChange,
  setAreDatesValid,
  isDateRangeSelected,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { themeMode } = useTheme();

  const handleStartDateChange = (event) => {
    const date = event.target.value;
    setStartDate(date);
    isDateRangeValid(date, endDate)
    // if (date && endDate && new Date(date) > new Date(endDate)) {
    //   setEndDate(""); // Reset endDate if startDate is after endDate
    // }
    onDateRangeChange(date, endDate);
  };

  const handleEndDateChange = (event) => {
    const date = event.target.value;
    setEndDate(date);
    isDateRangeValid(startDate, date)
    // if (date && startDate && new Date(date) < new Date(startDate)) {
    //   setStartDate(""); // Reset startDate if endDate is before startDate
    // }
    onDateRangeChange(startDate, date);
  };

  const isDateRangeValid = (start, end) => {
    if (!start || !end) {
      setAreDatesValid(true); // Valid if either date is not set
      return;
    }
    const startIsValid = new Date(start) <= new Date(end); // Start date must be before or equal to end date
    const endIsValid = new Date(end) >= new Date(start); // End date must be after or equal to start date
    const areDatesValid = startIsValid && endIsValid;
    console.log(areDatesValid, "areDatesValid");
    setAreDatesValid(areDatesValid); // Set validity state
  };

  return (
    <div className="calendar-filter">
      <div className="calendar-input-wrapper">
        <input
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          placeholder="Select Start Date"
          className={`calendar-input-${themeMode}`}
        />
        <span className="calendar-icon">
          <img
            style={{ marginLeft: "1vh" }}
            src={
              themeMode === "dark"
                ? "/icons/calendar-dark.svg"
                : "/icons/calendar.svg"
            }
            width={"20"}
          />
        </span>{" "}
        {/* Custom icon */}
      </div>
      <div className="calendar-input-wrapper">
        <input
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          placeholder="Select End Date"
          className={`calendar-input-${themeMode}`}
        />
        <span className="calendar-icon">
          <img
            style={{ marginLeft: "1vh" }}
            src={
              themeMode === "dark"
                ? "/icons/calendar-dark.svg"
                : "/icons/calendar.svg"
            }
            width={"20"}
          />
        </span>{" "}
        {/* Custom icon */}
      </div>
    </div>
  );
};

export default CalendarFilter;
