import React, { useEffect, useState } from "react";
import moment from "moment";
import OutComeCount from "./components/graphs/OutComeCount";
import InsuranceCount from "./components/graphs/InsuranceCount";
import "./AdvancedAnalytics.css";
import { useTheme } from "../../ThemeContext";
import AverageTalkTime from "./components/graphs/AverageTalkTime";
import CalendarFilter from "./components/common/calendarFilter/CalendarFilter";
import OutcomeReasonCount from "./components/graphs/OucomeReaonsCount";
import TotalCallsMade from "./components/graphs/TotalCallsMade";
import NoData from "./components/common/noData/NoData";
import data from "./data.json";
import { parseISO, isWithinInterval, isValid } from "date-fns";
import CallHandledCount from "./components/graphs/CallHandledCount";
import { Typography } from "@mui/material";
import { getAnalyticData } from "../../utils/CustomerUiAPI";
import LogoLoader from "../common/loaders/gif-logo-loader";

const AdvancedAnalytics = () => {
  const { currentTheme } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState({
    outcomeCountPieChartData: [],
    averageTalkTimeLineChartData: { data: [] },
    callHandledCountsBarChartData: [],
    insuranceCountPieChartData: [],
    totalCallsMadeData: [],
    outcomeReasonCountsData: [],
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [areDatesValid, setAreDatesValid] = useState(true);

  const filterDataByDate = (data, startDate, endDate) => {
    if (!startDate || !endDate)
      return {
        outcomeCountPieChartData: [],
        averageTalkTimeLineChartData: { data: [] },
        callHandledCountsBarChartData: [],
        insuranceCountPieChartData: [],
        totalCallsMadeData: [],
        outcomeReasonCountsData: [],
      };

    const parsedStartDate = parseISO(startDate);
    const parsedEndDate = parseISO(endDate);

    if (!isValid(parsedStartDate) || !isValid(parsedEndDate)) {
      console.error("Invalid dates:", { startDate, endDate });
      return {
        outcomeCountPieChartData: [],
        averageTalkTimeLineChartData: { data: [] },
        callHandledCountsBarChartData: [],
        insuranceCountPieChartData: [],
        totalCallsMadeData: [],
        outcomeReasonCountsData: [],
      };
    }

    return {
      outcomeCountPieChartData: (data.outcomeCountPieChartData || []).filter(
        (item) => {
          const date = parseISO(item.created_date);
          return (
            isValid(date) &&
            isWithinInterval(date, {
              start: parsedStartDate,
              end: parsedEndDate,
            })
          );
        }
      ),
      // callHandledCountsBarChartData: data.callHandledCountsBarChartData ,
      callHandledCountsBarChartData: (
        data.callHandledCountsBarChartData || []
      ).filter((item) => {
        const date = parseISO(item.created_date);
        return (
          isValid(date) &&
          isWithinInterval(date, { start: parsedStartDate, end: parsedEndDate })
        );
      }),
      insuranceCountPieChartData: (
        data.insuranceCountPieChartData || []
      ).filter((item) => {
        const date = parseISO(item.created_date);
        return (
          isValid(date) &&
          isWithinInterval(date, { start: parsedStartDate, end: parsedEndDate })
        );
      }),
      totalCallsMadeData: (data.totalCallsMadeData || []).filter((item) => {
        const date = parseISO(item.created_date);
        return (
          isValid(date) &&
          isWithinInterval(date, { start: parsedStartDate, end: parsedEndDate })
        );
      }),
      outcomeReasonCountsData: (data.outcomeReasonCountsData || []).filter(
        (item) => {
          const date = parseISO(item.created_date);
          return (
            isValid(date) &&
            isWithinInterval(date, {
              start: parsedStartDate,
              end: parsedEndDate,
            })
          );
        }
      ),
    };
  };

  // const handleDateRangeChange = (startDate, endDate) => {
  //   setDateRange({ startDate, endDate });
  //   setFilteredData(filterDataByDate(data, startDate, endDate));
  // };

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });

    // Check if both dates are available before making the API call
    if (startDate && endDate) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const response = await getAnalyticData(startDate, endDate); // Fetch data from API
          setFilteredData({
            outcomeCountPieChartData: response.query_outcome_counts.data.map(
              (item) => ({
                fullName: item.outcome,
                value: item.count,
                color: "", // Define the color if available or set it dynamically
              })
            ),
            averageTalkTimeLineChartData: {
              data: response.query_average_talk_time.data.map((item) => ({
                x: item.month,
                y: item.average_talk_time,
              })),
            },
            callHandledCountsBarChartData: response.query_call_handled_counts.data.reduce((acc, item) => {
              // Find if the entry for the created_date already exists in the accumulator
              const existingEntry = acc.find((entry) => entry.created_date === item.date);
          
              if (existingEntry) {
                // If the entry exists for this date, update the appropriate handler type
                existingEntry[item.caller_handler_type] = item.count;
              } else {
                // If no entry exists for this date, create a new one
                acc.push({
                  created_date: item.date,  // Keep the original date format
                  date: moment(item.date).format('MMM YYYY'),  // Format the date to "Feb 2024"
                  Bot: item.caller_handler_type === 'Bot' ? item.count : 0,
                  Bot_Human: item.caller_handler_type === 'Bot_Human' ? item.count : 0,
                  Human_Only: item.caller_handler_type === 'Human_Only' ? item.count : 0,
                });
              }
          
              return acc;
            }, [])
            // After reducing, ensure all entries have the Bot, Bot_Human, and Human_Only keys
            .map((entry) => ({
              ...entry,
              Bot: entry.Bot || 0,
              Bot_Human: entry.Bot_Human || 0,
              Human_Only: entry.Human_Only || 0,
            })),
            insuranceCountPieChartData:
              response.query_insurance_counts.data.map((item) => ({
                fullName: item.insurance_type,
                value: item.count,
                color: "", // Define color if available or set dynamically
              })),
            totalCallsMadeData: response.query_total_calls_per_day.data.map(
              (item) => ({
                month: new Date(item.date).toLocaleDateString("en-US", {
                  month: "short",
                  year: "numeric",
                }),
                count: item.total_calls,
              })
            ),
            outcomeReasonCountsData:
              response.query_outcome_reason_counts.data.map((item) => ({
                reason: item.outcome_reason,
                count: item.count,
              })),
          });
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching analytics data:", error);
          setIsLoading(false);
        }
      };

      fetchData();
    }
  };

  const isDateRangeSelected = dateRange.startDate && dateRange.endDate;

  console.log(
    filteredData.callHandledCountsBarChartData,
    "callHandledCountsBarChartData real"
  );

  console.log(
    data.callHandledCountsBarChartData,
    "callHandledCountsBarChartData dummy"
  );
  return (
    <div
      className="analitics-root"
      style={{
        background: currentTheme.homePage.mainBackground,
        color: currentTheme.homePage.textColor,
        padding: "2vh",
        height: "100%",
      }}
    >
      <div className="piechart-wrapper">
        <CalendarFilter
          onDateRangeChange={handleDateRangeChange}
          setAreDatesValid={setAreDatesValid}
          isDateRangeSelected={isDateRangeSelected}
        />
        {areDatesValid ? (
          isLoading ? (
            <LogoLoader />
          ) : (
            <div className="piechart-container">
              {isDateRangeSelected ? (
                <>
                  {!filteredData.outcomeCountPieChartData.length ? (
                    <NoData />
                  ) : (
                    <OutComeCount
                      data={filteredData.outcomeCountPieChartData}
                      title={"Outcome Counts"}
                    />
                  )}
                  {!filteredData.outcomeCountPieChartData.length ? (
                    <NoData />
                  ) : (
                    <AverageTalkTime
                      data={data.averageTalkTimeLineChartData}
                      title={"Average Talk Time"}
                    />
                  )}
                  {!filteredData.callHandledCountsBarChartData.length ? (
                    <NoData />
                  ) : (
                    <CallHandledCount
                      data={filteredData.callHandledCountsBarChartData}
                      title="Call Handled Counts"
                    />
                  )}
                  {!filteredData.insuranceCountPieChartData.length ? (
                    <NoData />
                  ) : (
                    <InsuranceCount
                      data={filteredData.insuranceCountPieChartData}
                      title={"Insurance Counts"}
                    />
                  )}
                  {!filteredData.totalCallsMadeData.length ? (
                    <NoData />
                  ) : (
                    <TotalCallsMade
                      data={filteredData.totalCallsMadeData}
                      title="Total Number Of Calls Made"
                    />
                  )}
                  {!filteredData.outcomeReasonCountsData.length ? (
                    <NoData />
                  ) : (
                    <OutcomeReasonCount
                      data={filteredData.outcomeReasonCountsData}
                      title="Outcome Reason Counts"
                    />
                  )}
                </>
              ) : (
                <NoData message="Please select a date range to view data." />
              )}
            </div>
          )
        ) : (
          <Typography
            variant="h6"
            align="center"
            style={{
              color: "red",
              position: "absolute",
              top: "30%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Invalid dates: Start date must be before the end date.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default AdvancedAnalytics;
