import "./UserRoles.css";
import Button from "../../common/button/Button";
import { useEffect, useState } from "react";
import {
  getAllCustomerUiRbca,
  deleteUserRole,
} from "../../../utils/CustomerUiAPI";
import { ToastContainer, toast } from "react-toast";
import { UserRole } from "./addUserRole/UserRole";

import { useTheme } from "../../../ThemeContext";
import DataTableMini from "../../common/table/DataTableMini/DataTableMini";
import DataTable from "../../common/table/DataTable/DataTable";
import CustomMuiTable from "../../common/custom-mui-table/CustomDataGridBackendPagination/Index";
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Table, CustomDialog } from "@voicecare.ai/ui-library";

const UserRoles = () => {
  const { themeMode, currentTheme } = useTheme();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState({});
  const [mode, setMode] = useState("add");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleCloseDeleteConfirmation = () => setIsDeleteConfirmationOpen(false);
  const handleOpenDeleteConfirmation = () => setIsDeleteConfirmationOpen(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      getAllCustomerUiRbca(
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId")
      )
        .then((res) => {
          setLoading(false);
          const { response } = res;
          const flatten = flatRowCreator(response);
          setData(flatten);
        })
        .catch((res) => {
          setLoading(false);
          console.log(res);
        });
    }
    fetchData();
  }, [mode]);

  const convertStringArrayToArray = (data) => {
    // Remove the first and last single quotation marks
    const stringWithoutQuotes = data.substring(1, data.length - 1);

    // Split the modified string into an array
    const arrayOfPermissions = stringWithoutQuotes
      .split(", ")
      .map((permission) => JSON.parse(`"${permission.replace(/'/g, "")}"`));
    // const resultArray = arrayOfPermissions[0].split(',');

    // Return the array
    return arrayOfPermissions;
  };

  const flatRowCreator = (rows) => {
    const flatten = rows.map((row) => ({
      ...rows,
      role: row.role,
      permissions: (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ float: "left" }}>
            {convertStringArrayToArray(row.permissions).length}
          </div>
        </div>
      ),
      action: (
        <div style={{ display: "flex", justifyContent: "center" }}>
        <IconButton onClick={() => handleEdit(row)}>
          <EditIcon sx={{ color: '#A9A9A9' }} />
        </IconButton>
        <IconButton onClick={() => {
          setSelectedUserToDelete(row);
          handleOpenDeleteConfirmation(true);          
          }}>
          <DeleteIcon sx={{color:'#ff4e3a'}} />
        </IconButton>
      </div>
        // <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        //   <div style={{ float: "right" }}>
        //     <img
        //       className="add-user-icon"
        //       alt=""
        //       src={
        //         themeMode === "dark"
        //           ? "./icons/edit.svg"
        //           : "./icons/edit-black.svg"
        //       }
        //       onClick={() => handleEdit(row)}
        //     />
        //     <img
        //       className="add-user-icon"
        //       alt=""
        //       src={
        //         themeMode === "dark"
        //           ? "./icons/delete.svg"
        //           : "./icons/delete-black.svg"
        //       }
        //       onClick={() => handleDeleteUser(row.role)}
        //     />
        //   </div>
        // </div>
      ),
    }));

    return flatten;
  };

  const columns = [
    { headerName: "Role", renderName: "role" },
    { headerName: "Permissions", renderName: "permissions" },
    { headerName: "Actions", renderName: "action" },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columnss = [
    {
      label: "Role",
      name: "Role",
      width: "35vw",
      selector: (row) => row.role,
    },
    {
      label: "Permissions",
      name: "Action",
      width: "55vw",
      render: (row) => (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ float: "left" }}>
            {convertStringArrayToArray(row.permissions).length}
          </div>
        </div>
      ),
    },
    {
      label: "Actions",
      name: "Action",
      width: "35vw",
      render: (row) => (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ float: "right" }}>
            <img
              className="add-user-icon"
              alt=""
              src={
                themeMode === "dark"
                  ? "./icons/edit.svg"
                  : "./icons/edit-black.svg"
              }
              onClick={() => handleEdit(row)}
            />
            <img
              className="add-user-icon"
              alt=""
              src={
                themeMode === "dark"
                  ? "./icons/delete.svg"
                  : "./icons/delete-black.svg"
              }
              onClick={() => handleDeleteUser(row.role)}
            />
          </div>
        </div>
      ),
    },
  ];

  const notifyMessage = (msg) =>
    toast.info(msg, {
      backgroundColor: "#f54f3b",
      color: "#ffffff",
      autoClose: 100,
      zIndex: 10001,
    });

  const handleEdit = (row) => {
    setVisible(true);
    const data = {
      role: row.role,
      permissions: convertStringArrayToArray(row?.permissions),
    };
    setEditData(data);
    setMode("edit");
  };

  const handleAdd = () => {
    setVisible(true);
    setMode("add");
  };

  const handleDeleteUser = (role) => {
    const data = {
      role: role,
    };
    notifyMessage("Deleting Role!");
    handleCloseDeleteConfirmation();
    deleteUserRole(
      data,
      sessionStorage.getItem("mail"),
      sessionStorage.getItem("sessionId")
    )
      .then((res) => notifyMessage("Deleted Role!"))
      .then(() => setEditData([]));
  };

  return (
    <div>
      {visible && <div className="overlay"></div>}
      {loading ? (
        <img
          className="loader"
          src={
            themeMode === "dark"
              ? "/icons/loader_white.gif"
              : "/icons/loader_black.gif"
          }
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: currentTheme.settingPage.textColor,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              label={"Add Role"}
              backgroundColor={currentTheme.settingPage.button}
              color="#F5F5F5"
              style={{
                display: "flex",
                margin: ".5rem",
                height: "1.5rem",
                width: "auto",
                backgroundColor: currentTheme.settingPage.button,
                color: "#f5f5f5",
                padding: ".5vh",
                borderRadius: "1vh",
                alignItems: "center",
                marginBottom: "2vh",
                justifyContent: "center",
                padding: "15px",
              }}
              iconSrc={"/icons/add-user.svg"}
              onClick={() => handleAdd()}
            />
            {/* <span
              style={{
                display: "flex",
                margin: "1vh",
                height: "2.5vh",
                width: "16vh",
                backgroundColor: currentTheme.settingPage.button,
                color: "#f5f5f5",
                padding: ".5vh",
                borderRadius: "1vh",
                alignItems: "center",
                marginBottom: "2vh",
                justifyContent: "center",
              }}
              onClick={() => handleAdd()}
            >
              <img
                src="/icons/add-user.svg"
                width={22}
                style={{ marginRight: "1vh" }}
              />
              <span>Add Role</span>
            </span> */}
          </div>
          {data.length > 0 ? (
            // <DataTable
            //   isEditable={false}
            //   headerColor={currentTheme.settingPage.tableHeader}
            //   dataColor={currentTheme.settingPage.search}
            //   headerTextColor={currentTheme.settingPage.textColor}
            //   columns={columns}
            //   data={data}
            //   itemsPerPageOptions={[5, 10, 20]}
            //   defaultItemsPerPage={10}
            //   height={"60vh"}
            // />
            <Table
              rows={data}
              columns={columns}
              frontendSort
              noCustomizeColumn
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              containerHeight="65vh"
              themeMode={themeMode}
              // paginationMargin="31.6vh"
            />
          ) : (
            "No Records Found"
          )}
        </div>
      )}
      <UserRole
        visible={visible}
        editData={editData}
        setVisible={setVisible}
        setEditData={setEditData}
        mode={mode}
        notifyMessage={notifyMessage}
        setMode={setMode}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        message={`Are you sure you want to delete this role?`}
        onConfirm={() => handleDeleteUser(selectedUserToDelete)}
        onCancel={handleCloseDeleteConfirmation}
        themeMode={themeMode}
        currentTheme={currentTheme}
      />
      <div style={{ zIndex: "10001" }}>
        <ToastContainer delay={"2000"} />
      </div>
    </div>
  );
};

export default UserRoles;