"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.orange4 = exports.orange3 = exports.orange2 = exports.orange1 = exports.blue5 = exports.blue4 = exports.blue3 = exports.blue2 = exports.blue1 = exports.grey11 = exports.grey9 = exports.grey8 = exports.grey7 = exports.grey10 = exports.grey6 = exports.grey5 = exports.grey4 = exports.grey3 = exports.grey2 = exports.grey1 = exports.black = exports.white = void 0;
// ############### WHITEs ############### \\
exports.white = '#FFFFFF';
// ############### BLACKs ############### \\
exports.black = '#000000';
// ############### GREYs ############### \\
exports.grey1 = "#3d3b56"; // sidebar
exports.grey2 = "#3e3b57"; // table pagination
exports.grey3 = "#7f7e8b"; // inactive time
exports.grey4 = "#6c6b7f"; // table hover
exports.grey5 = "#cac9d4"; // scroll bar
exports.grey6 = "#3f3d52"; // table status
exports.grey10 = "#3e3b56"; // disabled color
exports.grey7 = "##f2f1f5"; // main bacground light theme
exports.grey8 = "#f7f7f7"; // table background light theme
exports.grey9 = "#cfccda"; // theme toggle background light theme
exports.grey11 = "#e3e2e5"; // disabled color
// ############### BLUEs ############### \\
exports.blue1 = "#151138"; // header background
exports.blue2 = "#04002b"; // main background
exports.blue3 = "#252144"; // text field background
exports.blue4 = "#2f2d4c"; // table header
exports.blue5 = "#242143"; // table row background
// ############### ORANGEs ############### \\
exports.orange1 = "#ff4e3a"; // button background
exports.orange2 = "#cc3c2d"; // Graph 1
exports.orange3 = "#b33327"; // Graph 2
exports.orange4 = "#582b3e"; // Graph 3
