"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanNumericString = exports.toHoursMinutes = exports.toTotalMinutes = exports.truncateToTwoDecimalPlaces = exports.parseToIntOrDefault = exports.amountFormatter = void 0;
/**
 * Formats a numeric value to a string with commas as thousands separators
 * and truncates to two decimal places.
 * @param {number} value - The numeric value to format.
 * @returns {string} The formatted string with commas and two decimal places.
 * If the input is not a valid finite number, returns an empty string.
 */
var amountFormatter = function (value) {
    var _a;
    if (typeof value !== "number" || !isFinite(value)) {
        return "";
    }
    var _b = value.toString().split("."), whole = _b[0], decimal = _b[1];
    var truncatedDecimal = (_a = decimal === null || decimal === void 0 ? void 0 : decimal.substring(0, 2)) !== null && _a !== void 0 ? _a : "00";
    var withCommas = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return withCommas + "." + truncatedDecimal;
};
exports.amountFormatter = amountFormatter;
/**
 * Parses a value to an integer. If parsing fails, returns a default value.
 * @param {string | number} value - The value to parse.
 * @param {number} [defaultValue=0] - The default value to return if parsing fails.
 * @returns {number} The parsed integer or the default value.
 */
var parseToIntOrDefault = function (value, defaultValue) {
    if (defaultValue === void 0) { defaultValue = 0; }
    var parsed = parseInt(value, 10);
    return isNaN(parsed) ? defaultValue : parsed;
};
exports.parseToIntOrDefault = parseToIntOrDefault;
/**
 * Parses a value to a floating-point number. If parsing fails, returns a default value.
 * @param {string | number} value - The value to parse.
 * @param {number} [defaultValue=0] - The default value to return if parsing fails.
 * @returns {number} The parsed floating-point number or the default value.
 * @private
 */
var parseFloatOrDefault = function (value, defaultValue) {
    if (defaultValue === void 0) { defaultValue = 0; }
    var parsed = parseFloat(value);
    return isNaN(parsed) ? defaultValue : parsed;
};
/**
 * Truncates a numeric value to two decimal places.
 * @param {number | string} value - The value to truncate.
 * @param {number} [defaultValue=0] - The default value to return if the input is invalid.
 * @returns {number} The truncated number.
 */
var truncateToTwoDecimalPlaces = function (value, defaultValue) {
    if (defaultValue === void 0) { defaultValue = 0; }
    var num = parseFloatOrDefault(value, defaultValue);
    var numStr = num.toString();
    var index = numStr.indexOf(".");
    return index === -1 ? num : Number(numStr.slice(0, index + 3));
};
exports.truncateToTwoDecimalPlaces = truncateToTwoDecimalPlaces;
/**
 * Converts hours and minutes to total minutes.
 * @param {number} hours - The number of hours.
 * @param {number} minutes - The number of minutes.
 * @returns {number} The total minutes.
 */
var toTotalMinutes = function (hours, minutes) {
    var convertedMinutes = hours * 60;
    var totalMinutes = convertedMinutes + minutes;
    return totalMinutes;
};
exports.toTotalMinutes = toTotalMinutes;
/**
 * Converts total minutes to hours and minutes.
 * @param {number} totalMinutes - The total number of minutes.
 * @returns {{ hours: number, minutes: number }} An object containing hours and minutes.
 */
var toHoursMinutes = function (totalMinutes) {
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;
    return { hours: hours, minutes: minutes };
};
exports.toHoursMinutes = toHoursMinutes;
/**
 * Cleans a numeric string by removing all non-numeric characters except the decimal point.
 * @param {string} str - The string to clean.
 * @returns {number} The cleaned number as a floating-point number.
 */
var cleanNumericString = function (str) { return parseFloat(str.replace(/[^\d.]/g, '')); };
exports.cleanNumericString = cleanNumericString;
