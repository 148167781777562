import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { UserList, AudioVisualizerWithTranscription } from "./widgets";
import "./CallHistory.css";
import { CallSummaryInfo } from "./widgets/CallSummary";
import {
  useCallHistoryProvider,
  CallHistoryProvider,
} from "./context/CallHIstoryContext";
import { useTheme } from "../../ThemeContext";
import { useParams } from "react-router-dom";
import { Flex, Typography, Spin } from "antd";
import Button from "../common/button/Button";

const activeMenuButtonStyle = {
  display: "flex",
  padding: ".5rem",
  paddingRight: "auto",
  paddingLeft: "auto",
  backgroundColor: "#ff4e3a",
  color: "#ffffff",
  borderRadius: "1vh",
  marginBottom: "1vh",
  margin: "1vh",
  width: "22vh",
  cursor: "pointer",
  justifyContent: "center",
};


export const CallHistory = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const { currentTheme, themeMode } = useTheme();
  const [loading, setLoading] = useState(false);
  const [selctedMenu, setSelectedMenu] = useState("call-transcript");

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPageState, setPrevPageState] = useState([]);

  const darkInactiveMenuButtonStyle = {
    ...activeMenuButtonStyle,
    backgroundColor: "#3e3c57",
    color: currentTheme.homePage.textColor
  };

  const lightInactiveMenuButtonStyle = {
    ...activeMenuButtonStyle,
    backgroundColor: "#e3e2e5",
    color: currentTheme.homePage.textColor
  };

  const [viewMore, setViewMore] = useState(false);

  const params = useParams();

  const batchId = params?.batchId ?? "";

  if(!batchId) {
    sessionStorage.removeItem('batchId')
  }

  const {
    callList,
    fetchPatientCallData,
    fetchPatientCallDataOld,
    getUniquePatientData,
    callContent,
    renderType,
    setRenderType,
  } = useCallHistoryProvider();

  useEffect(() => {
    if (callContent?.call_id) {
      setRenderType("callDetails");
    } else {
      setRenderType("callList");
    }
  }, [callContent]);

  useEffect(() => {
    if (callList.length === 0) {
      getUniquePatientData(
        "",
        batchId,
        // sessionStorage.getItem("batchId"),
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId")
      );

      // fetchPatientCallData(
      //   sessionStorage.getItem("batchId"),
      //   setLoading,
      //   "1",
      //   "30"
      // );

      // fetchPatientCallDataOld(
      //   "",
      //   sessionStorage.getItem("batchId"),
      //   setLoading
      // );
    }
  }, [batchId]); // Empty dependency array to run only once on mount

  const screenHeight = window.innerHeight;
  let height = "79.5vh";

  if (screenHeight >= 1080) height = "82vh";

  return (
    <div
      style={{
        // padding: "2vh",
        width: "-webkit-fill-available",
        // margin: "auto",
        height: "86vh",
        backgroundColor: currentTheme.callhistory.mainBackground,
      }}
      className="call-history"
    >
      {loading ? (
        <img
          className="loader"
          src={
            themeMode === "dark"
              ? "/icons/loader_white.gif"
              : "/icons/loader_black.gif"
          }
        />
      ) : (
        <Row style={{ width: "101%" }}>
          {renderType === "callList" && (
            <Col hidden={viewMore} style={{ height: "85vh", flex: "0 0 25vw" }}>
              <div className="call-history-user-list-wrapper">
                <UserList
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                  batchId={batchId}
                  setRenderType={setRenderType}
                  prevPageState={prevPageState}
                  setPrevPageState={setPrevPageState}
                  totalRows={totalRows}
                  setTotalRows={setTotalRows}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </Col>
          )}
          {renderType === "callDetails" && (
            <div className="details-container">
              <div style={{ display: 'flex', gap: 2 }} >
                <Button
                  label={"Call Details with Transcript"}
                  style={
                    selctedMenu === "call-transcript"
                      ? activeMenuButtonStyle
                      : themeMode === "dark"
                      ? darkInactiveMenuButtonStyle
                      : lightInactiveMenuButtonStyle
                  }
                  onClick={() => setSelectedMenu("call-transcript")}
                />
                <Button
                  label={"Call Summary"}
                  style={
                    selctedMenu === "call-whitesheet"
                      ? activeMenuButtonStyle
                      : themeMode === "dark"
                        ? darkInactiveMenuButtonStyle
                        : lightInactiveMenuButtonStyle
                  }
                  onClick={() => setSelectedMenu("call-whitesheet")}
                />
              </div>
              {selctedMenu === "call-transcript" && (
                <Col
                  hidden={viewMore}
                  style={{ height: "58vh", flex: "0 0 38vw", marginRight: "2vh", marginTop: "2vh" }}
                >
                  <div
                    className={`${themeMode}-call-history-audio-visualizer-wrapper`}
                  >
                    <AudioVisualizerWithTranscription />
                  </div>
                </Col>
              )}
              {selctedMenu === "call-whitesheet" && (
                <Col
                  style={{
                    height: "65vh",
                    flex: !viewMore ? "0 0 40vw" : "0 0 92vw",
                  }}
                >
                  <div className="call-history-call-summary-wrapper">
                    <CallSummaryInfo
                      viewMore={viewMore}
                      setViewMore={setViewMore}
                    />
                  </div>
                </Col>
              )}
            </div>
          )}
        </Row>
      )}
    </div>
  );
};

export default () => (
  <CallHistoryProvider>
    <CallHistory />
  </CallHistoryProvider>
);
