import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "../../../../ThemeContext";

const BarChart = ({ data, title }) => {
  const { themeMode } = useTheme();
  // Extract keys dynamically from data
  const keys = Object.keys(data[0]).filter(
    (key) => key !== "month" && key !== "created_date"
  );

  const colors = ["#ff4e3a", "#b2b2b2", "#e64533", "#cc3c2d"]; // Adjust or add more colors as needed

  return (
    <div
      style={{
        height: "450px",
        borderRadius: "1rem",
        position: "relative", // Ensure title is positioned correctly
      }}
    >
      <h2
        style={{
          color: themeMode === "dark" ? "#ffffff" : "#000000",
          textAlign: "left",
          marginBottom: "20px",
        }}
      >
        {title}
      </h2>
      <ResponsiveBar
        data={data}
        keys={keys} // Dynamically use all keys except "month"
        indexBy="month" // Key that represents the categories in the data
        margin={{ top: 20, right: 20, bottom: 180, left: 80 }} // Adjusted margins for labels
        padding={0.3}
        colors={({ id }) => colors[keys.indexOf(id) % colors.length]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 45, // Rotate labels to fit better
          legend: "Month",
          legendPosition: "middle",
          legendOffset: 60, // Move the legend label below the chart
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Count",
          legendPosition: "middle",
          legendOffset: -60, // Move the legend label to the left of the chart
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [[themeMode === "dark" ? "brighter" : 'darker', 20]] }}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: themeMode === "dark" ? "#ffffff" : "#000000",
              },
            },
            legend: {
              text: {
                fill: themeMode === "dark" ? "#ffffff" : "#000000",
              },
            },
          },
          tooltip: {
            container: {
              background: themeMode === "dark" ? "#242344" : "#e3e2e5",
              color: themeMode === "dark" ? "#ffffff" : "#000000",
            },
          },
        }}
        animate={true}
        motionConfig="wobbly"
        groupMode="grouped"
      />
    </div>
  );
};

const TotalCallsMade = ({ data, title }) => {
  const { themeMode } = useTheme();
  return (
    <div
      style={{
        height: "450px",
        backgroundColor: themeMode === "dark" ? "#242344" : "#e3e2e5",
        padding: "20px",
        borderRadius: "1rem",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
        position: "relative", // Ensure title is positioned correctly
      }}
    >
      <BarChart data={data} title={title} />
    </div>
  );
};

export default TotalCallsMade;
