import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "../../../../ThemeContext"; // Assuming you're using a ThemeContext similar to PieChart

const LineChart = ({ data, title }) => {
  const themeShades = ["#e64533", "#cc3c2d", "#b33327", "#992a20", "#80211a"];
  const { themeMode } = useTheme();

  return (
    <div
      style={{
        height: "450px",
        backgroundColor: themeMode === "dark" ? "#242344" : "#e3e2e5",
        padding: "20px",
        borderRadius: "1rem",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
        position: "relative", // Ensure title is positioned correctly
      }}
    >
      <h3
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          margin: 0,
          color: themeMode === "dark" ? "#ffffff" : "#000000", // Title color based on theme
          zIndex: 10,
        }}
      >
        {title}
      </h3>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 50, bottom: 100, left: 50 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "", // Removed or set to an empty string to hide the x-axis legend
          tickValues: [], // Hide all tick labels
          tickColor: themeMode === "dark" ? "#ffffff" : "#000000",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Value",
          legendOffset: -40,
          legendPosition: "middle",
          tickColor: themeMode === "dark" ? "#ffffff" : "#000000",
        }}
        enableGridX={false}
        enableGridY={false}
        colors={themeShades}
        lineWidth={3}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        enableArea={true}
        areaOpacity={0.15}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: themeMode === "dark" ? "#ffffff" : "#000000",
                fontSize: 12,
              },
            },
            legend: {
              text: {
                fill: themeMode === "dark" ? "#ffffff" : "#000000",
                fontSize: 14,
              },
            },
          },
          grid: {
            line: {
              stroke: "#555555",
              strokeWidth: 1,
            },
          },
          tooltip: {
            container: {
              background: themeMode === "dark" ? "#242344" : "#e3e2e5",
              color: themeMode === "dark" ? "#ffffff" : "#000000",
              fontSize: "13px",
              borderRadius: "0.5rem",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
            },
          },
          legends: {
            text: {
              fill: themeMode === "dark" ? "#ffffff" : "#000000",
            },
          },
        }}
        legends={[
          {
            anchor: "top-right",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: -45,
            itemsSpacing: 15,
            itemDirection: "left-to-right",
            itemWidth: 150,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 14,
            symbolShape: "circle",
            symbolBorderColor: themeMode === "dark" ? "#ffffff" : "#000000",
            textColor: themeMode === "dark" ? "#ffffff" : "#000000",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default LineChart;
