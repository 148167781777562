// src/components/GoogleLoginButton.js
import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import "./GoogleLoginButton.css"; // Import the CSS file

const GoogleLoginButton = ({ onLoginSuccess, onLoginFailure }) => {
  const login = useGoogleLogin({
    scope: "email",
    onSuccess: async (response) => {
      try {
        // Fetch the user's email using the access token
        const res = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        const userInfo = await res.json();
        const email = userInfo.email;

        // Call the provided onLoginSuccess callback with the email and response
        onLoginSuccess({ email, ...response });
      } catch (error) {
        // Handle errors here
        onLoginFailure(error);
      }
    },
    onError: onLoginFailure,
  });

  return (
    <>
      <img
        src="/icons/google.png"
        alt="line"
        style={{ margin: "10px" }}
        onClick={() => login()}
      />
    </>
  );
};

export default GoogleLoginButton;
