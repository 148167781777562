"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMenu = useMenu;
exports.MenuProvider = MenuProvider;
var react_1 = __importStar(require("react"));
// Create the context with a default value
var MenuContext = (0, react_1.createContext)(undefined);
// Hook to access the context
function useMenu() {
    var context = (0, react_1.useContext)(MenuContext);
    if (!context) {
        throw new Error("useMenu must be used within a MenuProvider");
    }
    return context;
}
// MenuProvider component to wrap around parts of the app
function MenuProvider(_a) {
    var children = _a.children;
    var _b = (0, react_1.useState)("Home"), selectedMenu = _b[0], setSelectedMenu = _b[1];
    var selectMenu = function (menu) {
        setSelectedMenu(menu);
    };
    return (react_1.default.createElement(MenuContext.Provider, { value: { selectedMenu: selectedMenu, selectMenu: selectMenu } }, children));
}
