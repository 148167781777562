import { Flex } from "antd";
import { EnterNewQuestion } from "./EnterNewQuestion";
import { useState } from "react";
import Button from "../../common/button/Button";
import { addQuestionsFormNewTask } from "../../../utils/CustomerUiAPI";
import { useTheme } from "../../../ThemeContext";
import { CustomDialog } from "@voicecare.ai/ui-library";

const Questions = ({
  handleClose,
  isMedical,
  userQuestions,
  setUserQuestions,
}) => {
  const [questionType, setQuestionType] = useState(null);
  const [question, setQuestion] = useState("");
  const [yNQuestion, setYNQuestion] = useState({});
  const [loading, setLoading] = useState(false);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const handleOpenConfirmation = () => setIsConfirmationOpen(true);
  const handleCloseConfirmation = () => setIsConfirmationOpen(false);


  const { themeMode, currentTheme } = useTheme();

  const handleYNQuestion = (question, answerType) => {
    setYNQuestion({
      ...yNQuestion,
      [answerType]: {
        question,
      },
    });
  };

  const handleAddInput = (type, deleteInput) => {
    if (deleteInput) {
      const { [type]: _, ...rest } = yNQuestion || {};
      setYNQuestion({
        ...rest,
      });
    } else {
      setYNQuestion({
        ...yNQuestion,
        [type]: {},
      });
    }
  };

  const addQuestion = () => {
    const userMail = sessionStorage.getItem("mail");
    const insideData = [
      {
        referenceId: Math.random().toString(36).substring(7),
        originalQuestion: question,
        originalQuestionType: questionType,
        updatedQuestion: "None",
        updatedQuestionType: "None",
        operation: "insert",
        suffixAttentionNeeded: "False",
      },
    ];
    const data = {
      reported_by: userMail,
      reviewed_by: sessionStorage.getItem("mail"),
      data: {
        medical: isMedical ? insideData : [],
        dental: !isMedical ? insideData : [],
      },
    };
    setLoading(true);
    handleCloseConfirmation();
    addQuestionsFormNewTask(data, userMail, sessionStorage.getItem("sessionId"))
      .then(() => {
        const newQuestion = {
          question: question,
          questionType: questionType,
        };

        // Copy the existing userQuestions to avoid mutating state directly
        const updatedUserQuestions = { ...userQuestions };

        if (isMedical) {
          updatedUserQuestions.medical = [
            ...userQuestions.medical,
            newQuestion,
          ];
        } else {
          updatedUserQuestions.dental = [...userQuestions.dental, newQuestion];
        }

        console.log("Updated questions:", updatedUserQuestions);
        setUserQuestions(updatedUserQuestions);
        setLoading(false);
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        handleClose();
      });
  };

  return (
    <Flex vertical style={{ width: "-webkit-fill-available" }}>
      {loading ? (
        <img
          className="loader"
          src={
            themeMode === "dark"
              ? "/icons/loader_white.gif"
              : "/icons/loader_black.gif"
          }
        />
      ) : (
        <div style={{ background: "#fffff" }}>
          <EnterNewQuestion
            onChangeAnswerType={setQuestionType}
            selectedAnswerType={questionType}
            question={question}
            setQuestion={setQuestion}
            yNQuestion={yNQuestion}
            setYnQuestion={setYNQuestion}
            handleYNQuestion={handleYNQuestion}
            setAnswerTypeYN={handleAddInput}
          />
          <div style={{ paddingLeft: 40 }}>
            <Button
              label={"Add Question"}
              backgroundColor={"#f54f3b"}
              width={"8rem"}
              height={"4vh"}
              disabled={!question}
              style={{
                borderRadius: "1vh",
                marginTop: "1vh",
                marginRight: "1vh",
              }}
              onClick={handleOpenConfirmation}
            />
            <Button
              label={"Cancel"}
              backgroundColor={"#f54f3b"}
              width={"4rem"}
              height={"4vh"}
              style={{ borderRadius: "1vh", marginTop: "1vh" }}
              onClick={handleClose}
            />
          </div>
        </div>
      )}
      <CustomDialog
        open={isConfirmationOpen}
        message={`Are you sure you want to add new question?`}
        onConfirm={addQuestion}
        onCancel={handleCloseConfirmation}
        themeMode={themeMode}
        currentTheme={currentTheme}
      />
    </Flex>
  );
};

export default Questions;
