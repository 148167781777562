import Questions from "../newQuestion";
import { useTheme } from "../../../ThemeContext";

const QuestionInfo = ({
  setQuestionClicked,
  isMedical,
  setUserQuestions,
  userQuestions,
}) => {
  const handleClose = () => {
    setQuestionClicked(false);
  };

  const { currentTheme, themeMode } = useTheme();

  return (
    <div
      style={{
        width: "-webkit-fill-available",
        color: currentTheme.settingPage.textColor,
        background: themeMode === "light" ? "#ffffff" : "#131032",
        borderRadius: ".5rem",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          background: themeMode === "light" ? "#e3e2e5" : "#201e3c",
          borderRadius: ".5rem",
          paddingLeft: "2vh",
          alignItems: "center",
          alignContent: "center",
          height: "2rem",
          justifyContent: "space-between",
        }}
      >
        <span>New Question</span>
        <span>
          <button
            className="close-btn"
            onClick={() => handleClose()}
            style={{
              color: currentTheme.settingPage.textColor,
              position: "unset",
              paddingRight: ".5rem",
            }}
          >
            {themeMode === "dark" ? (
              <img src="/icons/cancel-grey.svg" width={"20vh"} />
            ) : (
              <img src="/icons/cancel-orange.svg" width={"20vh"} />
            )}
          </button>
        </span>
      </div>
      <Questions
        handleClose={handleClose}
        isMedical={isMedical}
        setUserQuestions={setUserQuestions}
        userQuestions={userQuestions}
      />
    </div>
  );
};

export default QuestionInfo;
