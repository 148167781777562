/* eslint-disable react-hooks/exhaustive-deps */
import "./Home.css";
import Button from "../common/button/Button";
// import Search from "../common/search/Search";
import {
  getProviderData,
  getBatchData,
  getCallAndProcessData,
  getBatchDataCSV,
} from "../../utils/CustomerUiAPI";
import { useEffect, useMemo, useState } from "react";
// import { useMenu } from "../common/menuContext/MenuContext";
import { Search, useMenu } from "@voicecare.ai/ui-library";
import { useTheme } from "../../ThemeContext";
import { useNavigate } from "react-router-dom";
import DataTable from "../common/table/DataTable/DataTable";
import CustomMuiTable from "../common/custom-mui-table/CustomDataGridBackendPagination/Index";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ActionButtons from "./HomeActionButtons";
import { Table } from "@voicecare.ai/ui-library";

const Home = () => {
  const { themeMode, currentTheme } = useTheme();
  const { selectMenu } = useMenu();
  const [batchId, setBatchId] = useState("");
  const [batchData, setBatchData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [batchDataBackup, setBatchDataBackUp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page2Visible, setPage2Visible] = useState(false);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [providerId, setProviderId] = useState("");
  const [sequentialId, setSequentialId] = useState(0);

  useEffect(() => {
    setLoading(true);
    getProviderData(
      sessionStorage.getItem("mail"),
      sessionStorage.getItem("sessionId")
    )
      .then((res) => {
        setProviderId(res.response.providerId);
        batchDataSetter(res.response.providerId);
        sessionStorage.setItem("providerName", res.response.providerName);
        sessionStorage.setItem("clinicName", res.response.clinicName);
        // return getBatchData(
        //   res.response.providerId,
        //   sessionStorage.getItem("mail"),
        //   sessionStorage.getItem("sessionId")
        // );
      })
      // .then((data) => {
      //   const dataWithSequentialIds = data.response.map((item, index) => ({
      //     ...item,
      //     sequentialId: (sequentialId + index + 1).toString().padStart(5, "0"),
      //   }));
      //   console.log(
      //     data.response,
      //     "batchDataDebugger we are in useEffect dataWithSequentialIds"
      //   );
      //   const flatten = flatRowCreator(data.response);
      //   console.log(
      //     dataWithSequentialIds,
      //     data,
      //     "batchDataDebugger dataWithSequentialIds"
      //   );
      //   setBatchData(flatten);
      //   setBatchDataBackUp(dataWithSequentialIds);
      //   setLoading(false);
      // })
      .catch(() => {
        // Handle error and possibly set some error state
        setLoading(false);
      });
  }, [sequentialId]);

  useEffect(() => {
    if (page2Visible) {
      fetchInitialData();
    }
  }, [page2Visible]);

  const batchDataSetter = async (providerId) => {
    const { response } = await getBatchData(
      providerId,
      sessionStorage.getItem("mail"),
      sessionStorage.getItem("sessionId")
    );
    const dataWithSequentialIds = response.map((item, index) => ({
      ...item,
      sequentialId: (sequentialId + index + 1).toString().padStart(5, "0"),
    }));
    const flatten = flatRowCreator(dataWithSequentialIds);
    setBatchData(flatten);
    setBatchDataBackUp(flatten);
    setLoading(false);
  };

  const fetchInitialData = () => {
    setLoading(true);
    if (batchId) {
      getCallAndProcessData(
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId"),
        batchId
      )
        .then((res) => {
          localStorage.removeItem("visibleColumns");
          const flatten = callAndProcessFlatRowCreator(res[0].response);
          setRowData(flatten);
          setOriginalData(flatten);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data: ", error);
        });
    }
  };

  const handleReset = () => {
    setSearchText("");
    setRowData(originalData);
  };

  const callAndProcessColumns2 = [
    {
      label: "Call Status",
      name: "Call Status",
      width: "15vh",
      selector: (row) => row.benefits_status,
      render: (row) => (
        <button className={statusStyle(row.benefits_status)}>
          {row.benefits_status}
        </button>
      ),
    },
    {
      label: "Data Write Status",
      name: "Data Write Status",
      width: "15vh",
      selector: (row) => row.data_write_status,
      render: (row) => (
        <button className={statusStyle(row.data_write_status)}>
          {row.data_write_status}
        </button>
      ),
    },
    {
      label: "First Name",
      name: "First Name",
      width: "20vh",
      selector: (row) => row.memberFirstName,
    },
    {
      label: "Middle Name",
      name: "Middle Name",
      width: "20vh",
      selector: (row) => row.memberMiddleName,
    },
    {
      label: "Last Name",
      name: "Last Name",
      width: "20vh",
      selector: (row) => row.memberLastName,
    },
    {
      label: "Phone Number",
      name: "Phone Number",
      width: "20vh",
      selector: (row) => row.memberPhoneNumber,
    },
  ];

  const statusStyle = (status) => {
    switch (status) {
      case "pending":
        return "pending";
      case "inProgress":
        return "in-progress";
      case "complete":
        return "complete";
      case "completeError":
        return "complete-error";
      default:
        return "pending";
    }
  };

  const handleSearch = (value) => {
    if (!page2Visible) {
      setFilterText(value);
      const searchText = value.toLowerCase();
      const filteredData = batchDataBackup.filter((row) => {
        return Object.keys(row).some((key) => {
          if (key !== "customer_input_filepath") {
            // Check if the column is the date field and format it
            if (key === "uploaded_date") {
              const formattedDate = formatDate(row[key]).toLowerCase();
              return formattedDate.includes(searchText);
            }
            // Check other string fields
            if (typeof row[key] === "string") {
              return row[key].toLowerCase().includes(searchText);
            }
          }
          return false;
        });
      });
      // const flatten = flatRowCreator(filteredData);
      setBatchData(filteredData);
    }
  };

  const handleSearchPage2 = (value) => {
    if (page2Visible) {
      setSearchText(value);
      const filteredData = originalData.filter((row) => {
        const searchText = value.toLowerCase();
        // Check if any column (excluding "customer_input_filepath") contains the search text
        return Object.keys(row).some((key) => {
          if (typeof row[key] === "string") {
            return row[key].toLowerCase().includes(searchText);
          }
          return false; // Ignore "customer_input_filepath" and non-string values
        });
      });
      setRowData(filteredData);
    }
  };

  const handleCallHistoryClick = (id) => {
    setBatchId(id);
    sessionStorage.setItem("batchId", id);
    navigate(`/call-history/${id}`);
  };

  const handleCallProcessClick = (id) => {
    setBatchId(id);
    setPage2Visible(true);
    selectMenu("Call Status");
  };

  const formatTime = (timestamp) => {
    const [hours, minutes, seconds] = timestamp.split(":");
    return `${hours.trim()} : ${minutes.trim()} : ${seconds.trim()}`;
  };

  // const formatDate = (inputDate) => {
  //   console.log(inputDate, "formatDateDebugger");
  //   const dateParts = inputDate.split("-");
  //   const year = dateParts[2];
  //   const month = dateParts[0];
  //   const day = dateParts[1];
  //   const date = new Date(`${year}-${month}-${day}`);
  //   const monthName = date.toLocaleString("en-us", { month: "short" });
  //   return `${day?.padStart(2, "0")} ${monthName}, ${year}`;
  // };

  const formatDate = (inputDate) => {
    const formattedDateRegex = /^\d{2} \w{3}, \d{4}$/;

    if (formattedDateRegex.test(inputDate)) {
      return inputDate;
    }

    const dateParts = inputDate.split("-");
    const year = dateParts[2];
    const month = dateParts[0];
    const day = dateParts[1];

    const date = new Date(`${year}-${month}-${day}`);
    const monthName = date.toLocaleString("en-us", { month: "short" });

    return `${day.padStart(2, "0")} ${monthName}, ${year}`;
  };

  const downloadFile = (url, fileName) => {
    // Create a fetch request to get the file as a Blob
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary anchor element
        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(blob);
        anchor.setAttribute("download", fileName);

        // Programmatically trigger a click event on the anchor element
        anchor.click();

        // Clean up by removing the anchor element from the DOM
        anchor.remove();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  // const handleDownload = async (row) => {
  //   // Check if the row has a property for the CSV URI
  //   if (row.customer_input_data_filepath) {
  //     try {
  //       const csvUrlResponse = await getBatchDataCSV(
  //         row.customer_input_data_filepath,
  //         sessionStorage.getItem("mail"),
  //         sessionStorage.getItem("sessionId")
  //       );

  //       const fileUrl = csvUrlResponse.response; // Access the signed URL from the response object
  //       const fileName = 'batch_data.csv'; // Replace with the actual file name if needed

  //       downloadFile(fileUrl, fileName);
  //     } catch (error) {
  //       console.error("Error getting signed URL:", error);
  //     }
  //   } else {
  //     // Optionally, handle the case where the CSV URI is missing or invalid
  //     console.error("CSV URI is missing or invalid");
  //   }
  // };

  // Function to format the date and time
  // function formatDateTime(dateStr, timeStr) {
  //   // Combine date and time strings into a single string
  //   const combinedStr = `${dateStr} ${timeStr}`;

  //   // Parse the combined string into a Date object
  //   const date = new Date(combinedStr);

  //   // Padding function
  //   const pad = (num) => num.toString().padStart(2, "0");

  //   // Extract components from the Date object
  //   const month = pad(date.getMonth() + 1); // Months are zero-based in JS
  //   const day = pad(date.getDate());
  //   const year = date.getFullYear();
  //   const hours = pad(date.getHours());
  //   const minutes = pad(date.getMinutes());
  //   const seconds = pad(date.getSeconds());
  //   const milliseconds = date.getMilliseconds().toString().padStart(3, "0");

  //   return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${milliseconds}`;
  // }

  const formatDateTime = (date, time) => {
    try {
      const [month, day, year] = date.split("-");
      let [hours, minutes, seconds] = time.split(/[:\s]/);
      const period = time.split(" ")[1];

      if (!month || !day || !year || !hours || !minutes || !seconds) {
        throw new Error("Invalid date or time format");
      }

      // Convert 12-hour format to 24-hour format if necessary
      if (period) {
        if (period.toUpperCase() === "PM" && hours !== "12") {
          hours = (parseInt(hours, 10) + 12).toString();
        } else if (period.toUpperCase() === "AM" && hours === "12") {
          hours = "00";
        }
      }

      const formattedDate = `${padNumber(month)}/${padNumber(day)}/${year}`;
      const formattedTime = `${padNumber(hours)}:${padNumber(
        minutes
      )}:${padNumber(seconds)}`;

      return `${formattedDate} ${formattedTime}`;
    } catch (error) {
      console.error(error.message);
      return "Invalid_Date_Time";
    }
  };

  const padNumber = (number) => {
    return number.toString().padStart(2, "0");
  };

  // const handleDownload = async (row) => {
  //   try {
  //     // Check if the row has a property for the CSV data
  //     if (row.customer_input_data_filepath) {
  //       // Call the API to get the CSV data directly
  //       const csvDataResponse = await getBatchDataCSV(
  //         row.customer_input_data_filepath,
  //         sessionStorage.getItem("mail"),
  //         sessionStorage.getItem("sessionId")
  //       );

  //       // Extract CSV data from the response
  //       const csvData = csvDataResponse; // Assuming csvDataResponse contains CSV data directly

  //       // Create a Blob from the CSV data
  //       const blob = new Blob([csvData], { type: "text/csv" });
  //       const fileName = `${sessionStorage.getItem("providerName")}_${
  //         row.sequentialId
  //       }_${formatDateTime(row.uploaded_date, row.uploaded_time)}.csv`;

  //       console.log({fileName, uploadedDate: row.uploaded_date, uploadedTime: row.uploaded_time}, 'downloadDebugger');
  //       // Create an anchor element to trigger the download
  //       const anchor = document.createElement("a");
  //       anchor.href = URL.createObjectURL(blob);
  //       anchor.setAttribute("download", fileName);
  //       anchor.click();
  //       anchor.remove();
  //     } else {
  //       console.error("CSV data filepath is missing or invalid");
  //       // Inform the user that download is unavailable
  //     }
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //     // Display an error message to the user
  //   }
  // };

  const handleDownload = async (row) => {
    try {
      if (row.customer_input_data_filepath) {
        const csvDataResponse = await getBatchDataCSV(
          row.customer_input_data_filepath,
          sessionStorage.getItem("mail"),
          sessionStorage.getItem("sessionId")
        );

        const csvData = csvDataResponse;

        const blob = new Blob([csvData], { type: "text/csv" });
        const fileName = `${sessionStorage.getItem("providerName")}_${
          row.sequentialId
        }_${formatDateTime(row.uploaded_date, row.uploaded_time)}.csv`;

        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(blob);
        anchor.setAttribute("download", fileName);
        anchor.click();
        anchor.remove();
      } else {
        console.error("CSV data filepath is missing or invalid");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // const handleDownload = (row) => {
  //   if (row.customer_input_data_filepath) {
  //     window.open(row.customer_input_data_filepath, "_blank");
  //   } else {
  //     console.error("Download URL is missing or invalid");
  //   }
  // };

  const columns2 = [
    { name: "sequentialId", label: "Batch ID", width: "20vw" },
    { name: "uploaded_by", label: "Uploaded By", width: "20vw" },
    {
      name: "Action",
      label: "Date",
      render: (row) => <span>{formatDate(row.uploaded_date)}</span>,
      width: "20vw",
    },
    {
      name: "Action",
      label: "Time",
      width: "20vw",
      render: (row) => <span>{formatTime(row.uploaded_time)}</span>,
    },
    {
      label: "Action",
      name: "Action",
      render: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="home-icon"
            src={
              themeMode === "dark"
                ? "/icons/call-history-mini-grey.svg"
                : "/icons/call-history-mini-grey-light.svg"
            }
            title="Call History"
            onClick={() => handleCallHistoryClick(row.batch_request_id)}
          />
          <img
            className="home-icon"
            src={
              themeMode === "dark"
                ? "/icons/call-process-mini-grey.svg"
                : "/icons/call-process-mini-grey-light.svg"
            }
            title="Call Status"
            onClick={() => handleCallProcessClick(row.batch_request_id)}
          />
          <span
            onClick={() => handleDownload(row)}
            style={{
              width: "12vh",
              height: "3vh",
              color: "black",
              backgroundColor: themeMode === "dark" ? "#a8a7b4" : "#e1e1e1",
              outline: "none",
              margin: "1vh",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "1vh",
              cursor: "pointer",
              fontSize: "12px",
            }}
          >
            Download
          </span>
        </div>
      ),
      width: "20vw",
    },
  ];

  const flatRowCreator = (rows) => {
    const flatten = rows.map((row, index) => ({
      ...row,
      id: row.sequentialId || index,
      uploaded_date: formatDate(row.uploaded_date),
      uploaded_time: formatTime(row.uploaded_time),
      actions: (
        <ActionButtons
          row={row}
          themeMode={themeMode}
          handleCallHistoryClick={handleCallHistoryClick}
          handleCallProcessClick={handleCallProcessClick}
          handleDownload={handleDownload}
        />
      ),
    }));
    return flatten;
  };

  const columns = [
    { headerName: "Batch Id", renderName: "sequentialId" },
    { headerName: "Uploaded By", renderName: "uploaded_by" },
    { headerName: "Date", renderName: "uploaded_date" },
    { headerName: "Time", renderName: "uploaded_time" },
    { headerName: "Action", renderName: "actions" },
  ];

  const callAndProcessColumns = [
    { headerName: "Call Status", renderName: "benefits_status" },
    { headerName: "Data Write Status", renderName: "data_write_status" },
    { headerName: "First Name", renderName: "memberFirstName" },
    { headerName: "Middle Name", renderName: "memberMiddleName" },
    { headerName: "Last Name", renderName: "memberLastName" },
    { headerName: "Phone Number", renderName: "memberPhoneNumber" },
  ];

  const callAndProcessFlatRowCreator = (rows) => {
    const flatten = rows.map((row, index) => ({
      id: row?.sequentialId || index,
      benefits_status: row?.benefits_status ? (
        <Button className={statusStyle(row.benefits_status)}>
          {row.benefits_status}
        </Button>
      ) : (
        "pending"
      ),
      data_write_status: row?.data_write_status ? (
        <Button className={statusStyle(row.data_write_status)}>
          {row.data_write_status}
        </Button>
      ) : (
        "pending"
      ),
      memberFirstName: row.memberFirstName,
      memberMiddleName: row.memberMiddleName,
      memberLastName: row.memberLastName,
      memberPhoneNumber: row.memberPhoneNumber,
    }));

    return flatten;
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleButtonClick = (action) => {
    if (action === "reset") {
      setFilterText("");
      const flatten = flatRowCreator(batchDataBackup);
      setBatchData(flatten);
    } else if (action === "createBatch") {
      navigate("/call-automation");
      selectMenu("Call Automation");
    }
  };

  const handleBack = () => {
    setPage2Visible(false);
    localStorage.removeItem("visibleColumns");
    selectMenu("Home");
  };

  return (
    <div
      className="home-root"
      style={{
        background: currentTheme.homePage.mainBackground,
        color: currentTheme.homePage.textColor,
      }}
    >
      {!page2Visible ? (
        <>
          <div style={{ display: "flex" }}>
            <div className="home-buttons">
              <Search
                value={filterText}
                onChange={handleSearch}
                themeMode={themeMode}
                currentTheme={currentTheme}
                style={{
                  flex: "1",
                  marginRight: "1rem",
                  outline: "none",
                  border: "none",
                }}
              />
              <Button
                label={"Reset"}
                backgroundColor={"#ff4e3a"}
                width={"calc(12% - 0.5rem)"}
                height={"4vh"}
                onClick={() => handleButtonClick("reset")}
                style={{
                  backgroundColor: "#ff4e3a",
                  width: "calc(12% - 0.5rem)",
                  height: "4vh",
                  transition: "all 0.3s ease",
                  marginRight: "1rem",
                }}
              />
              <Button
                label={"Create Batch"}
                backgroundColor={"#ff4e3a"}
                width={"calc(12% - 0.5rem)"}
                style={{
                  backgroundColor: "#ff4e3a",
                  width: "calc(12% - 0.5rem)",
                  height: "4vh",
                  transition: "all 0.3s ease",
                }}
                height={"4vh"}
                onClick={() => handleButtonClick("createBatch")}
              />
            </div>
          </div>
          {loading ? (
            <img
              className="loader"
              src={
                themeMode === "dark"
                  ? "/icons/loader_white.gif"
                  : "/icons/loader_black.gif"
              }
            />
          ) : batchData && batchData.length > 0 ? (
            // <DataTable
            //   isEditable={false}
            //   headerColor={currentTheme.homePage.tableHeader}
            //   dataColor={currentTheme.homePage.search}
            //   headerTextColor={currentTheme.homePage.textColor}
            //   columns={columns}
            //   data={batchData}
            //   itemsPerPageOptions={[5, 10, 20]}
            //   defaultItemsPerPage={10}
            //   maxHeight={"58vh"}
            //   height={"60vh"}
            // />
            // <div style={{ height: 400, width: "100%" }}>
            //   <DataGrid
            //     rows={batchData}
            //     columns={columns}
            //     pageSize={5}
            //     rowsPerPageOptions={[5]}
            //   />
            // </div>
            <Box sx={{ height: "70vh", overflow: "auto" }}>
              <Table
                rows={batchData}
                columns={columns}
                frontendSort
                // noCustomizeColumn
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                themeMode={themeMode}
              />
            </Box>
          ) : (
            <>No Records Found</>
          )}
        </>
      ) : (
        <>
          <div style={{ display: "flex", marginBottom: "2vh" }}>
            <Search
              value={searchText}
              onChange={handleSearchPage2}
              themeMode={themeMode}
              currentTheme={currentTheme}
              style={{
                flex: "1",
                marginRight: "1rem",
                outline: "none",
                border: "none",
              }}
            />
            <Button
              label={"Reset"}
              backgroundColor={"#ff4e3a"}
              width={"calc(12% - 0.5rem)"}
              height={"4vh"}
              onClick={() => handleReset()}
              style={{ marginRight: "1rem" }}
            />
            <Button
              label={"Back"}
              backgroundColor={"#ff4e3a"}
              width={"calc(12% - 0.5rem)"}
              height={"4vh"}
              onClick={() => handleBack()}
            />
          </div>
          {loading ? (
            <img
              className="loader"
              src={
                themeMode === "dark"
                  ? "/icons/loader_white.gif"
                  : "/icons/loader_black.gif"
              }
            />
          ) : rowData.length !== 0 ? (
            <Table
              rows={rowData}
              columns={callAndProcessColumns}
              frontendSort
              // noCustomizeColumn
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              themeMode={themeMode}
            />
          ) : (
            // <Table
            //   columns={callAndProcessColumns}
            //   data={rowData}
            //   page={page}
            //   setPage={setPage}
            //   rowsPerPage={rowsPerPage}
            //   setRowsPerPage={setRowsPerPage}
            //   handleChangePage={handleChangePage}
            //   handleChangeRowsPerPage={handleChangeRowsPerPage}
            //   themeMode={themeMode}
            // />
            "No Records Found"
          )}
        </>
      )}
    </div>
  );
};

export default Home;
