import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SamlSuccess = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailId = params.get("emailId");
    sessionStorage.setItem("samlSuccess", true);
    sessionStorage.setItem("mail", emailId);
    setIsAuthenticated(true);
    navigate("/");
  }, [navigate]);

  return <div>Loading...</div>;
}

export default SamlSuccess;