import React, { useEffect, useRef, useState } from "react";

const ZoomableWrapper = ({ children }) => {
  const containerRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [originalScale, setOriginalScale] = useState(1);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        if (event.key === "+" || event.key === "=") {
          // Prevent zoom in when Ctrl and + are pressed
          event.preventDefault();
          return;
        } else if (event.key === "-") {
          // Prevent zoom out when Ctrl and - are pressed
          event.preventDefault();
          return;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const resetZoom = () => {
    setScale(originalScale);
  };

  const handleDoubleClick = () => {
    setOriginalScale(scale);
    resetZoom();
  };

  useEffect(() => {
    const handleResize = () => {
      // window.alert("For best UI experiance please keep zoom between 75% to 125%");
      document.body.style.transform = `scale(1)`;
      document.body.style.transformOrigin = `0 0`;

      const currentZoom = (document.body.offsetWidth / window.innerWidth) * 100;

      if (currentZoom !== 100) {
        window.location.reload();
        window.alert(
          "For best UI experience, please keep the zoom level between 75% and 150%."
        );
      }

      if (currentZoom < 75) {
        window.alert(
          "Zoom level is too low. Please zoom in for better experience."
        );
      } else if (currentZoom > 125) {
        window.alert(
          "Zoom level is too high. Please zoom out for better experience."
        );
      }

      // Force a re-render by changing the state of a component or using forceUpdate
      // For example, you can update a state variable or trigger forceUpdate()
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        transform: `scale(${scale})`,
        transformOrigin: "0 0",
        transition: "transform 0.2s ease-out",
      }}
      onDoubleClick={handleDoubleClick}
    >
      {children}
    </div>
  );
};

export default ZoomableWrapper;
