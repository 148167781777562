import React from "react";
import "./ForceLoginDialog.css";
import Button from "../common/button/Button";
import { useTheme } from "../../ThemeContext";

const NoSessionDialog = ({ onResult, onCancel }) => {
  const { currentTheme, themeMode } = useTheme();

  const handleCancel = () => {
    onCancel(false);
    onResult("cancel");
    window.location.reload();
  };

  const handleContinue = () => {
    onResult("continue");
  };

  return (
    <div
      className="force-login-dialog"
      style={{ background: currentTheme.loginPage.forceLoginBgColor }}
    >
      <div className="force-login-content" style={{fontWeight: themeMode === "light" ? "bold" : "400"}}>
        <p>
          There is no valid sessions available for you to login. Please contact Voicecare Admin for further support.
        </p>
        <div className="force-login-buttons">
          <Button
            label={"Cancel"}
            backgroundColor={currentTheme.loginPage.loginButtonColor}
            width={"47vh"}
            height={"4vh"}
            style={{ padding: "1vh", margin: "2vh" }}
            onClick={() => handleCancel(1)}
          />
        </div>
      </div>
    </div>
  );
};

export default NoSessionDialog;
