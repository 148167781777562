/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import { useEffect, useState } from "react";
import "./Settings.css";
import UserAccess from "./userAccess/UserAccess";
import UserRoles from "./userRoles/UserRoles";
import { useTheme } from "../../ThemeContext";
import { Switch } from "antd";
import Page0 from "../intakeForm/page0/page0";
import IntakeForm from "../intakeForm/IntakeForm";
import Page2 from "../intakeForm/page2/page2";
import Button from "../common/button/Button";

const activeMenuButtonStyle = {
  display: "flex",
  padding: ".5rem",
  paddingRight: "auto",
  paddingLeft: "auto",
  backgroundColor: "#ff4e3a",
  color: "#ffffff",
  borderRadius: "1vh",
  marginBottom: "1vh",
  margin: "1vh",
  width: "22vh",
  cursor: "pointer",
  justifyContent: "center",
};



const Settings = () => {
  const storedMenu = sessionStorage.getItem("setting-menu");
  const [menu, setMenu] = useState(storedMenu || "users");
  const { toggleTheme, currentTheme, themeMode } = useTheme();
  const [idClicked, setIdClicked] = useState(false);
  const [intakeClicked, setIntakeClicked] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [createdDate, setCreatedData] = useState("");


    const darkInactiveMenuButtonStyle = {
        ...activeMenuButtonStyle,
        backgroundColor: "#3e3c57",
        color: currentTheme.homePage.textColor
    };

    const lightInactiveMenuButtonStyle = {
        ...activeMenuButtonStyle,
        backgroundColor: "#e3e2e5",
        color: currentTheme.homePage.textColor
    };

  useEffect(() => {
    sessionStorage.setItem("setting-menu", menu);
  }, [menu]);

  return (
    <div
      className="setting-root"
      style={{
        background: currentTheme.settingPage.mainBackground,
        color: currentTheme.settingPage.textColor,
      }}
    >
      <div className="setting-header">
        <span className="setting-header-name">User Selection</span>
        <div className="setting-sidebar">
          {/* <span
            className={
              menu === "users"
                ? "setting-sidebar-active-menu"
                : `${themeMode}-setting-sidebar-in-active-menu`
            }
            onClick={() => setMenu("users")}
          >
            Manage User Access
          </span> */}

          {/* <span
            className={
              menu === "roles"
                ? "setting-sidebar-active-menu"
                : `${themeMode}-setting-sidebar-in-active-menu`
            }
            onClick={() => setMenu("roles")}
          >
            Manage User Roles
          </span> */}
          <Button
            label="Manage User Access"
            onClick={() => setMenu("users")}
            style={
              menu === "users"
                ? activeMenuButtonStyle
                : themeMode === "dark"
                ? darkInactiveMenuButtonStyle
                : lightInactiveMenuButtonStyle
            }
            fontSize="1.875rem"
          />
          <Button
            label="Manage User Roles"
            onClick={() => setMenu("roles")}
            style={
              menu === "roles"
                ? activeMenuButtonStyle
                : themeMode === "dark"
                ? darkInactiveMenuButtonStyle
                : lightInactiveMenuButtonStyle
            }
            fontSize="1.875rem"
          />
          {sessionStorage.getItem("mail").toString().includes("voicecare") ? (
            // <span
            //   className={
            //     menu === "page0"
            //       ? "setting-sidebar-active-menu"
            //       : `${themeMode}-setting-sidebar-in-active-menu`
            //   }
            //   onClick={() => setMenu("page0")}
            // >
            //   Manage Intake Tasks
            // </span>
            <Button
              label="Manage Intake Tasks"
              onClick={() => setMenu("page0")}
              style={
                menu === "page0"
                  ? activeMenuButtonStyle
                  : themeMode === "dark"
                  ? darkInactiveMenuButtonStyle
                  : lightInactiveMenuButtonStyle
              }
              fontSize="1.875rem"
            />
          ) : (
            // <span
            //   className={
            //     menu === "intake"
            //       ? "setting-sidebar-active-menu"
            //       : `${themeMode}-setting-sidebar-in-active-menu`
            //   }
            //   onClick={() => setMenu("intake")}
            // >
            //   View Intake Form
            // </span>
            <Button
              label="View Intake Form"
              onClick={() => setMenu("intake")}
              style={
                menu === "intake"
                  ? activeMenuButtonStyle
                  : themeMode === "dark"
                  ? darkInactiveMenuButtonStyle
                  : lightInactiveMenuButtonStyle
              }
              fontSize="auto"
            />
          )}
          <span
            style={{
              display: "flex",
              alignItems: "center",
              padding: ".5vh",
              background:
                themeMode !== "light"
                  ? currentTheme.settingPage.tableHeader
                  : "#d6d5d8",
              width: "22vh",
              marginTop: "50vh",
              borderRadius: "1vh",
              justifyContent: "space-evenly",
            }}
          >
            <img
              src={
                themeMode === "light"
                  ? "/icons/theme-icon-light.svg"
                  : "/icons/theme-icon.svg"
              }
              height="20vh"
            />
            <span style={{ marginRight: "1vh" }}>Theme</span>
            <Switch
              style={{ background: "white" }}
              defaultChecked
              onChange={() => toggleTheme()}
              size="small"
            />
          </span>
        </div>
      </div>
      <div className="setting-content">
        <div className="setting-inner-content">
          {(() => {
            switch (menu) {
              case "users":
                return sessionStorage
                  .getItem("permissions")
                  .includes("view_user_access") ? (
                  <UserAccess />
                ) : null;
              case "roles":
                return sessionStorage
                  .getItem("permissions")
                  .includes("view_user_roles") ? (
                  <UserRoles />
                ) : null;
              case "page0":
                return idClicked ? (
                  <Page2
                    taskId={taskId}
                    createdDate={createdDate}
                    setIdClicked={setIdClicked}
                  />
                ) : intakeClicked ? (
                  <IntakeForm setIntakeClicked={setIntakeClicked} />
                ) : (
                  <Page0
                    setIdClicked={setIdClicked}
                    setIntakeClicked={setIntakeClicked}
                    setCreatedData={setCreatedData}
                    setTaskId={setTaskId}
                  />
                );
              case "page2":
                return <Page2 />;
              case "intake":
                return <IntakeForm />;
            }
          })()}
        </div>
      </div>
    </div>
  );
};
export default Settings;
