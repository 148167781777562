import "./AddNewUser.css";
import PersonalInfo from "./personalInfo/PersonalInfo";
import Popup from "react-animated-popup";
import { useEffect, useState } from "react";
import { useTheme } from "../../../../ThemeContext";
import LogoLoader from "../../../common/loaders/gif-logo-loader";

const AddNewUser = ({
  visible,
  setVisible,
  setEditData,
  editData,
  mode,
  notifyMessage,
  setMode,
  roles,
}) => {
  const { currentTheme, themeMode } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    setEditData({});
    setVisible(false);
  };
  return (
    <>
      {isLoading ? (
        <LogoLoader />
      ) : (
        <Popup
          visible={visible}
          className="add-new-user-div"
          style={{
            height: "55vh",
            width: "38vw",
            background: currentTheme.settingPage.popupBackground,
            color: currentTheme.settingPage.textColor,
          }}
        >
          <button
            className="close-btn"
            onClick={() => handleClose()}
            style={{ color: currentTheme.settingPage.textColor }}
          >
            {themeMode === "dark" ? (
              <img src="/icons/cancel-grey.svg" width={"20vh"} />
            ) : (
              <img src="/icons/cancel-orange.svg" width={"20vh"} />
            )}
          </button>
          <PersonalInfo
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            editData={editData}
            handleClose={handleClose}
            setVisible={setVisible}
            mode={mode}
            notifyMessage={notifyMessage}
            setMode={setMode}
            roles={roles}
          />
        </Popup>
      )}
    </>
  );
};

export default AddNewUser;
