import React, { useState } from "react";
import { useTheme } from "../../../ThemeContext";
import "./TextBox.css";
const TextBox = ({
  onChange,
  value,
  iconUrl,
  style,
  textBoxBackground,
  textBoxText,
  type,
  placeholder,
  onBlur
}) => {
  const { themeMode } = useTheme();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const handleType = () => {
    if (type === "text") {
      return "text";
    } else {
      return isRevealPwd ? "text" : "password";
    }
  };
  const newStyles = {
    background: textBoxBackground,
  };
  const mergedStyles = {
    ...style,
    ...newStyles,
  };
  const getShowUrl = () => {
    return themeMode === "dark" ? "/icons/show-white.png" : "/icons/show.png"
  }
  const getHideUrl = () => {
    return themeMode === "dark" ? "/icons/hide-white.png" : "/icons/hide.png"
  }
  return (
    <div className="search-container" style={mergedStyles}>
      {iconUrl && <img className="search-icon" src={iconUrl} />}
      <input
        type={handleType()}
        className="search-input"
        placeholder={placeholder || "Search"}
        style={{
          background: textBoxBackground,
          color: textBoxText,
          WebkitTextFillColor: textBoxText,
          WebkitBoxShadow: `0 0 0 1000px ${textBoxBackground} inset`,
        }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoComplete="off"
        onBlur={onBlur}
      />

      {type === "password" && (
        <img
          className="search-icon"
          onClick={() => setIsRevealPwd(!isRevealPwd)}
          src={isRevealPwd ?  getShowUrl() : getHideUrl()}
        />
      )}
    </div>
  );
};

export default TextBox;
