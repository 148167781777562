import React, { useEffect, useState } from "react";
import { AutoComplete, Input, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Colors } from "../helpers";
import { useCallHistoryProvider } from "../context/CallHIstoryContext";
import { useTheme } from "../../../ThemeContext";
import "../CallHistory.css";
import { Chip, Grid, Tooltip } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Button from "../../common/button/Button";
const { Text } = Typography;

// const SearchIcon = <SearchOutlined style={{ fontSize: 18, color: Colors.text }} />;

const activeMenuButtonStyle = {
  backgroundColor: "#ff4e3a",
  color: "#ffffff",
  borderRadius: "1vh",
  marginBottom: "1vh",
  cursor: "pointer",
  justifyContent: "center",
};

export const SearchInput = ({
  placeholder,
  searchInput,
  setSearchInput,
  className,
  width
}) => {
  const { themeMode, currentTheme } = useTheme();

  return (
    <div
      style={{
        borderRadius: "1vh",
        backgroundColor: currentTheme.callhistory.searchTranscriptColor,
        color: currentTheme.callhistory.searchColor,
        width: width ? width : '20rem'
      }}
    >
      <div
        style={{ display: "flex", alignItems: "left", justifyContent: "left", width: width ? width : '20rem' }}
      >
        <SearchOutlined
          style={{
            fontSize: 18,
            color: currentTheme.callhistory.searchColor,
            marginRight: 8,
            marginLeft: 6,
          }}
        />
        <input
          placeholder={placeholder}
          className={`call-history__searchInput ${className || ""}`}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          style={{
            color: currentTheme.callhistory.searchColor,
            height: "2vh",
            width: width ? width : '20rem'
          }}
        />
      </div>
    </div>
  );
};

const searchResult = (data, currentTheme) => {
  return data?.map((item) => {
    return {
      value: item.member_name,
      id: item.member_id,
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: currentTheme.callhistory.bgLite,
            cursor: "pointer",
          }}
        >
          <Text
            style={{ color: currentTheme.callhistory.textLight, fontSize: 14 }}
          >
            {item.member_name}
          </Text>
        </div>
      ),
    };
  });
};

export const AutoCompleteInput = ({
  className,
  setLoading,
  setTotalRows,
  setRenderType,
  setCurrentPage,
  setPrevPageState
}) => {
  const [options, setOptions] = useState([]);
  const {
    callerList,
    fetchPatientCallData,
    setSelectedMember,
    setFilterType,
    filterType,
    filterBy,
    setFilterBy,
  } = useCallHistoryProvider();
  const { themeMode, currentTheme } = useTheme();
  const [selectedText, setSelectedText] = useState("");

  const [showFilters, setShowFilters] = useState(false);

  const handleFilterChange = () => {
    if (filterBy === "date") {
      setFilterBy("name");
    } else {
      setFilterBy("date");
    }
  };

  const handleFilterTypeChange = () => {
    if (filterType === "ASC") {
      setFilterType("DESC");
    } else {
      setFilterType("ASC");
    }
  };

  const darkInactiveMenuButtonStyle = {
    ...activeMenuButtonStyle,
    backgroundColor: "#3e3c57",
    color: currentTheme.homePage.textColor,
  };

  const lightInactiveMenuButtonStyle = {
    ...activeMenuButtonStyle,
    backgroundColor: "#e3e2e5",
    color: currentTheme.homePage.textColor,
  };

  const handleSearch = (value) => {
    setSelectedText(value);
    if (!value) {
      setOptions([]);
    } else {
      const data = Object.keys(callerList)
        .map((key) => ({
          member_id: key,
          member_name: callerList[key],
        }))
        .filter((item) => {
          return item.member_name.toLowerCase().includes(value.toLowerCase());
        });
      setOptions(searchResult(data, currentTheme));
    }
  };

  const onSelect = (value, option) => {
    setSelectedText(value);
    const id = option?.id;
    console.log("popl", id);

    if (id) {
      setSelectedMember(id);
      fetchPatientCallData(
        sessionStorage.getItem("batchId"),
        setLoading,
        "1",
        "25",
        setTotalRows,
        id,
        "resetState"
      );
    }
  };

  const handleFilterSearch = () => {
    setCurrentPage(1);
    setPrevPageState([1]);
    setSelectedMember("");
    fetchPatientCallData(
      "",
      setLoading,
      "1",
      "25",
      setTotalRows,
      "",
      "resetState",
      filterBy === "name" ? "member_name" : "call_start_time",
      filterType
    );
  };

  const handleReset = () => {
    setPrevPageState([1]);
    setCurrentPage(1);
    setSelectedMember('');
    fetchPatientCallData(
      "",
      setLoading,
      "1",
      "25",
      setTotalRows,
      "",
      "resetState",
      "call_start_time",
      "DESC"
    );
  };

  return (
    <>
      <AutoComplete
        popupMatchSelectWidth={"100%"}
        style={{ width: "100%", color: currentTheme.callhistory.text }}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
        size="large"
        dropdownStyle={{
          background: currentTheme.callhistory.bgLite,
          color: currentTheme.callhistory.text,
        }}
      >
        <div
          style={{
            borderRadius: "1vh",
            backgroundColor: currentTheme.callhistory.searchBackground,
            color: currentTheme.callhistory.searchColor,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <SearchOutlined
              style={{
                fontSize: 18,
                color: currentTheme.callhistory.searchColor,
                marginLeft: "15px",
              }}
            />
            <input
              className={`call-history__searchInput ${className || ""}`}
              placeholder="Type a Name"
              value={selectedText}
              style={{
                color: currentTheme.callhistory.searchColor,
                width: "100%", // Adjust width as needed
              }}
            />
            <Tooltip title="sort">
              <Chip
                icon={showFilters ? <ArrowUpwardIcon /> : <SortIcon />}
                size="small"
                variant="contained"
                onClick={() => setShowFilters(!showFilters)}
              />
            </Tooltip>
          </div>
        </div>
      </AutoComplete>
      {showFilters && (
        <Grid sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Tooltip
            title={`Click to sort by ${filterBy === "name" ? "Date" : "Name"}`}
          >
            <Chip
              label={`Sorted By ${filterBy}`}
              size="small"
              color="primary"
              variant="outlined"
              onClick={handleFilterChange}
            />
          </Tooltip>
          <Tooltip
            title={`Click to sort ${filterBy} in ${
              filterType === "DESC" ? "ASC" : "DESC"
            } order`}
          >
            <Chip
              label={`Sorted in ${filterType} order`}
              size="small"
              color="primary"
              variant="outlined"
              onClick={handleFilterTypeChange}
            />
          </Tooltip>

          <Button
            label={"Sort"}
            onClick={handleFilterSearch}
            style={
              themeMode === "dark"
                ? darkInactiveMenuButtonStyle
                : lightInactiveMenuButtonStyle
            }
          />

          <Button
            label={"Reset"}
            onClick={handleReset}
            style={
              themeMode === "dark"
                ? darkInactiveMenuButtonStyle
                : lightInactiveMenuButtonStyle
            }
          />
        </Grid>
      )}
    </>
  );
};
