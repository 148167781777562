"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var InputField = function (_a) {
    var value = _a.value, onChange = _a.onChange, _b = _a.placeholder, placeholder = _b === void 0 ? "Enter text" : _b, _c = _a.type, type = _c === void 0 ? "text" : _c, iconUrl = _a.iconUrl, _d = _a.style, style = _d === void 0 ? {} : _d, textBoxBackground = _a.textBoxBackground, textBoxText = _a.textBoxText, onBlur = _a.onBlur, themeMode = _a.themeMode, currentTheme = _a.currentTheme;
    var _e = (0, react_1.useState)(false), isRevealPwd = _e[0], setIsRevealPwd = _e[1];
    // Default input type logic
    var handleType = function () {
        if (type === "text") {
            return "text";
        }
        else {
            return isRevealPwd ? "text" : "password";
        }
    };
    // Handling password reveal icon
    var getShowUrl = function () {
        return themeMode === "dark" ? "/icons/show-white.png" : "/icons/show.png";
    };
    var getHideUrl = function () {
        return themeMode === "dark" ? "/icons/hide-white.png" : "/icons/hide.png";
    };
    // Default styles extracted from the provided CSS
    var defaultStyles = {
        background: themeMode === "dark" ? "#535167" : "#ededed",
        color: currentTheme.settingPage.textColor,
        width: "15vw",
        height: "4vh",
        paddingLeft: "2vh",
        borderRadius: "1vh",
        borderStyle: "none",
        marginTop: "0.5vh",
        marginRight: "5vh",
        outline: "none",
        display: "flex",
        alignItems: "center",
        border: "1px solid #636279",
        position: "relative",
    };
    var mergedStyles = __assign(__assign({}, defaultStyles), style);
    return (react_1.default.createElement("div", { className: "input-field-container", style: mergedStyles },
        iconUrl && (react_1.default.createElement("img", { className: "input-icon", src: iconUrl, alt: "icon", style: {
                height: "1.5rem",
                cursor: "pointer",
                position: "absolute",
                left: "0.5rem",
            } })),
        react_1.default.createElement("input", { type: handleType(), className: "input-field", placeholder: placeholder, value: value, onChange: function (e) { return onChange(e.target.value); }, style: {
                background: textBoxBackground || defaultStyles.background,
                color: textBoxText || currentTheme.settingPage.textColor,
                WebkitTextFillColor: textBoxText || currentTheme.settingPage.textColor,
                WebkitBoxShadow: "0 0 0 1000px ".concat(textBoxBackground || defaultStyles.background, " inset"),
                width: "100%",
                border: "none",
                outline: "none",
            }, onBlur: onBlur, autoComplete: "off" }),
        type === "password" && (react_1.default.createElement("img", { className: "input-icon", onClick: function () { return setIsRevealPwd(!isRevealPwd); }, src: isRevealPwd ? getShowUrl() : getHideUrl(), alt: "toggle visibility", style: { height: "1.5rem", cursor: "pointer", marginRight: "0.5rem" } }))));
};
exports.default = InputField;
