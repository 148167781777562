/* eslint-disable jsx-a11y/alt-text */
import "./CallAutomation.css";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DateRangePicker from "../common/dateRangePicker/DateRangePicker";
import {
  TimeStampGenerator,
  convertTo12HourFormat,
} from "./widgets/TimeStampGenerator";
import Button from "../common/button/Button";
// import Table from "../common/table/Table";
import Popup from "react-animated-popup";
import { useHome } from "../../utils/hooks";
import {
  pushProviderPatientData,
  getErrorData,
  getBatchStatus,
} from "../../utils/CustomerUiAPI";

import { read, utils } from "xlsx";
import {
  CallAutomationInfo,
  DataValidationOverlay,
} from "../callAutomationInfo";
// import Search from "../common/search/Search";
import { useTheme } from "../../ThemeContext";
import Select from "../common/select/Select";
import DataTable from "../common/table/DataTable/DataTable";
import UploadData from "./widgets/UploadData/UploadData";
import CustomMuiTable from "../common/custom-mui-table/CustomDataGridBackendPagination/Index";
import { Table, CustomDialog, Search } from "@voicecare.ai/ui-library";

const CallAutomation = () => {
  const [isReccuring, setIsReccuring] = useState(false);
  const { themeMode, currentTheme, updateTheme } = useTheme();
  const [batchId, setBatchId] = useState("");
  const [hideUpload, setHideUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesBackup, setSelectedFilesBackup] = useState([]);
  const [allowedTypes, setAllowedTypes] = useState([
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ]);

  const [errorList, setErrorList] = useState([]);
  const [focusedFile, setFocusedfile] = useState(null);
  const [callStartTime, setCallStartTime] = useState(null);
  const [minuteClicked, setMinuteClicked] = useState(false);
  const [callEndTime, setCallEndTime] = useState(null);
  const [callTimeSelectionError, setCallTimeSelectionError] = useState(false);
  const [selectedRanges, setSelectedRanges] = useState([]);
  const [selectRandom, setSelectRandom] = useState(true);
  const [isDates, setIsDates] = useState(true);
  const navigate = useNavigate();
  const [frequency, setFrequency] = useState({});
  const format = "MM-DD-YYYY";
  const [startTimeClicked, setStartTimeClicked] = useState(false);
  const [endTimeClicked, setEndTimeClicked] = useState(false);
  const [automationFeature, setAutomationFeature] = useState("benefits");
  const [automationType, setAutomationType] = useState("manual");
  const [submitClicked, setSubmitClicked] = useState(false);

  const [transormedData, setTransformedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [errorOpen, setErrorOpen] = useState(false);

  const [openOverlay, setOpenOverlay] = useState(false);
  const [currentBatchId, setCurrentBatchId] = useState("");
  const dropAreaRef = useRef(null);

  const navigateHome = useHome();

  const [loading, setLoading] = useState(false);
  const [isFirstSelectOpen, setIsFirstSelectOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState("");
  const [addPatients, setAddPatients] = useState(false);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationValue, setConfirmationValue] = useState(null);
  const [filename, setFileName] = useState(null);

  const handleConfirmationClose = () => setIsConfirmationOpen(false);
  const handleConfirmationOpen = () => setIsConfirmationOpen(true);

  const handleConfirmationValueChange = (e) => {
    handleConfirmationOpen();
    setConfirmationValue(e);
    setFileName(e.target.files[0]?.name);
  };

  const handleConfirmed = () => {
    handleFileInput(confirmationValue);
    handleConfirmationClose();
    setConfirmationValue(null);
  };

  const handleFirstSelectToggle = () => {
    setIsFirstSelectOpen(!isFirstSelectOpen);
  };

  useEffect(() => {
    const data = getManualContent();
    setTransformedData(data);
    setFilteredData(data);
  }, [selectedFiles]);

  useEffect(() => {
    setSelectedRanges([]);
  }, [selectRandom]);

  const generateBatchId = (sessionId) => {
    const now = new Date();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${sessionId}:${month}${day}${year}${hours}${minutes}${seconds}`.replace(
      /%3a/g,
      ":"
    );
  };

  const handleRangeToggle = (value) => {
    setSelectedRanges([]);
    setSelectRandom(value);
  };

  const submitValidate = () => {
    // Check if selectedRanges is empty
    if (selectedRanges === null) {
      return false; // Return false if there are no selected ranges
    }

    const isDataTypeArray = Array.isArray(selectedRanges[0]);
    const areDatesValid = isDataTypeArray
      ? selectedRanges.every((range) => range.length > 1)
      : selectedRanges.length > 0;

    return callStartTime && areDatesValid && callEndTime; // Simplified return statement
  };

  const dateConversion = (date) => {
    const mm = date.month.number.toString().padStart(2, "0");
    const dd = date.day.toString().padStart(2, "0");
    const year = date.year;
    return `${mm}-${dd}-${year}`;
  };

  const now = new Date();

  const handleLoadPreviousFile = (load) => {
    load ? setSelectedFiles(selectedFilesBackup) : setSelectedFilesBackup([]);
  };

  function formatCurrentDateToMMDDYYYY() {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${month}-${day}-${year}`;
  }

  const formattedTime = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const handleSubmit = () => {
    setSubmitClicked(true);
    const batchId = generateBatchId(sessionStorage.getItem("sessionId"));
    setCurrentBatchId(batchId);
    const data = {
      payor: "",
      batch_request_id: batchId,
      recurring: isReccuring,
      start_date: [],
      end_date: [],
      selected_dates: [],
      start_time: [`${callStartTime}:00`],
      end_time: [`${callEndTime}:00`],
      excludable_days: ["Saturday", "Sunday"],
      excludable_dates: [],
      uploaded_by: sessionStorage.getItem("mail"),
      uploaded_date: formatCurrentDateToMMDDYYYY(),
      uploaded_time: formattedTime,
      executed_by: "",
    };

    if (selectRandom) {
      // If random is false, populate start_date and end_date arrays
      selectedRanges.forEach((range) => {
        data.selected_dates.push(dateConversion(range));
      });
    } else {
      selectedRanges.forEach((ranges) => {
        data.start_date.push(dateConversion(ranges[0]));
        data.end_date.push(dateConversion(ranges[1]));
      });
    }
    setFrequency(data);
  };

  const handleCancel = () => {
    navigate("/home");
  };

  const handleStartTimeChange = (time) => {
    setCallStartTime(time);
  };

  const handleEndTimeChange = (time) => {
    setCallEndTime(time);
  };

  const handleStartTimeClick = () => {
    setStartTimeClicked(!startTimeClicked);
    setCallEndTime(null);
    setEndTimeClicked(false);
    setMinuteClicked(false);
  };

  const handleEndTimeClick = () => {
    setEndTimeClicked(!endTimeClicked);

    setStartTimeClicked(false);
    setMinuteClicked(false);
  };

  const resetData = () => {
    setCallEndTime(null);
    setCallStartTime(null);
    setSelectRandom(true);
    setSelectedRanges([]);
    setSearchText("");
    setIsReccuring(false);
    setAutomationFeature("benifits");
    setAutomationType("manual");
    setSubmitClicked(false);
    setSelectedFiles([]);
    setSelectedFilesBackup([]);
  };

  const handleFileInput = (e) => {
    setHideUpload(false);
    const newFiles = Array.from(e.target.files).filter(
      (file) =>
        allowedTypes.includes(file.type) &&
        !selectedFiles.some((selectedFile) => selectedFile.name === file.name)
    );

    Promise.all(
      newFiles.map(async (file) => {
        const data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = (event) => {
            try {
              const binaryString = event.target.result;
              const workbook = read(binaryString, { type: "binary" });
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];
              // Convert sheet to JSON with custom formatting to ensure numbers are strings
              const jsonData = utils
                .sheet_to_json(sheet, {
                  header: 1,
                  raw: false,
                  defval: "", // Default value for null/undefined cells
                  // Custom transformation to ensure all numeric values are treated as strings
                  cellText: true,
                })
                .map((row) => row.map((cell) => cell.toString())); // Convert every cell to string explicitly
              resolve(jsonData);
            } catch (error) {
              reject(error);
            }
          };
          reader.onerror = (error) => {
            reject(error);
          };
        });
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          data: data,
        };
      })
    )
      .then((results) => {
        setFocusedfile(0);
        setSelectedFiles([...selectedFiles, ...results]);
        setAllowedTypes((prevAllowedTypes) => [
          ...prevAllowedTypes,
          ...newFiles.map((file) => file.type),
        ]);
        e.target.value = null; // clear value of file input element
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getColumns = () => {
    if (selectedFiles.length > 0) {
      const data = selectedFiles[focusedFile].data;
      const columns = transformToColumns(data[0]);
      // Calculate the width of each column
      const columnWidth = 100 / columns.length; // Assuming 100% width for the table

      // Set the width property for each column
      columns.forEach((column) => {
        column.width = `${columnWidth}%`;
        column.renderName = column.name;
        column.headerName = column.name;
      });
      return columns;
    }
  };

  const dummyColumns = [
    "Member EHR id",
    "payorName",
    "Payor Phone number to dial",
    "Appointment Date",
    "Member First Name",
    "Member Middle Name",
    "Member Last Name",
    "Member DOB (MM/DD/YYYY)",
    "Member SSN",
    "Insurance Plan Type (Medical/Dental)",
    "providerFirstName",
    "Primary Insurance Name",
    "Primary Member ID",
    "Primary Group Member ID",
    "Member Callback number on Insurance card",
    "Member Street",
    "Member State",
    "Member City",
    "Member Zipcode",
    "Member's Phone number",
    "Secondary First Name",
    "Secondary Middle Name",
    "Secondary Last Name",
    "Secondary Insurance Name",
    "Secondary Member ID",
    "Secondary Group Member ID",
    "Member relationship to Secondary",
    "InPatient/OutPatient",
    "Place Type (Clinic/Facility)",
    "CPT code",
    "Diagnosis / ICD -10",
    "Comments",
  ];

  const getDummyColumns = () => {
    const columns = transformToColumns(dummyColumns);

    // Calculate the width of each column
    const columnWidth = 100 / columns.length; // Assuming 100% width for the table

    // Set the width property for each column
    columns.forEach((column) => {
      column.width = `${columnWidth}%`;
      column.renderName = column.name;
      column.headerName = column.name;
    });
    return columns;
  };

  function transformToColumns(dataArray) {
    return dataArray.map((propertyName) => ({
      name: propertyName,
      label: propertyName,
      selector: (row) => row[propertyName],
      reorder: true,
    }));
  }

  function removeDuplicates(arr) {
    return arr.filter(
      (item, index, self) =>
        index ===
        self.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(item))
    );
  }

  function transformDataForReactTable(data) {
    const headers = data[0];
    const rows = data.slice(1);
    const filteredArr = removeDuplicates(
      rows.filter((subArr) => subArr.length > 0)
    );
    return filteredArr.map((row) => {
      const rowData = {};

      for (let i = 0; i < headers.length; i++) {
        rowData[headers[i]] = row[i];
      }

      return rowData;
    });
  }

  const getManualContent = () => {
    if (selectedFiles.length > 0) {
      const mergedData = selectedFiles.reduce((merged, file) => {
        const fileData = file.data;
        // You can perform any necessary transformations here
        // For example, if the data needs to be flattened or processed in any way

        return merged.concat(fileData);
      }, []);
      const response = transformDataForReactTable(mergedData);
      return response;
    } else {
      return []; // Return an empty array when there are no selected files
    }
  };

  const handleSearch = (value) => {
    const data = getManualContent();
    setSearchText(value);
    if (value.trim() === "") {
      // If the search text is empty, show the original data
      setFilteredData(data);
    } else {
      // Otherwise, filter the data based on the search text
      const filteredData = data.filter((row) => {
        const searchText = value.toLowerCase();
        // Check if any column (excluding "customer_input_filepath") contains the search text
        return Object.keys(row).some((key) => {
          if (typeof row[key] === "string") {
            return row[key].toLowerCase().includes(searchText);
          }
          return false; // Ignore "customer_input_filepath" and non-string values
        });
      });
      console.log("Filtered Data:", filteredData); // Add this line to check the filtered data
      setFilteredData(filteredData);
    }
  };

  const handleAdd = () => {
    let data = {};
    if (selectedFiles.length > 0) {
      data = selectedFiles[focusedFile].data[0];
    } else {
      data = dummyColumns;
    }

    // Create a new row with empty data
    const newRow = data.reduce((obj, col) => ({ ...obj, [col]: "" }), {});

    let newData = [];

    // If currentRow is defined and greater than -1
    if (currentRow >= 0) {
      // Insert the new row after the currentRow index
      newData = [
        ...filteredData.slice(0, currentRow + 1),
        newRow,
        ...filteredData.slice(currentRow + 1),
      ];
    } else {
      // Insert the new row at the beginning of the data array
      newData = [newRow, ...filteredData];
    }

    // Update the state to reflect the new data
    setFilteredData(newData);
  };

  //   setLoading(true);
  //   const batchId = currentBatchId;
  //   setBatchId(batchId);
  //   setHideUpload(true);
  //   setLoading(true);
  //   pushProviderPatientData(
  //     { frequency, data: transormedData },
  //     sessionStorage.getItem("mail"),
  //     sessionStorage.getItem("sessionId"),
  //     batchId
  //   )
  //     .then((res) => {
  //       setTimeout(() => {
  //         getErrorInData(
  //           batchId,
  //           sessionStorage.getItem("mail"),
  //           sessionStorage.getItem("sessionId")
  //         );
  //       }, 10000);
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //       setErrorOpen(true);
  //       setErrorList([{ error: "Failed uploading File" }]);
  //     });
  // };

  const handleUpload = async () => {
    const batchId = currentBatchId;
    setBatchId(batchId);
    setHideUpload(true);
    setLoading(true);

    try {
      await pushProviderPatientData(
        { frequency, data: filteredData },
        sessionStorage.getItem("mail"),
        sessionStorage.getItem("sessionId"),
        batchId
      )
        .then((res) => {
          sessionStorage.setItem("insufficient_storage_error", "{}");
          if (res === "") {
            setErrorOpen(true);
          }
          if (res) {
            setErrorOpen(true);
            // Check if the response is a success (status code 200 or 204)
            if (res && res.csv_data) {
              setErrorList(res);
            }
          } else {
            // Handle other status codes here if needed
            console.error("Unexpected status code:", res.status);
            // Handle the error accordingly
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          const errorResponse = error.response;
          // debugger;
          if (
            errorResponse.status === 500 &&
            errorResponse.data.errorMessage === "Insufficient Capacity"
          ) {
            sessionStorage.setItem(
              "insufficient_storage_error",
              JSON.stringify(errorResponse.data.response)
            );
            setErrorOpen(true);
            setSubmitClicked(false);
          }
          // Handle any network or request errors here
          console.error("Error Data:", error);
          // Handle the error accordingly
        });

      // // Polling getBatchStatus until status is either "ready_to_call" or "backend_issue"
      // let pollingInterval = setInterval(async () => {
      //   try {
      //     const batchStatusResponse = await getBatchStatus(
      //       sessionStorage.getItem("mail"),
      //       sessionStorage.getItem("sessionId"),
      //       batchId
      //     );

      //     const batchStatus = batchStatusResponse.response.batch_request_status;

      //     if (batchStatus === "ready_to_call") {
      //       clearInterval(pollingInterval);
      //       getErrorInData(
      //         batchId,
      //         sessionStorage.getItem("mail"),
      //         sessionStorage.getItem("sessionId")
      //       );
      //     } else if (batchStatus === "backend_issue") {
      //       clearInterval(pollingInterval);
      //       // Handle backend_issue
      //     }
      //   } catch (error) {
      //     console.error("Error while polling getBatchStatus:", error);
      //     clearInterval(pollingInterval);
      //     // Handle error while polling
      //   }
      // }, 10000);
    } catch (error) {
      setLoading(false);
      setErrorOpen(true);
      setErrorList([{ error: "Failed uploading File" }]);
    }
  };

  const handleClose = () => {
    setErrorOpen(false);
    // setScheduleVisible(true);
    navigate("/home");
  };

  const getFreqColor = (isSelected) => {
    if (isSelected) {
      return themeMode === "dark" ? "#ff4e3a" : "#ff4e3a";
    } else {
      return themeMode === "dark" ? "#3e3b56" : "#e3e2e5";
    }
  };

  const getFreqTextColor = (isSelected) => {
    if (isSelected) {
      return themeMode === "dark" ? "#ffffff" : "#ffffff";
    } else {
      return themeMode === "dark" ? "#ffffff" : "#04002b";
    }
  };

  const handleFileDrop = (droppedFiles) => {
    // Handle dropped files here
    // You can use the same logic as in your handleFileInput function
    // Call handleFileInput with the dropped files
    handleFileInput({ target: { files: droppedFiles } });
  };

  const completeStartTimeChange = () => {
    setMinuteClicked();
    setStartTimeClicked();
  };

  const completeEndTimeChange = () => {
    setMinuteClicked();
    setEndTimeClicked();
  };

  const options = [
    { value: "manual", label: "Manual" },
    { value: "api", label: "API" },
  ];

  const automationFeatureOptions = [
    { value: "benefits", label: "Benefits Verification" },
    { value: "priorAuth", label: "Prior Authentication follow up" },
    { value: "claimStatus", label: "Claim Status" },
    { value: "prescriptionSupport", label: "Prescription support" },
  ];

  const handleBack = () => {
    setSelectedFilesBackup(selectedFiles);
    setSelectedFiles([]);
    setAddPatients(false);
    // setSelectRandom(true);
    // setSelectedRanges(selectedRanges);
  };

  const handleDelete = () => {
    // Check if currentRow is valid
    if (currentRow >= 0 && currentRow < filteredData.length) {
      // Create a new array without the row at currentRow index
      const newData = [
        ...filteredData.slice(0, currentRow),
        ...filteredData.slice(currentRow + 1),
      ];

      // Update the state to reflect the new data
      setFilteredData(newData);

      // Reset the currentRow since the selected row has been deleted
      setCurrentRow(null);
    } else {
      console.error(
        "No row is currently selected or the index is out of bounds."
      );
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <DataValidationOverlay
      batchId={batchId}
      open={errorOpen}
      closeOverlay={() => {
        setErrorOpen(false);
        navigateHome();
        setAddPatients(false);
      }}
      success={errorList.length === 0}
      failed={errorList.length !== 0}
      loading={loading}
      errorList={errorList}
      csvData={filteredData}
      setErrorOpen={setErrorOpen}
    >
      <div
        className="call-automation-root"
        style={{
          background: currentTheme.callAutomationPage.mainBackground,
          color: currentTheme.homePage.textColor,
          paddingRight: "2rem",
        }}
      >
        {selectedFiles.length > 0 || addPatients ? (
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: "1rem" }}>
              <Search
                value={searchText}
                onChange={handleSearch}
                themeMode={themeMode}
                currentTheme={currentTheme}
                style={{
                  color: currentTheme.homePage.textColor,
                  width: "100%",
                }}
              />
              <CallAutomationInfo
                isRecursive={isReccuring}
                isMultipleDaysSelected={!selectRandom}
                startTime={callStartTime}
                endTime={callEndTime}
                selectedRanges={selectedRanges}
                handleValidate={handleUpload}
                handleBack={handleBack}
                handleAdd={handleAdd}
                handleDelete={handleDelete}
                loading={loading}
              />
            </div>
            {/* <div style={{ maxWidth: isSidebarOpen ? "87rem" : "97rem" }}> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ maxWidth: "87rem" }}>
                {loading ? (
                  <img
                    className="loader"
                    src={
                      themeMode === "dark"
                        ? "/icons/loader_white.gif"
                        : "/icons/loader_black.gif"
                    }
                  />
                ) : (
                  <>
                    <Table
                      rows={filteredData}
                      setRows={setFilteredData}
                      columns={addPatients ? getDummyColumns() : getColumns()}
                      // containerHeight={'50vh'}
                      frontendSort
                      page={page}
                      setPage={setPage}
                      isEditable={true}
                      currentRow={currentRow}
                      setCurrentRow={setCurrentRow}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      themeMode={themeMode}
                    />
                    {/* <DataTable
                    headerColor={currentTheme.homePage.tableHeader}
                    dataColor={currentTheme.homePage.search}
                    headerTextColor={currentTheme.homePage.textColor}
                    columns={addPatients ? getDummyColumns() : getColumns()}
                    data={filteredData}
                    itemsPerPageOptions={[5, 10, 20]}
                    defaultItemsPerPage={10}
                    maxHeight={"55vh"}
                    currentRow={currentRow}
                    isEditable={true}
                    setCurrentRow={setCurrentRow}
                  /> */}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="call-automation-inner-root">
            <div className="ca-left-section">
              <div style={{ height: "73vh", fontSize: "12px" }}>
                <div class="cals-frequency">
                  <button
                    class={isReccuring ? "switch-freq-selected" : "switch-freq"}
                    style={{
                      backgroundColor: getFreqColor(isReccuring),
                      color: getFreqTextColor(isReccuring),
                    }}
                    onClick={() => setIsReccuring(true)}
                  >
                    Recurring
                  </button>
                  <button
                    class={
                      !isReccuring ? "switch-freq-selected" : "switch-freq"
                    }
                    style={{
                      backgroundColor: getFreqColor(!isReccuring),

                      color: getFreqTextColor(!isReccuring),
                    }}
                    onClick={() => setIsReccuring(false)}
                  >
                    One Time
                  </button>
                </div>
                <div class="cals-frequency">
                  <button
                    class={
                      !selectRandom ? "switch-freq-selected" : "switch-freq"
                    }
                    style={{
                      backgroundColor: getFreqColor(!selectRandom),
                      color: getFreqTextColor(!selectRandom),
                    }}
                    onClick={() => handleRangeToggle(false)}
                  >
                    Select Date Range
                  </button>
                  <button
                    class={
                      selectRandom ? "switch-freq-selected" : "switch-freq"
                    }
                    style={{
                      backgroundColor: getFreqColor(selectRandom),
                      color: getFreqTextColor(selectRandom),
                    }}
                    onClick={() => handleRangeToggle(true)}
                  >
                    Select Multiple Dates
                  </button>
                </div>
                <div style={{ margin: "1vh", zIndex: 9999 }}>
                  <DateRangePicker
                    style={{
                      background:
                        currentTheme.callAutomationPage.dateBackground,
                      color: currentTheme.callAutomationPage.textColor,
                    }}
                    format={format}
                    selectedRanges={selectedRanges}
                    setSelectedRanges={setSelectedRanges}
                    selectRandom={selectRandom}
                  />
                </div>
                <div
                  className="cals-time"
                  style={{
                    background: currentTheme.callAutomationPage.timeBackground,
                    color: currentTheme.callAutomationPage.textColor,
                  }}
                >
                  <span className="cals-start-time">
                    <span
                      className="cals-start-time-btn"
                      style={{
                        background: startTimeClicked
                          ? currentTheme.callAutomationPage.timeButtonOpen
                          : currentTheme.callAutomationPage.timeButtonClose,
                        color: startTimeClicked
                          ? currentTheme.callAutomationPage.timeButtonOpenColor
                          : currentTheme.callAutomationPage
                              .timeButtonCloseColor,
                      }}
                    >
                      <img
                        className="call-auto-home-icon"
                        src="/icons/clock.svg"
                      />
                      Pick Start Time
                      {startTimeClicked ? (
                        <img
                          className="call-auto-home-icon"
                          src="/icons/up-orange.png"
                          onClick={() => handleStartTimeClick()}
                        />
                      ) : (
                        <img
                          className="call-auto-home-icon"
                          src={
                            themeMode === "dark"
                              ? "/icons/down.svg"
                              : "/icons/down-black.png"
                          }
                          onClick={() => handleStartTimeClick()}
                        />
                      )}
                    </span>
                    {!minuteClicked && startTimeClicked && (
                      <TimeStampGenerator
                        style={{
                          backgroundColor:
                            currentTheme.callAutomationPage.timeboxColor,
                        }}
                        onStartTimeChange={handleStartTimeChange}
                        setMinuteClicked={completeStartTimeChange}
                      />
                    )}
                  </span>
                  <span className="cals-end-time">
                    <span
                      className="cals-end-time-btn"
                      style={{
                        background: endTimeClicked
                          ? currentTheme.callAutomationPage.timeButtonOpen
                          : currentTheme.callAutomationPage.timeButtonClose,
                        color: endTimeClicked
                          ? currentTheme.callAutomationPage.timeButtonOpenColor
                          : currentTheme.callAutomationPage
                              .timeButtonCloseColor,
                      }}
                    >
                      <img
                        className="call-auto-home-icon"
                        src="/icons/clock.svg"
                      />
                      Pick End Time
                      {endTimeClicked ? (
                        <img
                          className="call-auto-home-icon"
                          src="/icons/up-orange.png"
                          onClick={() => handleEndTimeClick()}
                        />
                      ) : (
                        <img
                          className="call-auto-home-icon"
                          src={
                            themeMode === "dark"
                              ? "/icons/down.svg"
                              : "/icons/down-black.png"
                          }
                          onClick={() => handleEndTimeClick()}
                        />
                      )}
                    </span>
                    {!minuteClicked && endTimeClicked && (
                      <TimeStampGenerator
                        style={{
                          backgroundColor:
                            currentTheme.callAutomationPage.timeboxColor,
                        }}
                        onStartTimeChange={handleEndTimeChange}
                        setMinuteClicked={completeEndTimeChange}
                        startTime={callStartTime}
                      />
                    )}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      margin: "1vh",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      Start Time:{" "}
                      {callStartTime
                        ? convertTo12HourFormat(callStartTime)
                        : "--:--"}
                    </span>
                    <span>
                      End Time:{" "}
                      {callEndTime
                        ? convertTo12HourFormat(callEndTime)
                        : "--:--"}
                    </span>
                  </span>
                </div>
                <div className={`my-container ${themeMode}`}>
                  <Select
                    value={automationFeature}
                    onValueChange={setAutomationFeature}
                    options={automationFeatureOptions}
                    backgroundColor={
                      currentTheme.callAutomationPage.automationTypeColor
                    }
                    textColor={currentTheme.callAutomationPage.textColor}
                    margin={"2vh"}
                    onClick={handleFirstSelectToggle}
                  />
                </div>
                <div
                  className={`${isFirstSelectOpen ? "second-select-open" : ""}`}
                >
                  <Select
                    value={automationType}
                    onValueChange={setAutomationType}
                    options={options}
                    backgroundColor={
                      currentTheme.callAutomationPage.automationTypeColor
                    }
                    textColor={currentTheme.callAutomationPage.textColor}
                    margin={"2vh"}
                  />
                </div>
              </div>
              <span style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Button
                  label={"Reset"}
                  backgroundColor={currentTheme.callAutomationPage.buttonColor}
                  color={currentTheme.callAutomationPage.textColor}
                  width={"29%"}
                  borderRadius={"5px"}
                  height={"4vh"}
                  onClick={resetData}
                />
                <Button
                  label={"Cancel"}
                  backgroundColor={currentTheme.callAutomationPage.buttonColor}
                  color={currentTheme.callAutomationPage.textColor}
                  width={"29%"}
                  borderRadius={"5px"}
                  height={"4vh"}
                  onClick={handleCancel}
                />
                <Button
                  label={"Submit"}
                  backgroundColor={
                    submitValidate()
                      ? currentTheme.callAutomationPage.activeButtonColor
                      : currentTheme.callAutomationPage.buttonColor
                  }
                  color={
                    submitValidate()
                      ? currentTheme.callAutomationPage.submitColor
                      : currentTheme.callAutomationPage.textColor
                  }
                  width={"29%"}
                  height={"4vh"}
                  borderRadius={"5px"}
                  disabled={!submitValidate()}
                  onClick={handleSubmit}
                />
              </span>
            </div>
            <div className="ca-right-section">
              <div
                className="cars-page-one"
                ref={dropAreaRef}
                style={{
                  backgroundColor: themeMode === "dark" ? "#3e3c57" : "#e3e2e5",
                  color:
                    themeMode === "dark"
                      ? "#f5f5f5 !important"
                      : "#04002b !important",
                  borderRadius: "1vh",
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  // Add styles for the drag-over effect
                  dropAreaRef.current.style.border = "2px dashed #ff4e3a";
                }}
                onDragLeave={() => {
                  // Remove styles when leaving the drag area
                  dropAreaRef.current.style.border = "none";
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  // Handle the dropped files
                  handleFileDrop(e.dataTransfer.files);
                  // Remove styles after dropping
                  dropAreaRef.current.style.border = "none";
                }}
              >
                {submitClicked ? (
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    {selectedFilesBackup.length > 0 ? (
                      <div>
                        <span>Load previously loaded file</span>
                        <Button
                          backgroundColor={"#ff4e3a"}
                          width={"6vh"}
                          height={"4vh"}
                          onClick={() => handleLoadPreviousFile(true)}
                          style={{ margin: "1rem" }}
                          label={"Yes"}
                        />
                        <Button
                          backgroundColor={"#ff4e3a"}
                          width={"6vh"}
                          height={"4vh"}
                          onClick={() => handleLoadPreviousFile(false)}
                          style={{ margin: "1rem" }}
                          label={"No"}
                        />
                      </div>
                    ) : (
                      <div>
                        <span
                          style={{
                            display: "flex",
                            margin: "1vh",
                            color: themeMode === "dark" ? "#f5f5f5" : "#000",
                            justifyContent: "center",
                            fontWeight: "600",
                          }}
                        >
                          Upload File
                        </span>
                        <span
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span
                            style={{
                              display: "flex",
                              backgroundColor: "#ff4e3a",
                              color: "#fff",
                              padding: ".5vh",
                              width: "20vh",
                              margin: "1vh",
                              borderRadius: "1vh",
                              cursor: "pointer",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              document.getElementById("file-input").click();
                            }}
                          >
                            <img
                              className="call-auto-home-icon"
                              src="/icons/folder.svg"
                              style={{ marginRight: "1vh" }}
                            />
                            Browse
                            <input
                              id="file-input"
                              type="file"
                              accept=".xls,.xlsx,.csv"
                              onChange={(e) => handleConfirmationValueChange(e)}
                              multiple
                              style={{ display: "none" }}
                            />
                          </span>
                        </span>

                        <span
                          style={{
                            display: "flex",
                            margin: "1vh",
                            color: themeMode === "dark" ? "#f5f5f5" : "#000",
                            justifyContent: "center",
                          }}
                        >
                          or drag and drop your file in this section
                        </span>
                        <span
                          style={{ display: "flex", justifyContent: "center" }}
                          onClick={() => {
                            setAddPatients(true);
                            setSelectedFiles([]);
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              backgroundColor: "#ff4e3a",
                              color: "#fff",
                              padding: ".5vh",
                              width: "20vh",
                              margin: "1vh",
                              borderRadius: "1vh",
                              cursor: "pointer",
                              justifyContent: "center",
                            }}
                          >
                            <span style={{ marginRight: ".5rem" }}>+</span>
                            Add Patient (s)
                          </span>
                        </span>
                      </div>
                    )}
                  </span>
                ) : (
                  <span>Section will load after data is uploaded</span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomDialog
        open={isConfirmationOpen}
        message={`Are you sure you want to continue with this file: ${filename}?`}
        onConfirm={handleConfirmed}
        onCancel={handleConfirmationClose}
        themeMode={themeMode}
        currentTheme={currentTheme}
      />
    </DataValidationOverlay>
  );
};
export default CallAutomation;
