import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";
import Button from "../button/Button";
import { useTheme } from "../../../ThemeContext";

const CustomDialog = ({ open, title="Confirmation", message, onConfirm, onCancel }) => {
  const { currentTheme, themeMode } = useTheme();

  const fontColor = themeMode === "dark" ? '#fff' : '#000'

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="custom-popup-title"
      aria-describedby="custom-popup-description"
      PaperProps={{
        style: {
          backgroundColor: currentTheme.settingPage.popupBackground,
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle id="custom-popup-title" style={{ color: fontColor}}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="custom-popup-description"
          style={{ color: fontColor}}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          label={"Cancel"}
          onClick={onCancel}
          backgroundColor={"#ff4e3a"}
          width={"5rem"}
          height={"2rem"}
        />
        <Button
          label={"Confirm"}
          onClick={onConfirm}
          backgroundColor={"#ff4e3a"}
          width={"5rem"}
          height={"2rem"}
        />
      </DialogActions>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CustomDialog;
