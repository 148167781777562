import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DocxViewer from "../common/DocxViewer";

const TermsAndConditionsPopup = () => {
  const [open, setOpen] = useState(false);
  const [showFullTerms, setShowFullTerms] = useState(false);

  useEffect(() => {
    const isFirstLogin = localStorage.getItem("isFirstLogin") !== "false"; // Update logic if needed
    if (isFirstLogin) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("isFirstLogin", "false");
    setOpen(false);
  };

  const handleShowFullTerms = () => {
    setShowFullTerms(true);
  };

  const handleCloseFullTerms = () => {
    setShowFullTerms(false);
  };

  return (
    <>
      {/* Initial Popup */}
      <Dialog open={open} onClose={handleAccept} maxWidth="sm" fullWidth sx={{color:"#04002b"}}>
        <DialogTitle>Accept Terms and Conditions</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            By using our platform, you agree to our terms and conditions. You
            can review the full terms before accepting.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShowFullTerms} color="secondary">
            Read Full Terms
          </Button>
          <Button onClick={handleAccept} color="primary" variant="contained">
            Accept Terms
          </Button>
        </DialogActions>
      </Dialog>

      {/* Full Terms Modal */}
      <Modal
        open={showFullTerms}
        onClose={handleCloseFullTerms}
        aria-labelledby="full-terms-title"
        aria-describedby="full-terms-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%",
            bgcolor: "#04002b", // Main background color
            boxShadow: 24,
            p: 4,
            overflowY: "auto",
            borderRadius: 2,
            color: "#ffffff", // Text color
          }}
        >
          <Typography
            id="full-terms-title"
            variant="h6"
            component="h2"
            gutterBottom
            sx={{ color: "#ffffff" }}
          >
            Voicecare AI Platform Terms of Use
          </Typography>
          <DocxViewer docxPath="/documents/Voicecare Terms of Use Policy.docx" />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleCloseFullTerms}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TermsAndConditionsPopup;
