import React, { useState } from "react";
import "./Select.css";
import { useTheme } from "../../../ThemeContext";

const Select = ({
  value,
  onChange,
  options,
  backgroundColor,
  textColor,
  onValueChange,
  width,
  margin,
  onClick,
  align,
  paddingLeft
}) => {
  const [optionVisible, setOptionsVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const { themeMode } = useTheme();
  const selectOption = (selectedOption, index) => () => {
    setSelectedOption(selectedOption);
    setOptionsVisible(!optionVisible);
    onValueChange(selectedOption.value);

    onClick && onClick(); // Call the onClick prop if provided
  };

  const searchByValue = (value) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : 'Not found'; // Or any other default message
  };
  

  const handleClick = () => {
    setOptionsVisible(!optionVisible);
    onClick && onClick(); // Call the onClick prop if provided
  };

  return (
    <div
      style={{
        margin,
        display: "flex",
        flexDirection: "column",
        width,
      }}
    >
      <div
        style={{
          background: backgroundColor,
          color: textColor,
          borderRadius: "1vh",
          display: "flex",
          alignItems: align || "center", // Ensure items are aligned vertically in the center
          justifyContent: align || "center", // Center the content horizontally
          padding:"5px",
          paddingLeft: paddingLeft || "10px",
          position: "relative", // Allows absolute positioning of the icon within
        }}
        onClick={() => handleClick()}
      >
        {/* Text Container: It will be automatically centered */}
        <span style={{ textAlign: align || "center" }}>
          {selectedOption
            ? selectedOption.label || searchByValue(selectedOption)
            : "Select an option"}
        </span>

        {/* Icon Container: Positioned to the right */}
        <span style={{ position: "absolute", right: "10px" }}>
          {optionVisible ? (
            <img src="/icons/up-orange.png" className="call-auto-home-icon" />
          ) : (
            <img
              className="call-auto-home-icon"
              src={
                themeMode === "dark"
                  ? "/icons/down.svg"
                  : "/icons/down-black.png"
              }
            />
          )}
        </span>
      </div>

      <div className={`${themeMode}-select-option-container`}>
        {optionVisible && (
          <ul
            style={{
              background: backgroundColor,
              color: textColor,
              marginBottom: "1rem"
            }}
            className="select-option-list"
          >
            {options.map((data, index) => (
              <li
                className="select-option"
                onClick={selectOption(data, index)}
                key={data.value}
              >
                {data.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select;
