// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
  display: flex;
  align-items: center;
  border: 1px solid #636279;
  border-radius: 10px;
  padding: 5px;
  width: 60vw;
  height: 2.5vh;
  outline: none;
}

.search-icon {
  margin-right: 10px;
  color: #333;
  height: 2.5vh;
}

.search-input {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
} `, "",{"version":3,"sources":["webpack://./src/components/input-field/textbox/TextBox.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb","sourcesContent":[".search-container {\r\n  display: flex;\r\n  align-items: center;\r\n  border: 1px solid #636279;\r\n  border-radius: 10px;\r\n  padding: 5px;\r\n  width: 60vw;\r\n  height: 2.5vh;\r\n  outline: none;\r\n}\r\n\r\n.search-icon {\r\n  margin-right: 10px;\r\n  color: #333;\r\n  height: 2.5vh;\r\n}\r\n\r\n.search-input {\r\n  border: none;\r\n  outline: none;\r\n  background: transparent;\r\n  width: 100%;\r\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
