import React, { useState } from 'react';
import { UserInfoCard, SearchInput, Transcripts, CallSummary, MoreCallDetails } from './../components';
import { Space } from 'antd';
import { useCallHistoryProvider } from '../context/CallHIstoryContext';


export const CallSummaryInfo = ({viewMore, setViewMore}) => {
  const value = useCallHistoryProvider();
  return (
    <Space direction='vertical' size="large" className='call-history__call-summary'>
      <CallSummary setViewMore={setViewMore} viewMore={viewMore}/>
      {!viewMore && <MoreCallDetails />}
    </Space>
  )
}
