import { UserOutlined, ArrowUpOutlined } from "@ant-design/icons";
import React from "react";
import { Avatar, Flex, Typography } from "antd";
import { Colors, formatDateTime } from "../helpers";
import { useCallHistoryProvider } from "../context/CallHIstoryContext";
import { useTheme } from "../../../ThemeContext";
import Button from "../../common/button/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";

const { Text } = Typography;

export const UserCard = ({
  avatarSrc,
  username,
  datetime,
  onClick,
  isActive,
  handlerType,
  i,
  callContent,
}) => {
  const [date, time] = datetime.split(" ");
  const { themeMode, currentTheme } = useTheme();
  function capitalizeFirstLetter(str) {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function convertDateFormat(inputDate) {
    const [month, day, year] = inputDate.split("-").map(Number); // Convert parts to integers

    const dateObject = new Date(year, month - 1, day); // Creating a Date object with integers
    const formattedDate = `${padTo2Digits(
      dateObject.getMonth() + 1
    )}/${padTo2Digits(dateObject.getDate())}/${dateObject.getFullYear()}`; // Formatting to "mm/dd/yyyy"

    return formattedDate;
  }

  const startTime = callContent?.call_start_time;
  const endTime = callContent?.call_end_time;

  const duration = moment.duration(moment(endTime).diff(moment(startTime)));
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const callDuration = `${minutes}m ${seconds}s`;

  // Calculate call date with time
  const startDate = moment(startTime);
  let callDate;

  if (startDate.isSame(moment(), "day")) {
    callDate = `Today ${startDate.format("hh:mm A")}`;
  } else if (startDate.isSame(moment().subtract(1, "day"), "day")) {
    callDate = `Yesterday ${startDate.format("hh:mm A")}`;
  } else {
    callDate = `${startDate.format("MM/DD/YYYY : hh:mm A")}`;
  }

  return (
    <Flex
      direction="horizontal"
      size={16}
      className={`call-history__user-card ${isActive ? "active" : ""}`}
      style={{
        background: isActive
          ? currentTheme.callhistory.callListSelectedBackground
          : "transperant",
        color: currentTheme.callhistory.searchColor,
      }}
      justify="space-between"
      onClick={onClick}
    >
      <Flex justify="center" align="center" gap={10}>
        <Avatar
          shape="square"
          size={40}
          style={{ border: "none" }}
          icon={
            themeMode === "light" ? (
              <img
                src="/icons/light-theme/user-icon-light.png"
                style={{
                  backgroundColor: isActive
                    ? currentTheme.callhistory.callListSelectedBackground
                    : currentTheme.callhistory.mainBackground,
                  // width: "5vh",
                  objectFit: "cover", // Ensures the image fits into the Avatar
                }}
                alt="Light Icon"
              />
            ) : (
              <img
                src="/icons/dark-theme/user-icon-dark.png"
                style={{
                  backgroundColor: isActive
                    ? currentTheme.callhistory.callListSelectedBackground
                    : currentTheme.callhistory.mainBackground,
                  // width: "5vh",
                  objectFit: "cover", // Ensures the image fits into the Avatar
                }}
                alt="Dark Icon"
              />
            )
          }
        />
        <Flex vertical gap={1}>
          <Text
            ellipsis
            style={{
              color: currentTheme.callhistory.searchColor,
              fontWeight: "600",
            }}
          >
            {username}
          </Text>
          <Text
            style={{
              color: currentTheme.callhistory.searchColor,
              fontSize: 12,
            }}
            ellipsis
            type="secondary"
          >
            {" "}
            <ArrowUpOutlined
              style={{
                color: currentTheme.callhistory.searchColor,
                rotate: "45deg",
              }}
            />{" "}
            {capitalizeFirstLetter(handlerType)} Called
          </Text>
        </Flex>
      </Flex>
      <Flex
        vertical
        gap={1}
        style={{ paddingRight: ".5rem" }}
        justify="center"
        align="end"
      >
        <Text
          style={{ color: currentTheme.callhistory.searchColor, fontSize: 14 }}
        >
          {/* {convertDateFormat(date)} */}
          {callDate}
        </Text>
        <Text
          style={{ color: currentTheme.callhistory.searchColor, fontSize: 14 }}
          type="secondary"
        >
          {/* {time} */}
          {callDuration}
        </Text>
      </Flex>
    </Flex>
  );
};

export const UserInfoCard = ({ setRenderType }) => {
  const { getSelectedMemberInfo, setSelectedMember } = useCallHistoryProvider();
  const { member_name, member_id } = getSelectedMemberInfo() || {};
  const { themeMode, currentTheme } = useTheme();
  return (
    <Flex
      direction="horizontal"
      size={16}
      className="call-history__user-card user-info"
      justify="space-between"
      style={{ paddingLeft: 0 }}
    >
      <Flex justify="center" align="center" gap={10}>
        <Avatar
          shape="square"
          size={70}
          style={{ border: "none" }}
          icon={
            themeMode === "light" ? (
              <img
                src="/icons/light-theme/user-icon-light.png"
                style={{
                  backgroundColor: currentTheme.callhistory.searchBackground,
                  // width: "8vh",
                  objectFit: "cover", // Ensures the image fits into the Avatar
                }}
                alt="Light Icon"
              />
            ) : (
              <img
                src="/icons/dark-theme/user-icon-dark.png"
                style={{
                  backgroundColor: currentTheme.callhistory.searchBackground,
                  width: "100%",
                  objectFit: "cover", // Ensures the image fits into the Avatar
                }}
                alt="Dark Icon"
              />
            )
          }
        />

        <Flex vertical gap={1}>
          <Text
            ellipsis
            style={{
              color: currentTheme.callhistory.orange,
              fontWeight: "600",
              fontSize: 18,
            }}
          >
            {member_name}
          </Text>
          <Text
            style={{ color: currentTheme.callhistory.textLight, fontSize: 16 }}
            ellipsis
            type="secondary"
          >
            Patient ID: {member_id}
          </Text>
        </Flex>
      </Flex>
      <Tooltip title="call out">
        <IconButton
          aria-label="back"
          sx={{ color: "#ff4e3a", paddingBottom: 0 }}
          onClick={() => {
            setRenderType("callList");
            setSelectedMember("");
          }}
        >
          <KeyboardBackspaceIcon sx={{ fontSize: 35 }} />
        </IconButton>
      </Tooltip>
      {/* <Button
        label={""}
        height={"100%"}
        backgroundColor={"#ff4e3a"}
        onClick={() => setRenderType("callList")}
      /> */}
    </Flex>
  );
};
