"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
// Sidebar.tsx in ui-library
var react_1 = __importStar(require("react"));
require("./Sidebar.css");
var Sidebar = function (_a) {
    var handleLogout = _a.handleLogout, menuItems = _a.menuItems, selectMenu = _a.selectMenu, themeMode = _a.themeMode, navigate = _a.navigate, location = _a.location;
    var _b = (0, react_1.useState)(false), isDarkHover = _b[0], setIsDarkHover = _b[1];
    // Function to navigate to a selected route and select the menu
    var navigateTo = function (route, menu) {
        navigate(route);
        selectMenu(menu); // Update the selected menu using the provided function
    };
    // Render the logo based on the theme
    var getLogo = function () {
        var logoImage = themeMode === "dark"
            ? "/icons/loading-icon.gif"
            : "/icons/loading-icon-light.gif";
        return react_1.default.createElement("img", { src: logoImage, width: "40", alt: "Logo" });
    };
    // Render the text logo based on the theme
    var getTextLogo = function () {
        var textImage = themeMode === "dark"
            ? "/logo/dark-text.svg"
            : "/logo/white-text.svg";
        return react_1.default.createElement("img", { src: textImage, width: "auto", height: "auto", alt: "Text Logo" });
    };
    // Define styles based on the theme
    var mainBackgroundColor = themeMode === "dark" ? "#242242" : "#fff";
    var textColor = themeMode === "dark" ? "#fff" : "#000";
    return (react_1.default.createElement("div", { role: "navigation", className: "sidebar-root", style: {
            backgroundColor: mainBackgroundColor,
            color: textColor,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
        }, onMouseLeave: function () { return setIsDarkHover(false); }, onMouseEnter: function () { return setIsDarkHover(true); } },
        react_1.default.createElement("div", { style: { display: "flex", height: "3rem", padding: "1rem" } },
            getLogo(),
            isDarkHover ? getTextLogo() : null),
        react_1.default.createElement("span", { className: "".concat(themeMode, "-sidebar-menu-text") },
            react_1.default.createElement("span", { style: { textAlign: "center" } }, "Menu")),
        react_1.default.createElement("div", { className: "sidebar-menu-items" },
            menuItems.map(function (item, index) { return (react_1.default.createElement("div", { key: index, style: {
                    width: isDarkHover ? "15rem" : "auto",
                    textAlign: "center",
                } },
                react_1.default.createElement("div", { className: location.pathname === item.path
                        ? "sidebar-active-menu"
                        : "sidebar-in-active-menu", onClick: function () { return navigateTo(item.path, item.name); }, style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                    } },
                    react_1.default.createElement("img", { className: "sidebar-home-icon", src: location.pathname !== item.path && themeMode === "light"
                            ? "/icons/light-theme/".concat(item.icon || "Home", ".png")
                            : "/icons/dark-theme/".concat(item.icon || "Home", ".png"), alt: "".concat(item.name, " icon") }),
                    react_1.default.createElement("span", { hidden: !isDarkHover, style: { marginLeft: "1rem" } }, item.name)))); }),
            react_1.default.createElement("div", { style: {
                    width: isDarkHover ? "15rem" : "auto",
                    textAlign: "center",
                    marginTop: "20rem",
                } },
                react_1.default.createElement("div", { className: "sidebar-in-active-menu", onClick: handleLogout, style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                    } },
                    react_1.default.createElement("img", { src: themeMode === "dark"
                            ? "/icons/light-logout.svg"
                            : "/icons/dark-logout.svg", className: "sidebar-home-icon", alt: "Logout" }),
                    react_1.default.createElement("span", { hidden: !isDarkHover }, "Logout"))))));
};
exports.default = Sidebar;
