"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var ColorConstants_1 = require("utils/constants/color-constants/ColorConstants");
var Button = function (_a) {
    var label = _a.label, onClick = _a.onClick, _b = _a.backgroundColor, backgroundColor = _b === void 0 ? ColorConstants_1.orange1 : _b, _c = _a.color, color = _c === void 0 ? "white" : _c, _d = _a.width, width = _d === void 0 ? "auto" : _d, _e = _a.height, height = _e === void 0 ? "auto" : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f, style = _a.style, _g = _a.borderRadius, borderRadius = _g === void 0 ? "1.5vh" : _g, iconSrc = _a.iconSrc, className = _a.className, fontSize = _a.fontSize, id = _a.id;
    var _h = (0, react_1.useState)(false), isAnimating = _h[0], setIsAnimating = _h[1];
    var buttonStyle = __assign({ backgroundColor: backgroundColor, color: color, padding: "0.5rem 1rem", display: 'flex', alignItems: 'center', justifyContent: 'center', border: "none", borderRadius: borderRadius, cursor: disabled ? "not-allowed" : "pointer", width: width, height: height, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", gap: '.5rem' }, style);
    var viewportWidth = window.innerWidth;
    var adjustTextSize = function () {
        var textSize = fontSize || "inherit";
        if (viewportWidth < 768) {
            textSize = "8px";
        }
        else if (viewportWidth >= 768 && viewportWidth < 1260) {
            textSize = "10px";
        }
        else {
            textSize = "0.8rem";
        }
        return __assign(__assign({}, buttonStyle), { fontSize: textSize });
    };
    var buttonStyles = adjustTextSize();
    var handleOnClick = function () {
        setIsAnimating(true);
        setTimeout(function () {
            setIsAnimating(false);
            onClick();
        }, 1000);
    };
    return (react_1.default.createElement("button", { id: id, className: "button ".concat(isAnimating ? "button-fire" : "", " ").concat(className || ""), style: buttonStyles, onClick: handleOnClick, disabled: disabled },
        iconSrc && (react_1.default.createElement("img", { src: iconSrc, alt: "Icon", style: { marginRight: "5px", height: "1.5rem", width: "auto" } })),
        label));
};
exports.default = Button;
