import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, Box } from '@mui/material';
import mammoth from 'mammoth';

const DocxViewer = ({ docxPath }) => {
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDocxFile = async () => {
      if (!docxPath) {
        setError('No document path provided.');
        return;
      }

      setLoading(true);
      setError('');

      try {
        const response = await fetch(docxPath);
        if (!response.ok) {
          throw new Error('Failed to fetch the document.');
        }

        const arrayBuffer = await response.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer }, {
          styleMap: [
            "p[style-name='Heading 1'] => h1",
            "p[style-name='Heading 2'] => h2",
            "p[style-name='Heading 3'] => h3",
            "p[style-name='Normal'] => p:fresh"
          ],
        });
        setHtmlContent(result.value);
      } catch (err) {
        setError('Error loading the document. Please check the file path or URL.');
      } finally {
        setLoading(false);
      }
    };

    fetchDocxFile();
  }, [docxPath]);

  return (
    <Box
      sx={{
        backgroundColor: '#04002b', // Main background color
        color: '#ffffff', // Text color
        minHeight: '100vh',
        padding: '20px',
      }}
    >
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100px',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {htmlContent && (
        <Box
          sx={{
            mt: 3,
            padding: '20px',
            border: '1px solid #252144', // Border color
            borderRadius: '8px',
            backgroundColor: '#252144', // Text field background
            color: '#ffffff', // Text color
            overflowY: 'auto',
            maxHeight: '70vh',
          }}
        >
          {/* Render the HTML content */}
          <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: '1.6', // Adjust line spacing
              wordWrap: 'break-word', // Prevent long words from overflowing
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default DocxViewer;